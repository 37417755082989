import React from 'react'
import { Col, Row } from "react-bootstrap";
import Stat from '../common/Stat'
import StatBar from '../common/StatBar'
import "../QuestionReport.css"
import IsSmallScreen from '../common/IsSmallScreen';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'


function Mmcq(props) {

  console.log(props);

    const isSmallScreen = IsSmallScreen
    const data = [
      {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
      },
      {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
      },
      {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
      },
      {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },
      {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
      },
      {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
      },
      {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
      },
    ];
    const getNumber=(no)=>{
      if(no===0)
      return "A";
      else if(no===1)
      return "B"
      else if(no===2)
      return "C"
      else
      return "D"
    }
    const getStyle=(item,userAnswer,crctAnswer)=>{
        console.log(item,userAnswer,crctAnswer);
      if(userAnswer.includes(item?.optionId)&&crctAnswer.includes(item?.optionId))
      {
        return{ background:
          "linear-gradient(90deg, #7EB6A2 32.75%, #FFFFFF 96.35%)"}
      }
      // if(crctAnswer.indexOf(item?.optionId)!==-1)
      // {
      //   return{
      //     background:
      //     "linear-gradient(90deg, #7EB6A2 32.75%, #FFFFFF 96.35%)"}
        
      // }
      else if(userAnswer.includes(item?.optionId))
      {
        return{ background:
          "linear-gradient(90deg, #FF675E 32.75%, #FFFFFF 96.35%)"}
      }
      else{
        return{}
      }

    }

    const getStyles=(data)=>{
      let total=0;
      let current=0;
      props.option.forEach((item)=>{
        if(item.optionId===data.optionId)
        {
          current+=props?.stats?.[item.optionId]||0;
        }
        total+=props?.stats?.[item.optionId]||0;
      })
      let width=(current/total)*100;
      
      
        return (width-10);
      
    }
    const {op3}=props?.stats;
    console.log(op3);
    function htmlDecode(content) {
      let e = document.createElement('div');
      e.innerHTML = content;
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }
    return (
        <Row className="analytics_question_layer">
          <Col className="analytics_question_content">
            <Row className="analytics_question_heading">
              <p>Question {props?.no}</p>
              <p className="analytics_question_heading_card">2 Marks</p>
              <p className="analytics_question_heading_card">{props.difficulty.charAt(0).toUpperCase()+props?.difficulty.split("").slice(1).join("")}</p>
            </Row>
            <Row className="analytics_question_statement" dangerouslySetInnerHTML={{
                                                __html: htmlDecode(props?.question),
                                              }}/>
            {/* {props?.question}
            </Row> */}
            <Row >
              {
                props?.option.map((item,idx)=>{
                  return(
                    <Row style={getStyle(item,props?.userAnswer,props?.crctAnswer)} className="analytics_question_option">
                    <Col className="option_label">{getNumber(idx)}</Col>
                    <Col className="option_content" dangerouslySetInnerHTML={{
                                                __html: htmlDecode(item?.value),
                                              }}/>
                  </Row>
                  )

                })
              }
              {/* <Row  className="analytics_question_option">
                <Col className="option_label">A</Col>
                <Col className="option_content">lorem ipsum</Col>
              </Row>
              <Row
                className="analytics_question_option"
                style={{
                  background:
                    "linear-gradient(90deg, #FF675E 32.75%, #FFFFFF 96.35%)",
                }}
              >
                <Col className="option_label">B</Col>
                <Col className="option_content">lorem</Col>
              </Row>
              <Row
                className="analytics_question_option"
                style={{
                  background:
                    "linear-gradient(90deg, #7EB6A2 32.75%, #FFFFFF 96.35%)",
                }}
              >
                <Col className="option_label">C</Col>
                <Col className="option_content">lorem ipsum dolor</Col>
              </Row>
              <Row className="analytics_question_option">
                <Col className="option_label">D</Col>
                <Col className="option_content">sit amet</Col>
              </Row> */}
            </Row>
          </Col>
          <Col className="analytics_question_stats">
            {props?.option.map((item,idx)=>{
              return(<Stat style={{width:"100%"}} >
                {getNumber(idx)}
                <StatBar
                  width={getStyles(item)}
                  style={{height: isSmallScreen ? 15 : 30, backgroundColor: `${props.crctAnswer.indexOf(item.optionId)!==-1?"#7EB6A2":""}` }}
                />{" "}

                
                <div style={{minWidth:"100px"}}>{props?.stats?.[item?.optionId]||"0"} Students</div>
              </Stat>)
            })}
            {/* <Stat>
              A
              <StatBar
                style={{ width: 80, height: isSmallScreen ? 15 : 30 }}
              />{" "}
              5 Students
            </Stat>
            <Stat>
              B
              <StatBar
                style={{ width: 120, height: isSmallScreen ? 15 : 30 }}
              />{" "}
              15 Students
            </Stat>
            <Stat>
              C
              <StatBar
                style={{
                  width: 180,
                  height: isSmallScreen ? 15 : 30,
                  backgroundColor: "#7EB6A2",
                }}
              />{" "}
              30 Students
            </Stat>
            <Stat>
              D
              <StatBar
                style={{ width: 100, height: isSmallScreen ? 15 : 30 }}
              />{" "}
              10 Students
            </Stat> */}
            {/* <ResponsiveContainer width="100%" height={300}>
              <BarChart
                width={200}
                height={200}
                data={data}
                layout="vertical"
              >
                <YAxis width={50} dataKey="name" />
                <XAxis type="number" />
                <Tooltip />
                <Bar dataKey="pv" fill="#8884d8"  />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer> */}
          </Col>
        </Row>
    )
}

export default Mmcq

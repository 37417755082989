import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as ExportIcon} from "../../../../../images/analytics/Export.svg"

const useStyles = makeStyles({
    root:{
        background: "rgba(248, 192, 191, 1)",
        cursor: "pointer",
        transition:"0.3s",
        borderRadius: "50%",
        border:"none",
        transform: "scale(1.2)",
        padding: "4px 2px",
        "&:hover": {
            transform: "scale(1.27)",
            boxShadow:"0px 0px 0px 1px rgba(0, 0, 0, 0.2)"
        }
    }
})

function ExportBtn() {

    const classes = useStyles()

    return (
        <button className={classes.root} >
            <ExportIcon></ExportIcon>
        </button>
    )
}

export default ExportBtn

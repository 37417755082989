import React from 'react';
import { BarChart, Bar, Brush,Cell, XAxis, YAxis, CartesianGrid, Tooltip, Label,LabelList, ResponsiveContainer } from 'recharts';
import useStyles from '../../styles';
import { useMediaQuery } from 'react-responsive';
import {ReactComponent as Loader} from "../../../../../../images/analytics/ChartLoader.svg";
const {randomColor} = require("../../utils/Color")

export default function TimePerSubject ({data=[],loading=false,heading="Time Spent on Test of Each Subject",tests=0}) {

    const isSmall = useMediaQuery({query:"(max-width: 472px)"})
    const classes = useStyles()


    
    // const [data,setData] = React.useState([
    //     {
    //         Subject: 'English',
    //         Time: 52,
    //     },
    //     {
    //         Subject: 'Hindi',
    //         Time: 15,
    //     },
    //     {
    //         Subject: 'Maths',
    //         Time: 85,
    //     },
    //     {
    //         Subject: 'History',
    //         Time: 45,
    //     },
    //     {
    //         Subject: 'Science',
    //         Time: 65,
    //     },
    //     {
    //         Subject: 'Geography',
    //         Time: 40
    //     },
    // ])


    const renderCustomizedLabels  = (props) => {
        const { x, y, width, height, value,stroke } = props;
        const radius = 10;
        console.log(props.value);
      
        return (
          <g>
            {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
            <text x={x + width / 2} y={y +height/2} fill={stroke}   textAnchor="middle" dominantBaseline="middle">
            {/* <foreignObject x={0} y={0} width={100} height={100}> */}
            {props.value?props.value:""}
            {/* </foreignObject> */}
             
            </text>
          </g>
        );
      };
      
      console.log(data);



      const CustomTooltip = ({active,payload,label}) => {
        console.log(payload,label,active);
    
        if (active && payload && payload.length) {
            // console.log(props);
          return (
            <div style={{backgroundColor:"#fff",boxShadow:"1px 2px 10px #ccc",padding:"10px",borderRadius:"7px"}} className="custom-tooltip">
              <p className="label">{`${label} : ${payload[0].payload["time taken"]}`}</p>
              {/* <p className="label">{`${payload[1].name} : ${payload[1].payload.time}`}</p> */}
              {/* <p className="intro">{getIntroOfPage(label)}</p> */}
              {/* <p className="desc">Anything you want can be displayed here.</p> */}
            </div>
          );
        }
      
        return null;
      };

    return (
        <div className={classes.container}> 
        {loading?<Loader/>:(<>
            <div className={classes.headingContainer}>
                <h3>Time Spent on Test of Each Subject</h3>
                <p>Test Assigned {tests}</p>
                <p>&nbsp;</p>
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                width={300}
                height={250}
                data={data}
                margin={{
                    top: 25,
                    right: isSmall?3:5,
                    left: isSmall? -7 : 15,
                    bottom: isSmall?20:80,
                }}
                
                barSize={100}
                
                >

                     <Tooltip  content={<CustomTooltip/>} />
                <CartesianGrid strokeDasharray="3 6" />
                <XAxis dataKey="Subject" allowDataOverflow={true}>
                    <Label value="Subjects" offset={isSmall?-25:-30} position="insideBottom"/>
                </XAxis>
                <Brush dataKey="Subject" height={10} endIndex={data.length-1} />
                <YAxis label={{ value: "Time (mins)", angle: -90,offset: isSmall? 18:-2, position: 'insideLeft' }} />
               
                    <Bar dataKey={"Time"} >
                        <LabelList  content={renderCustomizedLabels} dataKey={"time taken"} position="center"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill={randomColor()} ></Cell> )}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            </>)
    }

        </div>
    );
 }


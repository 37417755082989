import React,{ useState,Fragment} from 'react'
import Select from 'react-select';
import { makeStyles } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {useParams} from 'react-router-dom'

const useStyles = makeStyles({
  select:{
    width:'fit-content',
    padding: '0px 10px',
    '& > .select__control':{
      border: '1px solid #B46B82',
      boxShadow: '0px 0px 5px 0px #00000040'
    }
  },
  datePicker:{
    padding:"0px 1px",
    width:"10%",
    marginTop:"3px",
    marginBottom:"0px",
    "& > .MuiInputBase-root > .MuiOutlinedInput-notchedOutline":{
      border: '1px solid #B46B82',
      boxShadow: '0px 0px 5px 0px #00000040'
    }
  },
  '@media screen and (max-width:450px)':{
    select:{
      marginBottom:"5px"
    },
    datePicker:{
      width:"94%",
      marginBottom:"10px"
    }
  }
})

function TestFilter({subjects,filterData,setFilterData}) {

    const classes = useStyles();
    const params = useParams();

    //Filter the subjects to make an label-value pair of options
    subjects = subjects.map(subject=>({
      value:subject.subjectId,
      label:subject.subject
    }))

    const handleSubjectChange = (subject) => {
      setFilterData({...filterData,subjectId:subject && subject.value})
    }
    const handleFromDateChange = (date) => {
      setFilterData({...filterData,'from[gte]':date})
    }
    const handleToDateChange = (date) => {
      setFilterData({...filterData,'to[gte]':date})
    }

    return (
      <section className="test_list_filter my-3">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Fragment>
          {false?
            <><Select
              className = {classes.select}
              classNamePrefix="select"
              isDisabled={false}
              isClearable
              isSearchable
              name="color"
              options={subjects}
              placeholder="Subjects"
              onChange={handleSubjectChange}
            /></>:""}
            <KeyboardDatePicker
              inputVariant="outlined"
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="From"
              value={filterData['from[gte]']}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              autoOk={true}
              onChange={date => handleFromDateChange(date)}
              className={classes.datePicker}
              id="test_filter_date_picker"
            />
            <KeyboardDatePicker
              inputVariant="outlined"
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="To"
              value={filterData['to[gte]']}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              autoOk={true}
              onChange={date => handleToDateChange(date)}
              className={classes.datePicker}
              id="test_filter_date_picker"
            />
          </Fragment>
          </MuiPickersUtilsProvider>
        </section>
    )
}

export default TestFilter

import React from 'react';
import Info from '../../../images/assessment/Info';
// import Info from '../../../images/assessment/Info.png';

const ProgressBar = ({ width , progressText, test = false, color,createdAt }) => {

  const getStartingtDate = () => {
    return new Date(createdAt).toLocaleDateString('us')
  }

  const getStartingTime = () => {
    return new Date(createdAt).toLocaleTimeString()
  }

  return (
    <div className='progressContainer'>
      <div className='progressBar'>
        <p className='progress-percentage'>
          {progressText || 'Progress '} {width}
        </p>
        <div className='progressBarCont'>
          <div className='bar' style={{ width }}></div>
        </div>
        {!test ? (
          <>
            <p className='progress-time'>{getStartingtDate()}</p>
            <p className='progress-time time-grey'>Starting Date</p>
            {/* <p className='progress-time time-grey'>{getStartingTime()}</p> */}
          </>
        ) : (
          ''
        )}
      </div>
      <div className='flex'>
        {/* <img src={Info} alt='info' /> */}
        <Info color={color} />
      </div>
    </div>
  );
};

export default ProgressBar;

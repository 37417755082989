import React, { useEffect } from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import { GlobalProvider } from "./context/GlobalState";
import SecuredRoute from "./components/SecuredRoute/SecuredRoute";
import TaskList from "./components/TaskList/TaskList";
import TaskTopic from "./components/TaskList/TaskTopic";
import LearningResources from "./components/LearningResources/LearningResources";
import Modules from "./components/LearningResources/Modules";
import UploadResponse from "./components/LearningResources/uploadResponse";
import Test from "./components/Test/Test";
import OnlineTest from "./components/OnlineTest/Test/Test";
import TestPreview from "./components/testPreview/TestPreview";
import ResportCard from "./components/ReportCard/ResportCard";
import Setting from "./components/Setting/Setting";
import MyUploads from "./components/Myuploads/Myuploads";
import Login from "./components/Login/Login";
import Chapters from "./components/LearningResources/Chapter/Chapters";
import Audio from "./components/LearningResources/ModuleContents/Audio";
import Video from "./components/LearningResources/ModuleContents/Video";
import Image from "./components/LearningResources/ModuleContents/Image";
import Document from "./components/LearningResources/ModuleContents/Document";
import PDFDocument from "./components/LearningResources/ModuleContents/PDFViewer";
import Auth from "./components/auth/Auth";
import ChapterPage from "./components/LearningResources/Chapter/ChapterPage";
import TaskViewer from "./components/TaskList/TaskViewer";
import Assesment from "./components/LearningResources/Assement/Assesment";
import MyAssessment from "./components/MyAssessment/MyAssessment";
import AssessmentChapter from "./components/MyAssessment/Chapters/Chapters";
import AssessmentTopics from "./components/MyAssessment/Topics/Topics";
import AssessmentTypes from "./components/MyAssessment/AssessmentTypes/AssessmentTypes";
import TestList from "./components/OnlineTest/TestList/TestList";
import Dashboard from "./components/Dashboard/Dashboard";
import AnalyticsTypes from "./components/Analytics/AnalyticsTypes/AnalyticsTypes";
import AssessmentAnalytics from "./components/Analytics/AssessmentAnalytics/AssessmentAnalytics";
import QuestionReport from "./components/Analytics/AssessmentAnalytics/QuestionReport/QuestionReport";
import OnlineTestAnalytics from "./components/Analytics/OnlineTestAnalytics/OnlineTestAnalytics";
import TestCharts from "./components/Analytics/OnlineTestAnalytics/TestCharts/TestCharts";
import TestQuestionReport from "./components/Analytics/OnlineTestAnalytics/TestCharts/QuestionReport/QuestionReport";
import TaskURLViewer from "./components/TaskList/TaskURLViewer";

function App() {
  document.title = "2xcell.in";

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    document.body.appendChild(script);

    window.googleTranslateElementInit = function () {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en", // set the default language of your page
          includedLanguages: "en,hi,ar", // set the languages you want to allow translation to
          layout:
            window.google.translate.TranslateElement.InlineLayout.HORIZONTAL, // set the layout of the button
        },
        "google_translate_element"
      );
    };
  }, []);

  return (
    <>
      <div id="google_translate_element"></div>
      <GlobalProvider>
        <Switch>
          <Route exact path="/auth/:token/:id" component={Auth}></Route>
          <Route exact path="/" component={Login} />
          <SecuredRoute exact path="/dashboard" component={Dashboard} />
          <SecuredRoute exact path="/tasklist" component={TaskList} />
          <SecuredRoute
            exact
            path="/tasklist/topic/:taskid"
            component={TaskTopic}
          />
          <SecuredRoute
            exact
            path="/learningresources"
            component={LearningResources}
          />
          <SecuredRoute
            exact
            path="/learningresources/modules/:module/show"
            component={Image}
          />
          <SecuredRoute
            exact
            path="/learningresources/modules/:module/watch"
            component={Video}
          />
          <SecuredRoute
            exact
            path="/learningresources/modules/:module/view"
            component={Document}
          />
          <SecuredRoute
            exact
            path="/learningresources/modules/:module/pdfview"
            component={PDFDocument}
          />
          <SecuredRoute
            exact
            path="/learningresources/modules/:module/listen"
            component={Audio}
          />
          <SecuredRoute
            exact
            path="/learningresources/:subjectId"
            component={ChapterPage}
          />
          <SecuredRoute
            exact
            path="/learningresources/:subject/:subjectId"
            component={ChapterPage}
          />
          <SecuredRoute
            exact
            path="/learningresources/:subject/:subjectId"
            component={LearningResources}
          />
          <SecuredRoute
            exact
            path="/learningresources/:subject/:subjectId/:chapter/:chapterId"
            component={Chapters}
          />
          <SecuredRoute
            exact
            path="/learningresources/:subject/:subjectId/:chapter/:chapterId/topic/:topic/:topicId"
            component={Modules}
          />
          <SecuredRoute
            exact
            path="/learningresources/:subject/:subjectId/:chapter/:chapterId/topic/:topic/:topicId/practice-session"
            component={Assesment}
          />
          <SecuredRoute
            exact
            path="/learningresources/:subjectName/:subjectId/:chapterName/:chapterId/topics/:topicId/:topicName/tests"
            component={TestList}
          />
          <SecuredRoute
            exact
            path="/learningresources/:subjectName/:subjectId/:chapterName/:chapterId/topics/:topicId/:topicName/tests/:testId"
            component={OnlineTest}
          />

          <SecuredRoute
            exact
            path="/learningresources/modules-assesment/:subjectId"
            component={Modules}
          />
          <SecuredRoute
            exact
            path="/learningresources/modules-assesment/uploadresponse/:assesmentId"
            component={UploadResponse}
          />
          <SecuredRoute
            exact
            path="/testpreview/:topicId/:id/"
            component={TestPreview}
          />
          <SecuredRoute
            exact
            path="/test/:topicId/:taskId/:testId"
            component={Test}
          />
          <SecuredRoute
            exact
            path="/tasklist/task/:taskId/modules/:moduleId"
            component={TaskViewer}
          />
          <SecuredRoute
            exact
            path="/tasklist/task/:taskId/resource"
            component={TaskURLViewer}
          />
          <SecuredRoute
            exact
            path="/myassessment"
            component={AssessmentTypes}
          />
          <SecuredRoute
            exact
            path="/myassessment/practice/subjects"
            component={MyAssessment}
          />
          <SecuredRoute
            exact
            path="/myassessment/practice/subjects/:subjectId/:subjectName/chapters"
            component={AssessmentChapter}
          />
          <SecuredRoute
            exact
            path="/myassessment/practice/subjects/:subjectId/:subjectName/chapters/:chapterId/:chapterName/topics"
            component={AssessmentTopics}
          />
          <SecuredRoute
            exact
            path="/myassessment/practice/subjects/:subjectId/:subjectName/chapters/:chapterId/:chapterName/topics/:topicId/:topicName"
            component={Assesment}
          />
          <SecuredRoute
            exact
            path="/myassessment/online-test/tests"
            component={TestList}
          />
          <SecuredRoute
            exact
            path="/myassessment/online-test/tests/:testId"
            component={OnlineTest}
          />
          <SecuredRoute exact path="/analytics" component={AnalyticsTypes} />
          <SecuredRoute
            exact
            path="/analytics/assessment"
            component={AssessmentAnalytics}
          />
          <SecuredRoute
            exact
            path="/analytics/assessment/report/:subjectId"
            component={QuestionReport}
          />
          <SecuredRoute
            exact
            path="/analytics/tests/charts"
            component={TestCharts}
          />
          {/* <SecuredRoute
            exact
            path='/analytics/tests/charts'
            component={TestCharts}
          /> */}
          <SecuredRoute
            exact
            path="/analytics/tests/charts/report/:testId/:subId"
            component={TestQuestionReport}
          />
          <SecuredRoute exact path="/reportcard" component={ResportCard} />
          <SecuredRoute exact path="/myuploads" component={MyUploads} />

          <SecuredRoute exact path="/setting" component={Setting} />
        </Switch>
      </GlobalProvider>
    </>
  );
}

export default App;

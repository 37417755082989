import PriorityHighOutlined from '@material-ui/icons/PriorityHighOutlined';
import React, { useState,useEffect } from 'react';
import Success from '../../../images/assessment/Success';
import Triangle from '../../../images/assessment/Triangle';
import {useHistory} from 'react-router-dom';

const SubmitTest = ({ modal, setModal,setConfirmed,submit,questions,onlineTestId,preventRefresh,preventBack }) => {

  const history = useHistory()
  const [submitted, setSubmitted] = useState(false);

  const clearStorage = () => {

    // window.removeEventListener('beforeunload',preventRefresh)
    window.removeEventListener('popstate',preventBack)
    

    if(submitted){
      for(let question of questions){
        localStorage.removeItem(onlineTestId + "," + question.questionId._id);
      }
    }
  }

  useEffect(() => {
    clearStorage()
  },[submitted])

  return (
    <div
      className='assesmentPopup'
      style={{ display: modal ? 'flex' : 'none' }}
    >
      <div className='assesmentPopupCont'>
        {submitted ? (
          <>
            <Success />
            <p className='error-text'>Submitted</p>
            <button
              className='save-btn popupClose submit-test-btn'
              onClick={() => {
                history.goBack()
                setSubmitted((state) => !state);
                setModal((state) => !state);
              }}
              style={{ marginTop: '1.5rem' }}
            >
              Okay
            </button>
          </>
        ) : (
          <>
            <div className='warning-popup'>
              <Triangle color='#BF8296' />
              <div className='warning-symbol'>
                <PriorityHighOutlined />
              </div>
            </div>
            <p className='error-text'>Are You Sure Want to</p>
            <p className='error-text'>Submit Your Answer?</p>
            <div className='flex'>
              <button
                className='save-btn popupClose submit-test-btn'
                onClick={async() => {
                  await submit()
                  setSubmitted((state) => !state)
                  setConfirmed(true)
                }}
              >
                SUBMIT
              </button>
              <button
                className='save-btn popupClose submit-test-btn'
                onClick={() => setModal((state) => !state)}
              >
                CANCEL
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SubmitTest;

import React from "react";
import "./QuestionReport.css";
import { Col, Container, Row } from "react-bootstrap";
import Table from "./Table/Table";
import SideBoard from "../../SideBoard/SideBoard";
import { useMediaQuery } from "react-responsive";
import Question from "./Questions/Question"
import axios from "axios"
import {useParams} from 'react-router-dom'

const QuestionReport = () => {

  //common
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });
  const {subjectId} = useParams()
  const sectionId = window.localStorage.getItem('sectionId')

  //States to hold the data
  const [questionDetails,setQuestionDetails] = React.useState([])

  //Fucntion to fetch the question details
  const fetchQuestionDetails = async()=>{  
    try{
      const response = await axios.get(`${process.env.REACT_APP_ORIGIN2}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectId}/questiondetails`)
      console.log("Question Details of Practice ====>",response)      
      setQuestionDetails(response.data.data.questiondetails)
    }catch(error){
      console.log("Error in fetching question details",error)
    }
  }

  React.useEffect(() => {
    fetchQuestionDetails()
  },[])

  return (
    <Container fluid="xl" className="analytics_main_section">
      {/* <SideBoard /> */}
      <Row className="questionanalytics_section_layer my-5">
        {questionDetails.map((question,index)=> <Question type={question.questionId.questionType} data={question} index={index} />)}      
      </Row>
      <Table />
    </Container>
  );
};

export default QuestionReport;
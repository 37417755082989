import React, { useState, useEffect,useContext } from 'react';
import '../../LearningResources/styles/LearningResources.css';
import TablePagination from '@material-ui/core/TablePagination';
import { NavLink } from 'react-router-dom';
import SingleTopic from './SingleTopic/SingleTopic';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useAlert } from 'react-alert';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { GlobalContext} from "../../../context/GlobalState"
import { useMediaQuery } from 'react-responsive';
const {isDisabled} = require('../../LearningResources/util.js')

const LearningResourcesStyle = (url) => ({
  //Here url is the thumbnail
  backgroundImage: `url(${
    url ? url : process.env.PUBLIC_URL + '/backgrounds/revisionTask/nature.png'
  })`,
  backgroundSize: 'cover',
  minHeight: '245px',
  height: '100%',
  borderBottomRightRadius: '17px',
  borderBottomLeftRadius: '17px',
});

const AssessmentTopics = (props) => {
  console.log('in chapters comp,props->', props);
  document.title = '2xcell.in';

  const isSmall = useMediaQuery({query:"(max-width: 472px)"})
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //convert html, used for url link of each card in different language
  function decodeHTMLEntities(text) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  }

  // fetching topics of chapters using subect ID
  // loading for chapters
  const [loaderTopic, setloaderTopic] = useState(false);
  const [topicList, setTopicList] = useState([]);
  const params = useParams();
  const subjectId = params.subjectId;
  const chapterId = params.chapterId;
  const subjectName = decodeHTMLEntities(params.subjectName);
  const chapterName = decodeHTMLEntities(params.chapterName);
  const alert = useAlert();
  const {userDetails,setUserDetails} = useContext(GlobalContext);

  const [fetched, setFetched] = useState(false);
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const apiForTopics = `${process.env.REACT_APP_ORIGIN}/api/v1/chapters/${chapterId}`;

    setloaderTopic(true);
    axios
      .get(apiForTopics, {
        headers: {
          token: authToken,
        },
      })
      .then((resp) => {
        console.log(resp);

        let topics = resp.data.data.chapter.totalTopics?resp.data.data.chapter.topics.filter(item=> item.lang === window.localStorage.getItem("language")):[]
        setTopicList(topics);
        setloaderTopic(false);
        setFetched(true);
      })
      .catch((err) => {
        alert.error('Topics not fetched');
        setloaderTopic(false);
      });
  }, []);

  const getCurrentDate = ()=>{
    const date = new Date()
    const months = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    
    return `${date.getDate()} ${months[date.getMonth()]},${date.getFullYear()}`
  }

  useEffect(()=>{
    setUserDetails({...userDetails,selectedTab:"My Assessment"})
    setUserDetails({...userDetails,selectedPath:{
      subject:subjectName,
      chapter:chapterName,
      topic:""
    }})
  },[])

  return (
    <>
      <section className='learningResources my-md-5 my-sm-1 p-lg-0 p-2 p-sm-2 py-sm-4 col-md-9 col-sm-12  mx-auto'>
        <div className='container'>
          {!isSmall && <div className='box mb-5 row' style={{alignItems: 'center'}}>
            <span style={{display: 'flex',flexDirection: 'column',width: 'fit-content'}}>
              <h2 className='fw-bold col-sm-9 white_color' style={{ fontWeight: 'bolder',color: 'white' }} >{userDetails.grade}</h2>
              <h1 style={{ fontWeight: 'bolder',color: 'white'}} >Practice Assessment</h1>
            </span>
            <h5 className="col-sm-3" style={{marginLeft:"auto",fontSize:'90%',fontWeight:'bolder',marginBottom:"auto",textAlign:"right"}}>
              <CalendarTodayIcon style={{width:"17px"}}/> {getCurrentDate()}
            </h5>
            <p className=" white_color" style={{color:'white'}}>{subjectName + " > " + chapterName + "> Topics" }</p>
          </div>}
          <div className='row my-3 gy-4'>
            {topicList.length === 0 ? (
              <h1 className='text-center'>
                {fetched ? '0 topics to show' : 'Loading...'}
              </h1>
            ) : (
              topicList.map((val, i) => {
                return (
                  <SingleTopic
                    key={i}
                    name={val.name}
                    totalModules={val.totalModules}
                    url={props.match.url}
                    id={val._id}
                    thumbnail={val.thumbnail}
                    disabled={ val.disabled || isDisabled(val.modules)}
                  ></SingleTopic>
                );
              })
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default AssessmentTopics;

import React, { useState,useEffect } from "react";
import axios from "axios";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalState";
import { useAlert } from "react-alert";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { TableHead } from "@material-ui/core";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: "auto",
  },
});

const ReportCardTable = ({filterUrl}) => {

  const alert = useAlert();
  const { userId, authToken } = useContext(GlobalContext).userDetails;
  let [rows, setRows] = useState(undefined);

  useEffect(() => {
    const getData = async () => {
      if (filterUrl) {
        try {
          const reportData = await axios.get(`${filterUrl}`, {
            headers: {
              token: authToken,
            },
          });
          console.log("Report card data->",reportData);
          if (reportData.data.status === 'success') {
            let submissions =  reportData.data.data.reports.reverse();
            submissions = submissions.map(e => {
              let date = new Date(e.submittedAt || null);
              date = e.submittedAt?date.toISOString().substring(0, 10):"-"
              console.log(date)
              return {
              subject: e.questionSet.subjectId.name,
              chapter: (e.questionSet.chapters.map(chapter => chapter.name)).join(","),
              topic: (e.questionSet.topics.map(topic => topic.name)).join(","),
              totalQuestions: e.totalQuestions,
              correct: e.correctAnswers,
              incorrect: e.wrongAnswers,
              unattempted: e.unattemptedAnswers,
              date,
              score: e.totalScore + "/" + e.totalMarks,
            }
          })
            setRows(submissions);
          }
        } catch (e) {
          alert.error('Not able to fetch reports, Please refresh')
          console.log(e.message);
        }
      }
    }
    getData()
  }, [userId, authToken, alert, filterUrl])
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Report Card table">
          <TableHead>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 30, { label: "All", value: -1 }]}
                colSpan={10}
                count={rows?.length||0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell align="center">Subject</TableCell>
              <TableCell align="center">Chapter</TableCell>
              <TableCell align="center">Topic</TableCell>
              <TableCell align="center">Total Questions</TableCell>
              <TableCell align="center">Correct Answer</TableCell>
              <TableCell align="center">Incorrect Answer</TableCell>
              <TableCell align="center">Not Attempted</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            )?.map((row, index) => (
              <TableRow key={`${row.subject}${index}`}>
                <TableCell align="center">{row.subject}</TableCell>
                <TableCell align="center">{row.chapter}</TableCell>
                <TableCell align="center">{row.topic}</TableCell>
                <TableCell align="center">{row.totalQuestions}</TableCell>
                <TableCell align="center">{row.correct}</TableCell>
                <TableCell align="center">{row.incorrect}</TableCell>
                <TableCell align="center">{row.unattempted}</TableCell>
                <TableCell align="center">{row.date}</TableCell>
                <TableCell align="center">{row.score}</TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReportCardTable;



export const createFilteredTask = (task, userId = undefined) => {
    let cDate = new Date();
    let toDate = new Date(task.to);
    let status = '';
    if (task.submittedBy.length > 0){
        status = 'Completed';
    } else if (cDate.getTime() > toDate.getTime()) {
        status = "Due"
    } else {
        status = 'active';
    }
    return {
        ...task,
        module: task.content?.name, 
        status,
        tasktype: task.taskType, 
        from: new Date(task.from).toISOString().substring(0, 10),
        to: toDate.toISOString().substring(0, 10)
    }
}

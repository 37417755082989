import React,{ useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import './MediaViewer.css'

const pdfViewer = {
  width: '90%',
  height: '150%',
  margin: 'auto',
  paddingTop: '1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

function Document(props) {

  const history = useHistory();
  const [loaded,setLoaded] = useState(false)

  useEffect(() => {
    document.getElementById('pdf_iframe').addEventListener("load",()=>{
      setLoaded(true);
    })
  })

  useEffect(() => {
    if(!props.location?.useProps?.fileUrl){
      history.goBack();  
    }
  }, [props.location])

  return (
    <div style={pdfViewer}>
      {/* <h3>{props.location.useProps.moduleName}</h3> */}
      <iframe
        id="pdf_iframe"
        src={props.location.useProps?.fileUrl}
        width='80%'
        height='800px'
        title={props?.location?.useProps?.moduleName || 'doc'}
         style={{display:loaded?'block':'none'}}
      ></iframe>
      <h4 className="text-center" style={{display:!loaded?'block':'none'}} >Loading...</h4>
    </div>
  );
}

export default Document;

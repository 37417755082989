import React from 'react';
import { BarChart, Bar, Brush,Cell, XAxis, YAxis, CartesianGrid,Legend, Tooltip, Label,LabelList, ResponsiveContainer } from 'recharts';
import useStyles from '../../styles';
import { useMediaQuery } from 'react-responsive';
import {ReactComponent as Loader} from "../../../../../../images/analytics/ChartLoader.svg";

export default function DifficultyReport ({data,loading=false}) {

    const isSmall = useMediaQuery({query:"(max-width: 472px)"})
    const classes = useStyles()

    const renderCustomizedLabels  = (props) => {
        const { x, y, width, height, value,stroke } = props;
        const radius = 10;
        console.log(props.value);
      
        return (
          <g>
            {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
            <text x={x + width / 2} y={y +height/2} fill={stroke}   textAnchor="middle" dominantBaseline="middle">
            {/* <foreignObject x={0} y={0} width={100} height={100}> */}
            {props.value?props.value:""}
            {/* </foreignObject> */}
             
            </text>
          </g>
        );
      };
      

    // const [data,setData] = React.useState([
    //                 {
    //                     Subject: 'English',
    //                     Easy: 52,
    //                     Intermediate: 42,
    //                     Difficult: 72,
    //                     "Total Easy":240,
    //                     "Total Difficult":240,
    //                     "Total Intermediate":240
    //                 },
    //                 {
    //                     Subject: 'Hindi',
    //                     Easy: 15,
    //                     Intermediate: 20,
    //                     Difficult: 82,
    //                     "Total Easy":240,
    //                     "Total Difficult":240,
    //                     "Total Intermediate":240,
    //                 },
    //                 {
    //                     Subject: 'Maths',
    //                     Easy: 85,
    //                     Intermediate: 82,
    //                     Difficult: 12,
    //                     "Total Easy":240,
    //                     "Total Difficult":240,
    //                     "Total Intermediate":240
    //                 },
    //                 {
    //                     Subject: 'History',
    //                     Easy: 4,
    //                     Intermediate: 52,
    //                     Difficult: 25,
    //                     "Total Easy":240,
    //                     "Total Difficult":240,
    //                     "Total Intermediate":240
    //                 },
    //                 {
    //                     Subject: 'Science',
    //                     Easy: 65,
    //                     Intermediate: 0,
    //                     Difficult: 2,
    //                     "Total Easy":240,
    //                     "Total Difficult":240,
    //                     "Total Intermediate":240
    //                 },
    //                 {
    //                     Subject: 'Geography',
    //                     Easy: 63,
    //                     Intermediate: 5,
    //                     Difficult: 77,
    //                     "Total Easy":240,
    //                     "Total Difficult":240,
    //                     "Total Intermediate":240
    //                 },
    //             ])
    
    const getTotal=()=>{
        let total=0;
        console.log(data);
        data.forEach(item=>{
            total+=item["Total Easy"]+item["Total Intermediate"]+item["Total Difficult"]
        })
        return total;
    }
    return (
        <div className={classes.container}> 
        {loading?<Loader/>:(<>
            <div className={classes.headingContainer}>
                <h3>Difficulty Level Report</h3>
                <p>Quesions Attempted- {getTotal()}</p>
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                width={300}
                height={250}
                data={data}
                barGap={2}
                margin={{
                    top: 25,
                    right: isSmall?3:5,
                    left: isSmall? -7 : 15,
                    bottom: isSmall?20:80,
                }}
                barSize={100}
                
                >
                <CartesianGrid strokeDasharray="3 6" />
                <XAxis dataKey="Subject" allowDataOverflow={true}>
                    <Label value="Subjects" offset={isSmall?-25:-30} position="insideBottom"/>
                </XAxis>
                <Brush dataKey="Subject" height={10} endIndex={data.length-1} />
                <YAxis label={{ value: "Questions", angle: -90,offset: isSmall? 18:-2, position: 'insideLeft' }} />
                <Tooltip />
                    <Bar dataKey={"Easy"} stackId="a" fill="rgba(125, 230, 219, 1)">
                        <LabelList content={renderCustomizedLabels} style={{fontWeight:"800"}} dataKey={"Easy"} position="top"/>
                        {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(125, 230, 219, 1)" ></Cell> )} */}
                    </Bar>
                    <Bar dataKey={"Total Easy"} tooltipType="none" stackId="a" fill="rgba(125, 230, 219, 1)">
                        <LabelList content={renderCustomizedLabels} style={{fontWeight:"800"}} dataKey={"Total Easy"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#cccc" ></Cell> )}
                    </Bar>

                    <Bar dataKey={"Intermediate"} stackId="b" fill="rgba(255, 152, 152, 1)">
                        <LabelList content={renderCustomizedLabels} style={{fontWeight:"800"}} dataKey={"Intermediate"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(255, 152, 152, 1)" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Total Intermediate"} tooltipType="none" stackId={"b"} fill="rgba(255, 152, 152, 1)">
                        <LabelList content={renderCustomizedLabels} style={{fontWeight:"800"}} dataKey={"Total Intermediate"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#cccc" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Difficult"}  stackId="c" fill="rgba(120, 234, 165, 1)">
                        <LabelList content={renderCustomizedLabels} style={{fontWeight:"800"}} dataKey={"Difficult"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(120, 234, 165, 1)" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Total Difficult"} tooltipType="none" stackId="c" fill="rgba(120, 234, 165, 1)">
                        <LabelList content={renderCustomizedLabels} style={{fontWeight:"800"}} dataKey={"Total Difficult"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#ccc" ></Cell> )}
                    </Bar>
                <Legend payload={[{
    id:"easy",
    value:"Easy",
    type:"rect",
    color:"#5CE0D2"
  },{
    id:"medium",
    value:"Intermediate",
    type:"rect",
    color:"#FF7E7E"
  },
  {
    id:"difficult",
    value:"Difficult",
    type:"rect",
    color:"#56E58F"
  },{
      id:"total question",
      value:"Total Q'",
      type:"rect",
      color:"#cccc"
  }
  
  ]} />
                </BarChart>
            </ResponsiveContainer>
            </>)}
        </div>
    );
 }


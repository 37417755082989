import styled from "styled-components";

const Stat = styled.div`
  display: flex;
  margin-bottom: 5px;
  font-weight: 700;
`;

export default Stat



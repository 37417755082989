import React, { useState,useEffect } from "react";
import axios from "axios";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalState";
import { useAlert } from "react-alert";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles({
  table: {
    minWidth: "auto",
  },
  btnColor: {
    backgroundColor: "#5CE0D2",
    width: "200px",
    "&:hover": {
      backgroundColor: "#5CE0D2",
    },
  },

  closeBtn: {
    cursor: "pointer",
  },
});


const MyUploads = () => {
  const classes = useStyles();
  const alert = useAlert();
  const { userId, authToken, section, grade } = useContext(GlobalContext).userDetails;
  let [rows, setRows] = useState(undefined);

  useEffect(() => {
    const getUploads = async () => {
      try {
        const uploadData = await axios.get(`${process.env.REACT_APP_ORIGIN}/api/v1/students/${userId}/uploads`, {
          headers: {
            token: authToken,
          },
          transformResponse: [
            response => {
              const data = JSON.parse(response);
              console.log("Fetch upload data response==>",data)
              if (data.status === 'success') return data.data.submissions
              if (data?.status === 'fail' && data?.error?.status === 'fail') {
                throw new Error(data.message);
              }
            }
          ]
        });
        console.log("uploadData==>",uploadData);
        if (uploadData.status === 200) {
        let submissions = uploadData.data;
        console.log("List of submissions==>",submissions)
        submissions = submissions.map(task => ({
          module: task.taskId.topic,
          subject: task.taskId.subject,
          taskType: task.taskId.taskType,
          file: task.resources[0]?.fileName?.split('/')[1],
          fileUrl: task.resources[0]?.fileUrl,
          taskId: task.taskId._id,
          id: task._id,
          date: task.submittedAt
        }))
        setRows(submissions);
        }
      } catch (e) {
        alert.error(e.message)
      }
    }
    getUploads()
  }, [userId, authToken, alert])

  const handleUploaDelete = async (e) => {
    try {
      let url = `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/tasks/${e.taskId}/submittasks/${e.id}`;
    const data = await axios.delete(url, {
      headers: {
        token: authToken,
      },
      transformResponse: [
        response => {
          const data = JSON.parse(response);
          if (data.status === 'success'){
            return data
          }else{
            alert.error(data.message);
            console.error(data)
          }
          
        }
      ]
    });
    console.log(data)
    } catch (e) {
      alert.error(e.message);
      console.log(e.message)
    }
  };
  return (
    <>
      <div className="border upload rounded p-4 mt-5">
        <h1 className="fw-bold">My Uploads</h1>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Subject</TableCell>
                <TableCell align="center">Module</TableCell>
                <TableCell align="center">Task</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">File</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.map((row, index) => (
                <TableRow key={`${row.subject}${index}`}>
                  <TableCell align="center">{row.subject}</TableCell>
                  <TableCell align="center">{row.module}</TableCell>
                  <TableCell align="center">{row.taskType}</TableCell>
                  <TableCell align="center">{row.date.slice(0,10)}</TableCell>
                  <TableCell align="center">
                    {row.file}{" "} <CloseIcon onClick={() => handleUploaDelete(row)} className={classes.closeBtn} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <div className="text-center mt-4">
          <Button variant="contained" className={classes.btnColor}>
            Save
          </Button>
        </div> */}
      </div>
    </>
  );
};

export default MyUploads;

import Mcq from './Mcq'
import OneWord from './OneWord'
import TrueFalse from './TrueFalse'
import FillUp from './FillUp'

function Question({type,data,index}) {
    if(type=='mcq') return <Mcq data={data} index={index}/>
    if(type=='mmcq') return <Mcq data={data} index={index}/>
    if(type=='oneword') return <OneWord data={data} index={index}/>
    if(type=='fillup') return <FillUp data={data} index={index}/>
    if(type=='TrueFalse') return <TrueFalse data={data} index={index}/>
    return <></>
}

export default Question

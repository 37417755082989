import React from 'react';

const Mark = (props) => {
  return (
    <svg
      width='104'
      height='104'
      viewBox='0 0 104 104'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_13:5765)'>
        <path
          d='M52 95.6975C78.5097 95.6975 100 74.2749 100 47.8487C100 21.4226 78.5097 0 52 0C25.4903 0 4 21.4226 4 47.8487C4 74.2749 25.4903 95.6975 52 95.6975Z'
          fill={props.color ? props.color : '#FF675E'}
        />
        <path
          opacity='0.55'
          d='M52.0001 91.3359C76.0933 91.3359 95.6248 71.866 95.6248 47.8487C95.6248 23.8315 76.0933 4.36163 52.0001 4.36163C27.9069 4.36163 8.37549 23.8315 8.37549 47.8487C8.37549 71.866 27.9069 91.3359 52.0001 91.3359Z'
          fill='white'
        />
        <path
          d='M51.9999 86.8976C73.6342 86.8976 91.1722 69.4149 91.1722 47.8488C91.1722 26.2827 73.6342 8.79993 51.9999 8.79993C30.3657 8.79993 12.8276 26.2827 12.8276 47.8488C12.8276 69.4149 30.3657 86.8976 51.9999 86.8976Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_13:5765'
          x='0'
          y='0'
          width='104'
          height='103.698'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_13:5765'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_13:5765'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Mark;

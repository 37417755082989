import React,{ useState, useEffect} from 'react'
import './descCard.css';
import editImg from '../../../../../images/edit.svg';
import deleteImg from '../../../../../images/delete.svg';
import Checkbox from '@material-ui/core/Checkbox';
import uploadBtnImg from '../../../../../images/uploadBtn.svg';
import { button, head, input, remarkStyle, signature } from '../../helper';
import DefaultImage from '../../../../../images/Navbar/title.png'
import { htmlDecode } from '../../../../../utils/parse';

export default function DescCard({ color = '',data,number,correctAnswer,setCorrectAnswer }) {

  const question = data
  const parseString = (str) => {
    return new DOMParser()
      .parseFromString(str, "text/html")
      .body.textContent.trimStart();
  }

  return (
    <>
      <div className='container  question_card  my-4'>
        <div className='superhead'>
          <div className='remark' style={remarkStyle(color)}>Give Your Answer In Detail</div>
          <div className='desc__head' style={head(color)}>
            <div className='descCard'>
              <div className='descCard__left'  dangerouslySetInnerHTML={{__html:htmlDecode(`Q${number}. ${question?.question}`)}}></div>
              {/* <div className='descCard__right'>
                <img src={editImg} />
                <img src={deleteImg} />
                <Checkbox className='checkbox' />
              </div> */}
            </div>
            <div className='desc'>
              {question?.images?.questionImg ?<img className='desc_image' src='https://source.unsplash.com/random' />:''}
              <div className='upload'>
                <textarea placeholder='Write your answer here' style={input(color)} onChange={(e)=>{setCorrectAnswer(e.target.value)}} />
                <span className='or'>or</span>
                <button className='upload_btn' style={button(color)}>
                  <img src={uploadBtnImg} />Upload File</button>
                <span className='button_details'>Upload JPG/PNG,PDF,Word,<br /> doc & PPT
                </span>
              </div>
            </div>
            <div className='last'>
                {question.onModel==="admin"?<img src={DefaultImage} className='signarture' style={{...signature(color),width:'50px'}} />:<div className='signarture' style={signature(color)}>{question.creatorId?question.creatorId.name:""}<br />{question.schoolId.name}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

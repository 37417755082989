import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexWrap:"wrap",
        gap:"10px",
        marginBottom:"10px"
    },
    card:{
        // maxWidth:"280px",
        // minWidth:"120px",
        // width:"20%",
        // margin:"10px",
        height:"100px",
        boxSizing:"border-box",
        textAlign:"center",
        fontSize:"0.9em",
        padding:"5px 1em",
        background: "#FFFFFF",
        border: "1px solid #B46B82",
        // boxSizing: "border-box",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "7px",
        fontWeight:"bold",
        // margin:"0px 5px",
        // marginTop:"20px"
    },
    "@media screen and (max-width: 472px)":{
        card: {
            display: "flex",
            // width:"80px",
            flexDirection:"column",
            justifyContent:"space-between",
            alignItems:"center",
            // padding:"5px 0px",
            minWidth:"80px",
            height:"70px !important",
            "& > *":{
                fontSize:"0.7em",
            }
        }
    }
})

/**
 * Component to render the minicards on top of the page
*/
function MiniCards({heading,body}) {

    const styles = useStyles()
    const [data,setData] = React.useState([
        {
            heading:"Student Average Score",
            body: "45",
        },
        {
            heading:"Class Average Score",
            body: "35",
        },
        {
            heading:"Test Assigned",
            body: "35/70",
        },
        {
            heading:"Questions Attempted",
            body: "800/1000",
        },
        {
            heading:"Time Spent on Test",
            body: "2:00:00",
        },
        {
            heading:"Average Time Spent on Each Question",
            body: "00:05:00",
        },
        {
            heading:"Concepts covered in Tests",
            body: "30",
        },
        {
            heading:"Chapters Covered",
            body: "12",
        },
    ])

    // const Card = ({heading,body}) => 
    // <Grid item lg={4} sm={6} className={classes.report} style={isSmall ? {marginLeft:"auto"}:{}} >
       


    return (
    
    <Grid item  lg={3} md={3} sm={6} xs={6}    >
       <div className={styles.card}>
            <div>{heading}</div>
        <div>{body}</div>
        </div>
    </Grid>
            //  <Card heading={heading} body={body}/>
        
        /* <div className={styles.container}>
            {data.slice(0,4).map(item => <Card heading={item.heading} body={item.body}/>)}
        </div> */
        /* <div className={styles.container}>
            {data.slice(0,4).map(item => <Card heading={item.heading} body={item.body}/>)}
        </div> */
    
    )
}

export default MiniCards

import React from 'react';
import '../styles/LearningResources.css';
import { NavLink } from 'react-router-dom';
import { image } from '../../helpers';

// const LearningResourcesStyle={
//     backgroundSize: "cover",
//     minHeight: "245px",
//     height: "100%",
//     borderRadius: "17px",
//     boxShadow: "#00000085 0px 0px 4px",
// };

// const image = url => url? url:process.env.PUBLIC_URL + "/backgrounds/revisionTask/nature.png"

// const CardStyle =(url)=> ({
//     backgroundImage: `url(${url? url:process.env.PUBLIC_URL + "/backgrounds/revisionTask/nature.png"})`,
//     backgroundSize:'cover',
//     backgroundRepeat: "no-repeat",
//     height:"75%",
//     borderRadius: "17px 17px 0px 0px",
//     borderBottom:'1px solid #0000005c',
//     textDecoration: "none !important",

//   })

//convert html, used for url link of each card in different language
function decodeHTMLEntities(text) {
  var textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}

//function to fix the thumbnail url
const fixThumbnailUrl = (url) => {
  if (!url) {
    return '';
  }

  url = url.trim().split(' ').join('%20');
  if (url.startsWith("https://") || url.startsWith('https://xcell-cms-files.s3.amazonaws.com')) {
    return url;
  } else {
    return 'https://xcell-cms-files.s3.amazonaws.com' + url;
  }
};

const fixName = (name) => {
  const charArrays = ["?", "+", "&", "/"];
  charArrays.forEach((c) => {
    name = name.replaceAll(c, "");
  });
  return name;
}

const SingleChapter = (props) => {
  //props has fields of {chapterId,name,num,subjectId}

  // console.log(fixThumbnailUrl(props.thumbnail));
  return (
    <div className='col-sm-2 col-6' style={{display:props.disabled?'none':'block'}}>
      <NavLink
        style={{ textDecoration: 'none' }}
        to={`/learningresources/${fixName(props.selectedSubject)}/${
          props.subjectId
        }/${decodeHTMLEntities(props.name)}/${props.chapterId}`}
      >
        <div className='card card-height card-radius'>
          <div className='card-img'>
            <img
              src={image(fixThumbnailUrl(props.thumbnail))}
              className='card-thumbnail'
              alt={props?.name || 'sub'}
            />
          </div>
          {/* <div className="text-center" style={LearningResourcesStyle}> */}
          <div className='text-center text-dark' style={{fontWeight:"bold"}}>
            <p
              dangerouslySetInnerHTML={{ __html: props.name }}
              className='mar-p'
            ></p>
            {/* <p>Topics : {props.num}</p> */}
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default SingleChapter;

import { NavLink } from 'react-router-dom';
import React from 'react';
import './styles/Assesment.css';
import PractiseTest from '../../images/assessment/PractiseTest';
import Go from '../../images/assessment/Go';
import OnlineTest from '../../images/assessment/OnlineTest';
import {useParams} from 'react-router-dom'
import {useMediaQuery} from 'react-responsive'

const AssesmentStyle = {
  backgroundSize: 'cover',
  minHeight: '245px',
  height: '100%',
  borderRadius: '17px',
};

// const CardStyle = {
//   background: 'linear-gradient(0deg,#000000a6,transparent)',
//   height: '100%',
//   borderRadius: '17px',
//   color: 'white',
// };

const CardStyle2 = {
  background: 'linear-gradient(0deg,#000000a6,transparent)',
  height: '100%',
  borderRadius: '17px',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
};

const fixName = (name) => {
  const charArrays = ["?", "+", "&", "/"];
  charArrays.forEach((c) => {
    name = name.replaceAll(c, "");
  });
  return name;
}

const Assesment = ({ worksheets, tests, fetchedTests, fetchedWorksheets }) => {

  const params = useParams()
  const isSmall = useMediaQuery({query:"(max-width:472px)"})
  console.log(tests);
  return (
    <>
      <div className='tests-links'>
        <NavLink to={`/learningresources/${fixName(params.subject)}/${params.subjectId}/${fixName(params.chapter)}/${params.chapterId}/topic/${fixName(params.topic)}/${params.topicId}/practice-session`} className='link-box'>
          <PractiseTest />
          <p>&nbsp;Practice Session</p>
          {!isSmall && <Go />}
        </NavLink>
        <NavLink
          to={`/learningresources/${fixName(params.subject)}/${params.subjectId}/${fixName(params.chapter)}/${params.chapterId}/topics/${params.topicId}/${fixName(params.topic)}/tests`}
          className='link-box secondary-box'
        >
          <OnlineTest />
          <p>&nbsp;Online Test</p>
          {!isSmall && <Go />}
        </NavLink>
      </div>
      
    </>
  );
};

export default Assesment;

import React, { useState,useContext } from "react";
import ReportCardTable from "./ReportCardTable";
import ReportCardFilter from "./ReportCardFilter";
import { GlobalContext } from "../../context/GlobalState";

const ResportCard = () => {
  document.title = '2xcell.in';
  const { userId } = useContext(GlobalContext).userDetails;
  const filterUrl = `${process.env.REACT_APP_ORIGIN2}/api/v1/students/${userId}/reports?export=true`

  return (
    <>
      <section className="reportCard my-5">
        <div className="container">
          <div className="row">
            {/* <div className="col-sm-2">
              <ReportCardFilter filterUrl={filterUrl} setFilterUrl={setFilterUrl}/>
            </div> */}
            <div className="col-sm-12 mt-lg-0 mt-0">
              <ReportCardTable filterUrl={filterUrl}/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResportCard;

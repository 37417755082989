import React from 'react'
import ExportButton from "./ExportButton/ExportButton"
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    root:{
        display: 'flex',
        flexDirection: 'row',
        width: 'fit-content',
    }
})

function Buttons(props) {

    const classes = useStyles()
    console.log(props);

    return (
        <div onClick={()=>{}  }className={classes.root}>
            <ExportButton></ExportButton>
        </div>
    )
}

export default Buttons

import React,{useState} from 'react';
import { head, input, remarkStyle, signature } from '../../helper';
import './tfCard.css';
import DefaultImage from '../../../../../images/Navbar/title.png'
import { htmlDecode } from '../../../../../utils/parse';

// import EditCard from "../../EditCard/EditCard";
export default function TFCard({ img, img2,data,number, color = '',correctAnswer,setCorrectAnswer,onlineTestId }) {

  const question = data
  const id = onlineTestId + "," + question._id

  const handleClick = (e)=>{
    console.log("Now clicked ===> ",e.target.value)
    localStorage.getItem(id)===e.target.value?localStorage.removeItem(id):localStorage.setItem(id, e.target.value)
    setCorrectAnswer(e.target.value)
  }

  const parseString = (str) => {
    return new DOMParser()
      .parseFromString(str, "text/html")
      .body.textContent.trimStart();
  }

  return (
    <>
      {/* {edit && <EditCard edit={edit} closeEdit={closeEdit} quesType="tf" />} */}
      <div className='container question_card  my-4'>
        <div className='superhead'>
          <div className='remark' style={remarkStyle(color)}>True or False</div>
          <div className='tf__head' style={head(color)}>
            <div className='tfCard'>
              <div className='tfCard__left'>
                <h6 style={{fontWeight:"bolder",marginTop:"5px"}}  dangerouslySetInnerHTML={{__html:htmlDecode(`Q${number}. ${question?.question}`)}}></h6></div>
              {/* <div className='tfCard__right'>
                <img src={editImg} onClick={openEdit} />
                <img src={deleteImg} />
              </div> */}
            </div>
            <div className='main_div'>
              {question?.images?.questionImg ? <img className='tf_image' src={question?.images?.questionImg} /> : null}
              <div className='tf' style={question?.images?.questionImg ? { width: '70%' } : { width: '80%' }}>
                {
                  data?.options.map((option, i) =>
                  <div className='up top-left'>
                    <label class='opt1-container' style={input(color)}>
                      {option.value?option.value:"-"}
                      <input type='radio' name='radio' value={option.optionId} onClick={handleClick} checked = {localStorage.getItem(id) === option.optionId} />
                      <span class='checkmark' style={input(color, 3)}></span>
                      {/* <div className='imgBlock' style={img2? {background: `url(${img2}) no-repeat center center`,backgroundSize: 'cover',}: { display: 'none' }}></div> */}
                      {/* {img2? <img className="ans_mcq_image" src={img2}/> : null} */}
                    </label>
                  </div>)
                }
                {/* <div className='up top-right ml-6'>
                  <label class='opt1-container' style={input(color)}>
                    False
                    <input type='radio' name='radio' onClick={()=>{setCorrectAnswer(false)}} />
                    <span class='checkmark' style={input(color, 3)}></span>
                    {/* <div className='imgBlock' style={img2? {background: `url(${img2}) no-repeat center center`,backgroundSize: 'cover',}: { display: 'none' }}></div> */}
                    {/* <div className="imgBlock" style={{background:`url(${img2}) no-repeat center center`,backgroundSize: "cover"}}></div> */}
                    {/* {img2? <img className="ans_tf_image" src={img2}/> : null} 
                  </label>
                </div> */}
              </div>
            </div>
            <div className='last'>
                {question.onModel==="admin"?<img src={DefaultImage} className='signarture' style={{...signature(color),width:'50px'}} />:<div className='signarture' style={signature(color)}>{question.creatorId?question.creatorId.name:""}<br />{question.schoolId.name}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import "./Login.css";
import LoginBox from "./LoginBox/LoginBox";
import Footer from "./Footer/Footer";
import wave from "./wave.png"

function Login() {
  return (
    <div className="Login">
      <div className="bg-1"></div>
      <img src={wave} className="wave" alt="" />
      <LoginBox />
      <Footer />
    </div>
  );
}

export default Login;

import "./QuestionReport.css";
import React,{useEffect,useState,useContext} from "react";
import { Col, Container, Row } from "react-bootstrap";
// import {} from 'react-router-dom';
// import Table from "../Table/Table";
// import SideBoard from "../../../SideBoard/SideBoard";
import { useParams,useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Question from "./Questions/Question"
import { QuestionsReport } from "../../../../../Api/OnlineTest";
import axios from 'axios';
import { GlobalContext } from "../../../../../context/GlobalState";
import TableComponent from "../Table2/OnlineTestTable";
import { QuestionsReportHeader } from "../Table2/TableHeader";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { FilterComponentt } from "../Table2/Sidebar/DropDown";

const questions = [
  {
    question: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. At autem illum molestias provident quam in corrupti? Nesciunt illum qui vitae, laudantium corporis cupiditate eum voluptatibus fugit quia voluptas suscipit voluptate?",
    marks: "2",
    difficulty: "Easy",
    options:[
      {option:"abcd"},
      {option:"efgh"},
      {option:"ijkl"},
      {option:"mnop"},
    ],
    answers:[
      {option:"a",value:"5"},
      {option:"b",value:"10"},
      {option:"c",value:"50"},
      {option:"d",value:"10"}
    ],
    type:"mcq"
  },
  {
    question: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. At autem illum molestias provident quam in corrupti? Nesciunt illum qui vitae, laudantium corporis cupiditate eum voluptatibus fugit quia voluptas suscipit voluptate?",
    marks: "2",
    difficulty: "Easy",
    options:[
      {option:"abcd"},
      {option:"efgh"},
      {option:"ijkl"},
      {option:"mnop"},
    ],
    answers:[
      {option:"a",value:"5"},
      {option:"b",value:"10"},
      {option:"c",value:"50"},
      {option:"d",value:"10"}
    ],
    type:"oneWord"
  },
]
const getCurrentDate = () => {
  const date = Date().slice(0, 15).split(" ");
  return date[0] + "," + date[1] + " " + date[2] + "," + date[3];
};

const QuestionReport = () => {
  const {testId,subId}=useParams();
  const [analytics,setAnalyticsData]=useState([]);
  const {grade}= useContext(GlobalContext).userDetails;
  const [tabContent,setTabContent]=useState([]);
  const [tabData,setTabData]=useState([]);
  const [chapters,setChapters]=useState([]);
  const search = useLocation().search;
  const [formData,setFormData]=useState({chapter:"",topic:""});
  const [topic,setTopics]=useState([]);
  const Subject= new URLSearchParams(search).get('subject');
  const subjectId=new URLSearchParams(search).get('subId')
  const q_data = [
    {
      name: "Correct",
      value: 350,
    },
    {
      name: "Incorrect",
      value: 135,
    },
    {
      name: "Not Attempted",
      value: 15,
    },
  ];
  const ls_data = [
    { name: "c1", value: "79" },
    { name: "c2", value: "50" },
    { name: "c3", value: "10" },
    { name: "c4", value: "58" },
    { name: "c5", value: "30" },
    { name: "c6", value: "49" },
    { name: "c7", value: "50" },
  ];
  const COLORS = ["#1DCD6D", "#9DABDD", "#FF8989"];

  const RADIAN = Math.PI / 180;
  const getQuestionDetails=async()=>{
    try{
      const res=await axios.get(QuestionsReport.questionDetails(testId),{
        headers:{
          token:window.localStorage.getItem("authToken")
        }
      })
      let data=res.data.data.analytics;
      setAnalyticsData(data);
      

    }

     catch(err)
     {
       console.log(err);
     } 
  }


  const getSubmissionReport=()=>{
    axios.get(QuestionsReport.questionReport(subId),{
      headers:{
        token:window.localStorage.getItem("authToken")
      }
    }).then(res=>{
      let data=res.data.data.analytics;
      console.log(data);
      let dt=data.map((item,idx)=>{
        return{
          no:idx+1,
          grade:grade,
          subject:item?.subjectId?.name,
          qno:`Question${idx+1}`,
          questionText:item?.question,
          chapter:item?.chapterId?.name,
          topic:item?.topicId?.name,
          difficultyLevel:item?.difficulty,
          cognitive:item?.cognitive,
          correct:item?.status,
          // marks:item?.marks,
          // marksObtained:item?.marksObtained,
          timeSpent:`${parseFloat((item?.timespend)/(1000*60)).toFixed(2)} min`,
          date:new Date(item?.testId?.from).toLocaleDateString()
        }
      })
      setTabContent(dt);
      setTabData(dt);

    }).catch(err=>console.log(err))
  }
  const getChapters=async()=>{

    const response = await axios.get(`${process.env.REACT_APP_ORIGIN2}/api/v1/subjects/${subjectId}`,{
      headers:{
          token:`${window.localStorage.getItem('authToken')}`
      }
  })
  let dt1=response.data.data.subject.chapters.filter(item=>item.lang === window.localStorage.getItem("language")&&!item.disabled)
  // console.log(window.localStorage.getItem("language"));
  setChapters(dt1.map(item=>{return{name:item?.name,_id:item?._id}}));

  }
  useEffect( ()=>{
     getQuestionDetails();
     getSubmissionReport();
     getChapters();
  },[])
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const fetchTopic=async()=>{
    const response = await axios.get(`${process.env.REACT_APP_ORIGIN2}/api/v1/chapters/${formData.chapter?._id}`,{
      headers:{
          token:`${window.localStorage.getItem('authToken')}`
      }
  })
  let dt1=response.data.data.chapter.topics.filter(item=>item.lang === window.localStorage.getItem("language")&&!item.disabled)
  // console.log(window.localStorage.getItem("language"));
  
  // setChapters(dt1);
  setTopics(dt1.map(item=>{return{name:item?.name,_id:item?._id}}));
  }
  useEffect(()=>{
    if(formData.chapter)
    {
      fetchTopic()
    }
    
  },[formData.chapter])

  useEffect(()=>{
    let dt=tabData;
    if(formData.chapter)
    {
     dt= dt.filter(item=>item.chapter===formData.chapter?.name);
    }
    if(formData.topic)
    {
      dt= dt.filter(item=>item.topic===formData.topic?.name);
    }
    setTabContent(dt.map((item,idx)=>{return{...item,no:idx+1}}));
  },[formData])

  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });
  console.log(analytics);
  const isSmall = useMediaQuery({ query: "(max-width: 472px)" });
  return (
    <>
    <Container fluid="xl" className="analytics_main_section">
      {/* <SideBoard /> */}
      <section className="learningResources my-md-5 my-sm-1 p-lg-0 p-2 p-sm-2">
        <div  style={{}} className="container">
          {!isSmall ? (
            <div
              className="box mb-5 row"
              style={{ cursor: "pointer", alignItems: "center",background:"linear-gradient(91.91deg, #C08497 1.44%, #B46A81 99.96%)" }}
            >
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "fit-content",
                }}
              >
                <h2
                  className="fw-bold white_color"
                  style={{
                    fontWeight: "bolder",
                    color: "white",
                    width: "100% !important",
                  }}
                >
                  Analytics  Online Test
                </h2>
                
              </span>
              <h5
                className="col-sm-3"
                style={{
                  marginLeft: "auto",
                  fontSize: "90%",
                  fontWeight: "bolder",
                  marginBottom: "auto",
                  textAlign: "right",
                }}
              >
                <CalendarTodayIcon style={{ width: "17px" }} />{" "}
                {getCurrentDate()}
              </h5>
              <p className=" white_color" style={{ color: "white" }}>
          {Subject}
                 {/* {headerData.subject} {headerData?.chapter&&`-> ${headerData.chapter}`} {headerData?.topic&&` -> ${headerData.topic}`} */}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
      <div style={{display:"flex",flexWrap:"wrap"}}>
        <FilterComponentt options={chapters} formData={formData} setFormData={setFormData} name="chapter" placeholder="Chapter"/>
        <FilterComponentt options={topic} formData={formData} setFormData={setFormData} name="topic" placeholder="Topic"/>

      </div>
      <div
        style={{
          maxWidth: "90vw",
          margin: "20px auto",
          boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.37)",
          borderRadius: "19px",
          padding: "20px 10px",
          overFlowX: "auto",
        }}
      >
        <div style={{display:"flex",justifyContent:"space-between"}}>
           <h2>Questions Report</h2>
           </div>
        <TableComponent tabHeader={QuestionsReportHeader} tabContent={tabContent} />
      </div>
      <Row className="questionanalytics_section_layer my-5">
        {analytics.map((item,idx)=>{
          return <Question  key={idx} id={idx+1} data={item} type={item?.questionId?.questionType} ></Question>
        })}
            
      </Row>
      {/* <Table /> */}
    </Container>
    </>
  );
};

export default QuestionReport;
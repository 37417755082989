import React from 'react';
import './styles/Dashboard.css';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {ReactComponent as DashboardIcon} from '../../images/dashboard/Dashboard.svg'
import {ReactComponent as AnalyticsIcon} from '../../images/dashboard/Analytics.svg'
import {ReactComponent as TaskIcon} from '../../images/dashboard/Task.svg'
import AssessmentIcon from '../../images/dashboard/assessment.png'
import { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import AssessmentTypes from '../MyAssessment/AssessmentTypes/AssessmentTypes'
import Updates from './Updates'
import Ranking from '../../images/dashboard/Ranking.png'
import Badge from '../../images/dashboard/Badge.svg'
import TaskDashboard from "./TaskDashboard"
import{ useMediaQuery} from 'react-responsive'
import Drawer from "../Navbar/Drawer"
import AnalyticsTypes from "../Analytics/AnalyticsTypes/AnalyticsTypes"

const useStyles = makeStyles((theme)=>({
  root: {
    backgroundColor: "#edfcfa",
    width:"100%"
  },
  dashboardTabStyle : {
    color: "#7F3DFF !important",
    minWidth:"100px",
    fontWeight: 'normal !important',
  },
  bottomSectionStyle : {
    color:'white',
    fontSize:'0.8em'
  },
  "@media screen and (max-width:472px)":{
    dashboardTabStyle:{
      minWidth:"60px",
      "& .MuiTab-wrapper":{
        fontSize:"0.8em"
      },
      "& .MuiTabs-flexContainer":{
        justifyContent:"space-evenly"
      }
    },

  }

}));



const tabList = ["Dashboard","Analytics","Assigned Tasks","Assessment"]

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const Dashboard = () => {
  const isSmall = useMediaQuery({query:"(max-width: 472px)"});
  const { grade, section, name} = useContext(GlobalContext).userDetails;
  const { userDetails,setUserDetails} = useContext(GlobalContext);
  document.title = '2xcell.in';
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const currentDate = new Date();

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setUserDetails({...userDetails,selectedTab:tabList[newValue]})
  };

  React.useEffect(()=>{
    setUserDetails({...userDetails,selectedTab:"My Assessment"})
    setUserDetails({...userDetails,selectedPath:{
      subject:"",
      chapter:"",
      topic:""
    }})
  },[])

  const customStyles =()=>({
    fontSize:"0.5em"
  })

  const DashboardTabs = ()=>(
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='inherit'
        variant='scrollable'
        scrollButtons={'on'}
        // centered
      >
        <Tab
          label='Home'
          icon={<DashboardIcon/>}
          {...a11yProps(0)}
          className={classes.dashboardTabStyle}
          styles={{fontSize:"0.5em !important"}}
          />
        <Tab
          label='Analytics'
          icon={<AnalyticsIcon/>}
          {...a11yProps(1)}
          className={classes.dashboardTabStyle}
        />
        <Tab
          label='Assigned Task'
          icon={<TaskIcon/>}
          {...a11yProps(2)}
          className={classes.dashboardTabStyle}
          />
        <Tab
          label='Assessment'
          icon={<img src={AssessmentIcon}/>}
          {...a11yProps(3)}
          className={classes.dashboardTabStyle}
          style={{padding:"0px"}}
          />
      </Tabs>
    </Paper>
  )

  
  
  return (
    <>
      {isSmall && 
        <Drawer>
          <DashboardTabs/>
        </Drawer>}
      <section className='dashboard my-4'>
        <div className='container dashboard_container'>
          <div className='box'>
            {!isSmall && <div className='upperBox'>
              <div className='row'>
                <div className='col-sm text-center text-sm-start'>
                  <h1 style={{ fontWeight: 'bolder',color: 'white' }}>{grade} - {section}</h1>
                  <h3 style={{ fontWeight: 'bolder'}}>
                    {tabList[value]}
                  </h3>
                </div>
                <div className='col-sm' style={{ textAlign: 'right',height: '100%',display:'flex',flexDirection: 'column'}}>
                  <h5 className='ms-auto' style={{ marginLeft: 'auto',fontWeight: 'bolder',fontSize: '90%',marginTop: '0px' }}>
                    <CalendarTodayIcon style={{width:"17px"}}/>{' '}
                    {` ${currentDate.getDate()} ${months[currentDate.getMonth()]} ${currentDate.getFullYear()}`}
                  </h5>
                  {/* <div className={classes.bottomSectionStyle} > */}
                    {/* <span className="col-12" className={classes.bottomSectionStyle} style={{marginTop:'15%'}} >
                      <img src={Ranking}/> &nbsp;
                      Ranks 
                    </span>
                    <span className="col-12" className={classes.bottomSectionStyle}>
                      <img src={Badge}/> &nbsp;
                      Badge
                    </span> */}
                  {/* </div> */}
                </div>
              </div>
              <div className="row">
              </div>
            </div>}
            {!isSmall && <DashboardTabs/>}

            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction} id="dashboard_assessment_types">
                <Updates/>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <AnalyticsTypes displayTopSection={false} ></AnalyticsTypes>
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction} id="dashboard_assessment_types">
                <TaskDashboard/>
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction} id="dashboard_assessment_types" >
                <AssessmentTypes displayTopSection={false}/>
              </TabPanel>
            </SwipeableViews>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;

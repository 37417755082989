import React,{ useState,Fragment} from 'react'
import Select from 'react-select';
import { makeStyles } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {useParams} from 'react-router-dom'

const useStyles = makeStyles({
  select:{
    width:'fit-content',
    padding: '0px 10px',
    '& > .select__control':{
      border: '1px solid #B46B82',
      boxShadow: '0px 0px 5px 0px #00000040'
    }
  },
  datePicker:{
    padding:"0px 1px",
    width:"10%",
    marginTop:"3px",
    marginBottom:"0px",
    "& > .MuiInputBase-root > .MuiOutlinedInput-notchedOutline":{
      border: '1px solid #B46B82',
      boxShadow: '0px 0px 5px 0px #00000040'
    }
  },
  '@media screen and (max-width:450px)':{
    select:{
      marginBottom:"5px"
    },
    datePicker:{
      width:"94%",
      marginBottom:"10px"
    }
  }
})

const fixDate = (date)=>{

  if(!date) return null

  const newDate = new Date(`${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`).toISOString()
  return newDate
}

function TestFilter({subjects,chapters,topics,filterData,setFilterData,testType}) {

    const classes = useStyles();
    const params = useParams();

    //state to enable select boxes 
    const [chapterDisabled,setChapterDisabled] = useState(true)
    const [topicDisabled,setTopicDisabled] = useState(true)

    //Filter the subjects to make an label-value pair of options
    subjects = subjects.map(subject=>({
      value:subject.subjectId,
      label:subject.subject
    }))

    //Filter the chapters to make an label-value pair of options
    chapters = chapters && chapters.length && chapters.map(chapter=>({
      value:chapter._id,
      label:chapter.name
    }))

    //Filter the topics to make an label-value pair of options
    topics = topics && topics.length && topics.map(topic=>({
      value:topic._id,
      label:topic.name
    }))

    //Filter the test type to make an label-value pair of options
    testType = testType && testType.length && testType.map(type=>({
      value:type,
      label:type
    }))


    const handleSubjectChange = (subject) => {
      setFilterData({...filterData,subjectId:subject && subject.value})
      setChapterDisabled(false)
    }
    const handleChapterChange = (chapter) => {
      setFilterData({...filterData,...{chapter:chapter.label,chapterId:chapter && chapter.value}})
      setTopicDisabled(false)
    }
    const handleTopicChange = (topic) => {
      setFilterData({...filterData,...{topic:topic.label,topicId:topic.value}})
    }

    const handleDateChange = (date) => {
      setFilterData({...filterData,'from[gte]':fixDate(date)})
    }

    const handleTypeChange = (type) => {
      setFilterData({...filterData,testType:type && type.value})
    }

    return (
      <section className="test_list_filter my-3">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Fragment>
          {!params.topicId?
            <><Select
              className = {classes.select}
              classNamePrefix="select"
              isDisabled={false}
              isClearable
              isSearchable
              name="color"
              options={subjects}
              placeholder="Subjects"
              onChange={handleSubjectChange}
            /></>:""}
            {/* <Select
            //   className = {classes.select}
            //   classNamePrefix="select"
            //   isDisabled={chapterDisabled}
            //   isClearable
            //   isSearchable
            //   name="color"
            //   options={chapters}
            //   placeholder="Chapters"
            //   onChange={handleChapterChange}
            // />
            // <Select
            //   className = {classes.select}
            //   classNamePrefix="select"
            //   isDisabled={topicDisabled}
            //   isClearable
            //   isSearchable
            //   name="color"
            //   options={topics}
            //   placeholder="Topics"
            //   onChange={handleTopicChange}
            // />
            */}
            <Select
              className = {classes.select}
              classNamePrefix="select"
              isDisabled={false}
              isClearable
              isSearchable
              name="color"
              options={testType}
              placeholder="Type"
              onChange={handleTypeChange}
            />
            <KeyboardDatePicker
              inputVariant="outlined"
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Date"
              value={filterData['from[gte]']}
              KeyboardButtonProps={{
                "aria-label": "disable",
              }}
              autoOk={true}
              onChange={date => handleDateChange(date)}
              onInput={(e) => e.target.value=""}
              className={classes.datePicker}
              id="test_filter_date_picker"
            />
          </Fragment>
          </MuiPickersUtilsProvider>
        </section>
    )
}

export default TestFilter

export const htmlDecode = (content) => {
  const e = document.createElement("body");
  e.innerHTML = content;

  const nodes = [];
  for (let childIndex = 0; childIndex < e.childNodes.length; childIndex++) {
    const node = e.childNodes[childIndex];
    if (node.nodeName === "#text") nodes.push(node.nodeValue);
    else nodes.push(node.outerHTML);
  }
  const nodeText = nodes.join("");
  return nodeText;
};

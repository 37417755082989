import React from 'react';
import '../../../LearningResources/styles/LearningResources.css';
import { NavLink,useParams } from 'react-router-dom';
import { image } from '../../../helpers';

// const LearningResourcesStyle = {
//   height: '100%',
//   borderRadius: '17px',
//   boxShadow: '0px 0px 4px rgb(0,0,0,0.4)',
// };

const fixName = (name) => {
    const charArrays = ["?", "+", "&", "/"];
    charArrays.forEach((c) => {
      name = name.replaceAll(c, "");
    });
    return name;
}

// const CardStyle = (url) => ({
//   backgroundImage: `url(${
//     url ? url : process.env.PUBLIC_URL + '/backgrounds/revisionTask/nature.png'
//   })`,
//   backgroundSize: 'cover',
//   height: '75%',
//   borderRadius: '17px 17px 0px 0px',
//   borderBottom: '1px solid rgb(0,0,0,0.2)',
//   display: 'flex',
//   flexDirection: 'column',
// });

//convert html, used for url link of each card in different language
function decodeHTMLEntities(text) {
  var textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}
const SingleTopic = (props) => {
    
    const params = useParams()

    // props has fields of {name,id,totalModules}
    return (
        <div className='col-sm-2 col-6' style={{display:props.disabled?'none':'block'}}>
        <NavLink
            to={{
                pathname: `/myassessment/practice/subjects/${params.subjectId}/${fixName(params.subjectName)}/chapters/${params.chapterId}/${fixName(params.chapterName)}/topics/${props?.id}/${fixName(props?.name)}`,
                state: { topicId: props.id },
            }}
            style={{ textDecoration: 'none' }}>
            {/* <div className="text-center" style={LearningResourcesStyle}> */}
            <div className='card card-radius card-height'>
            <div className='card-img'>
                <img
                src={image(props.thumbnail)}
                alt={props?.name || 'topic'}
                className='card-thumbnail'
                />
            </div>
            <div className='text-dark text-center'>
                <p
                dangerouslySetInnerHTML={{ __html: props.name }}
                className='mar-p'
                ></p>
                {/* <p>Total Modules : {props.totalModules}</p> */}
            </div>
            </div>
        </NavLink>
        </div>
  );
};

export default SingleTopic;

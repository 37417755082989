import React from "react";
import TableTask from "./TableTask";

const TaskList = () => {
  document.title = '2xcell.in';

  return (
    <>
      <section className="taskList">
        <div className="container my-5">
          <TableTask />
        </div>
      </section>
    </>
  );
};

export default TaskList;

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    container: {
        width: '40%',
        height: '70%',
        boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.25)",
        borderRadius: "17px",
        margin: "1rem 0px"
    },
    headingContainer: {
        textAlign: "center",
        "& > *": {
            marginBlockStart: "0em",
            marginBlockEnd: "0.2em",
        },
        "& > h3": {
            fontSize: "0.9em",
            marginBlockStart: "10px"
        },
        "& > p": {
            fontSize: "0.7em",
        }
    },
    "@media screen and (max-width: 472px)": {
        container: {
            width: '97%',
            height: '60%',
            borderRadius: '10px',
            margin: "10px auto"
        }
    }

})

export default useStyles;
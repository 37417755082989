/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from "react";
import "./LoginBox.css";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import axios from "axios";
import { useForm } from "react-hook-form";
import { GlobalContext } from "../../../context/GlobalState";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";

function LoginBox() {
  document.title = '2xcell.in';

  const { register, handleSubmit } = useForm();
  const { userDetails, setUserDetails } = useContext(GlobalContext);

  const alert = useAlert();

  useEffect(() => {
    if (userDetails.isAuthenticated) {
      window.location.href = "/dashboard";
    }
  }, []);

  const TogglePassword = () => {
    if (document.getElementById("visible").classList.contains("hidden")) {
      document.getElementById("password-field").type = "password";
      document.getElementById("visible").classList.remove("hidden");
      document.getElementById("invisible").classList.add("hidden");
    } else {
      document.getElementById("password-field").type = "text";
      document.getElementById("invisible").classList.remove("hidden");
      document.getElementById("visible").classList.add("hidden");
    }
  };

  const submitForm = async (data) => {
    const { email, password } = data;

    if (!email || !password) {
      alert.error("Fields cannot be empty");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ORIGIN}/api/v1/auth/login?user=student`,
        {
          email,
          password,
        }
      );

      if (response.data.status === "success") {
        localStorage.setItem("authToken", response.data.data.token);
        setUserDetails({
          isAuthenticated: true,
          name: response.data.data.payload.name,
          userId: response.data.data.payload.userId,
          schoolId: response.data.data.payload.school,
          role: response.data.data.payload.role,
        });
        window.localStorage.setItem("name",response.data.data.payload.name)
        window.localStorage.setItem("userId",response.data.data.payload.userId)
        window.localStorage.setItem("imageUrl",response.data.data.payload.profile.fileUrl)
        window.localStorage.setItem("grade",response.data.data.payload.grade)
        window.localStorage.setItem("section",response.data.data.payload.section)
        window.location.href = "/dashboard";
      }
    } catch (error) {
      console.log(error);
      alert.show(error.message);
    }
  };

  return (
    <>
      <div className="LoginBox">
        <div className="top-section">
          <h3>Sign In</h3>
          <p>Sign in with your details</p>
        </div>

        <form onSubmit={handleSubmit(submitForm)} className="input-container">
          <input
            type="text"
            className="input-username"
            placeholder="Username or email address"
            {...register("email")}
            required
          />
          <span>
            <input
              type="password"
              className="input-password"
              id="password-field"
              placeholder="Password"
              {...register("password")}
              required
            />
            <button
              className="toggle-password"
              type="button"
              onClick={() => {
                TogglePassword();
              }}
            >
              <VisibilityOutlinedIcon id="visible" />
              <VisibilityOffOutlinedIcon className="hidden" id="invisible" />
            </button>
          </span>
          <button className="signin-btn" type="submit">
            Sign in to my account
          </button>
        </form>

        <div className="bottom-section">
          <p>
            Don't have an account? <a href={`${process.env.REACT_APP_ORIGIN}`}>Sign Up</a>
          </p>
          <p>
            Forgot password? <Link to="#">Password Reset</Link>
          </p>
        </div>

        <div className="linkBox">
          <a href="https://master.d2t0hf155qs4n4.amplifyapp.com/">Login as a teacher</a>
          <br />
          <a href="https://main.dvek77zyogw7h.amplifyapp.com/">Login as an admin</a>
        </div>

      </div>
    </>
  );
}

export default LoginBox;

import React,{ useState, useEffect} from 'react'
import {useAlert} from "react-alert"
import TaskViewerPlayer from './TaskViewerPlayer';
import './styles/TaskViewer.css'

//Find the main module to be displayed
const findMainModuleFile = (resources, mainFileTypes, data) => {
    for (let resource of resources) {
      const nameParts = resource.fileName ? resource.fileName.split('.') : '';
      const fileType = nameParts[nameParts.length - 1];
      if (mainFileTypes.includes(fileType)) {
        data['notFound'] = false;
        data['resource'] = resource;
        data['fileType'] = fileType;
        break;
      }
    }
    return data;
  };
const getModuleType = (resources) => {
    var result = { notFound: true };
    const modulesTypes = [
      ['html'],
      ['mp4', 'mkv'],
      ['ppt', 'docx', 'pdf','pptx','pps'],
      ['mp3'],
      // ['png', 'jpg', 'jpeg']
    ];
    var move = 0,
      lastStep = modulesTypes.length - 1;
    while (result.notFound) {
      result = findMainModuleFile(resources, modulesTypes[move], result);
      if (move === lastStep) break;
      move += 1;
    }
    return result;
  };


function TaskViewer(props) {

    const alert = useAlert()

    console.log("Props that are passing into this component==>",props)
    const taskId = props.match.params.taskId
    const moduleId = props.match.params.moduleId

    //State to save the module and metadata
    const [module,setModule] = useState(null)
    const [metaData,setMetaData] = useState(null) //to store metadat of the module

    //fetch the modules
    const fetchModule = async()=>{
        try{
            await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/modules/${moduleId}`,{
                headers:{
                    token: `${window.localStorage.getItem('authToken')}`,
                }
            }).then(response => response.json())
                .then(response=>{

                    console.log("Fetching module response ==> ",response)
                    if(response.status === "success"){
                        setModule(response.data.module)
                        setMetaData(getModuleType(response.data.module.resources))

                        console.log("Module ====> ",module)
                        console.log("Meta data ====> ",metaData)

                    }else{
                        alert.show(response.message)
                    }
                })
        }catch(e){
            console.log(e)
            alert.error(e.message)
        }
    }

    //run the fetch function once the component is loaded
    useEffect(() => {
        fetchModule()
    },[])

    return(
        <div className = "media_player">
            <TaskViewerPlayer module={module} metaData={metaData} ></TaskViewerPlayer>
        </div>
    )

}

export default TaskViewer

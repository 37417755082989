import React,{ useState,useContext,useEffect} from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink,Link } from "react-router-dom";
import './Sidebar.css'
import {GlobalContext} from "../../context/GlobalState"
import {ReactComponent as User} from '../../images/sidebar/user.svg'
import {ReactComponent as DashboardIcon} from "../../images/sidebar/dashboard.svg"
import {ReactComponent as TaskListIcon} from "../../images/sidebar/task_list.svg"
import ResourcesIcon from "../../images/sidebar/resources.png"
import MyAssessmentIcon from "../../images/sidebar/my_assessment.png"
import {ReactComponent as AnalyticsIcon} from "../../images/sidebar/analytics.svg"
import {ReactComponent as SettingsIcon} from "../../images/sidebar/settings.svg"
import {ReactComponent as SettingsIcon2} from "../../images/sidebar/settingsIcon2.svg"
import {useMediaQuery} from 'react-responsive'

const useStyles = makeStyles({
  list: {
    width: 280,
    overflowX:"hidden",
    "& .MuiListItemIcon-root":{
      minWidth:"40px !important"
    },
  },
  fullList: {
    width: "auto",
  },
  sidebarLink: {
    background: "linear-gradient(90deg, #CEF6F2 0%, rgba(206, 246, 242, 0) 100%)",
    "&:hover": {
      background: "linear-gradient(90deg, #CEF6F2 0%, rgba(206, 246, 242, 0) 100%)",
    },
  },
  logoutBtn:{
    width: "60px",
    height: "fit-content",
    backgroundColor:"rgba(206, 246, 242, 1)",
    fontSize:"0.75em",
    cursor:"pointer",
    marginLeft:"110px",
    marginRight:"5px",
    boxShadow:"0px",
    borderRadius:"5px",
    border:"none",
    padding:"4px 8px",
    transition:"0.3s",
    "&:hover":{
      boxShadow:"0px 0px 1px 3px #5ce0d2",
      transform:"scale(1.05)"
    }
  },
  settingsBtn:{
    marginLeft:"15px",
    "& svg":{
      transition:"0.2s",
      "&:hover":{
        transform:"scale(1.05)"
      }
    }
  }, 
});

const details = {
  marginTop: '1rem',
  width:"fit-content",
  "@media screen and (max-width:472x)":{
    display: "flex !important"
  }
}

const Sidebar = ({ slider, toggleDrawer }) => {
  const {userDetails,setUserDetails} = useContext(GlobalContext);
  const isSmall = useMediaQuery({query:"(max-width: 472px)"});

  const classes = useStyles();

  const setLanguage = (lang)=>{
    window.localStorage.setItem('language',lang)
    if(lang==='english'){
      document.getElementById('english_btn').classList.add('selected_language')
      document.getElementById('hindi_btn').classList.remove('selected_language')
      setLang('english')
      window.location.reload()
    }else{
      document.getElementById('hindi_btn').classList.add('selected_language')
      document.getElementById('english_btn').classList.remove('selected_language')
      setLang('hindi')
      window.location.reload()

    }
  }

  const [lang,setLang] =useState(window.localStorage.getItem('language'))
  const profileImageUrl = window.localStorage.getItem('profileImageUrl')

  useEffect(() => {
    if(!window.localStorage.getItem("language")){
      window.localStorage.setItem("language","english")
      window.location.reload( )
    }
  }, [])

  const handleLogout = async()=>{
    const token = window.localStorage.getItem('authToken')
    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/auth/logout`,{
      headers:{
        'token':token
      }
    }).then(response =>response.json())
      .then(response =>{
        localStorage.removeItem("authToken");
        window.location.href = `${process.env.REACT_APP_REDIRECT}?logout=true`;
      })
  }

  const list = () => (
    <div className={clsx(classes.list)}>
      <div className="row" style={details}>
        <div className="col-sm-2 my-1 mx-2" style={{marginLeft:"5px"}} >
          {!["undefined",undefined,null,"null"].includes(profileImageUrl)?
            <img
            src={profileImageUrl}
            alt={<User/>}
            width="40px"
            height="40px"
            id="navbarDropdown"
            className="rounded-circle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />:<User/>}
          {isSmall && 
          <><button variant="contained" className={classes.logoutBtn} onClick={handleLogout}>
            Logout
          </button>
          <NavLink to="/setting" onClick={toggleDrawer("Settings")} className={classes.settingsBtn} >
            <SettingsIcon2></SettingsIcon2>
          </NavLink>
          </>}
        </div>
        <div className="col-sm-8" style={{marginTop:"10px",marginLeft:"15px"}}>
            <h4 style={{fontSize:"1em",marginBlockEnd:"0px"}} >{userDetails.name}</h4>
            <p style={{fontSize:"0.7em"}} >{userDetails.school.name}</p>
        </div>
      </div>
      <List>
        <ListItem
          button
          component={NavLink}
          to="/dashboard"
          activeClassName={classes.sidebarLink}
          exact={true}
          onClick={toggleDrawer("Dashboard")}>
          <ListItemIcon>
            <DashboardIcon></DashboardIcon>
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to="/tasklist"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer("Task List")}>
          <ListItemIcon>
            <TaskListIcon></TaskListIcon>
          </ListItemIcon>
          <ListItemText primary="Task List" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to="/learningresources"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer("2Xcell Resources")}
        >
          <ListItemIcon>
            <img src={ResourcesIcon} alt={ResourcesIcon} />
          </ListItemIcon>
          <ListItemText primary="2Xcell Learning Resources" />
        </ListItem>

        {/* {For my assessment} */}
        {/* <ListItem
            button
            component={NavLink}
            to="/myassessment"
            activeClassName={classes.sidebarLink}
            onClick={toggleDrawer("My Assessment")}
          >
            <ListItemIcon>
              <img src={MyAssessmentIcon} alt={MyAssessmentIcon} />
            </ListItemIcon>
            <ListItemText primary="My Assessment" />
        </ListItem> */}
        
        {/* For analytics */}
        <ListItem
          button
          component={NavLink}
          to="/analytics"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer("Analytics")}
        >
          <ListItemIcon>
            <AnalyticsIcon></AnalyticsIcon>
          </ListItemIcon>
          <ListItemText primary="Analytics" />
        </ListItem>
        {/*<ListItem
          button
          component={NavLink}
          to="/myuploads"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer("Analytics")}
        >
          <ListItemIcon>
            <AnalyticsIcon></AnalyticsIcon>
            <TaskListIcon/>
          </ListItemIcon>
          <ListItemText primary="My Uploads" />
        </ListItem>*/}
        
        <ListItem
          button
          component={NavLink}
          to="/setting"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer("Settings")}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </List>
      <div className="language_button_container">
        <p>Language</p>
        <span>
          <button type="button" className={lang==="english"?"language_button selected_language":"language_button"} id="english_btn" onClick={()=>{setLanguage('english')}}>ENG</button>
          {/* <button type="button" className={lang==="hindi"?"language_button selected_language":"language_button"}  id="hindi_btn" onClick={()=>{setLanguage('hindi')}}>HIN</button> */}
        </span>
      </div>
    </div>
  );

  return (
    <>
      <SwipeableDrawer
        anchor="left"
        open={slider}
        onClose={toggleDrawer()}
        onOpen={toggleDrawer()}
      >
        {list()}
      </SwipeableDrawer>
    </>
  );
};

export default Sidebar;

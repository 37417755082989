import React from 'react'
import { Col, Row } from "react-bootstrap";
import Stat from '../common/Stat'
import StatBar from '../common/StatBar'
import "../QuestionReport.css"
import IsSmallScreen from '../common/IsSmallScreen';
import {GlobalContext} from "../../../../../context/GlobalState"
import { htmlDecode } from '../../../../../utils/parse';
const optionColor = require('../common/OptionColor')
const StatBarWidth = require('../common/StatBarWidth')

function Mcq({data,index}) {

    const isSmallScreen = IsSmallScreen
    const {userDetails} = React.useContext(GlobalContext)

    return (
        <Row className="analytics_question_layer">
          <Col className="analytics_question_content">
            <Row className="analytics_question_heading">
              <p>Question {index+1}</p>
              <p className="analytics_question_heading_card">{data._id.marks} Marks</p>
              <p className="analytics_question_heading_card">{data.questionId.difficulty}</p>
            </Row>
            <Row className="analytics_question_statement" dangerouslySetInnerHTML={{__html:htmlDecode(data.questionId.question)}}></Row>
            <Row>
              {data.questionId.options.map((option,index)=>
                <Row className="analytics_question_option"
                  style={{background: optionColor(data,option.optionId)}}
                >
                  <Col className="option_label">{index+1}</Col>
                  <Col className="option_content">{option.value}</Col>
                </Row>
              )}
            </Row>
          </Col>
          <Col className="analytics_question_stats">
            {data.questionId.options.map((option,index)=>
              <Stat>{index+1}
                <StatBar
                  style={{ 
                    width: StatBarWidth(data, option), 
                    height: isSmallScreen ? 15 : 30 
                  }}
                  marked={data.statistics.map(user => user.userId === userDetails.userId &&
                    (data.questionId.questionType=="mcq"?
                    user.answer.mcq === option.optionId:user.answer.mmcq.includes(option.optionId)
                )).length}
                />{" "}
                {data.statistics.filter(user => 
                      data.questionId.questionType=="mcq"?
                      user.answer.mcq === option.optionId:user.answer.mmcq.includes(option.optionId)
                  ).length} Student
              </Stat>
            )}
            {/* <Stat>
              B
              <StatBar
                style={{ width: 120, height: isSmallScreen ? 15 : 30 }}
              />{" "}
              15 Students
            </Stat>
            <Stat>
              C
              <StatBar
                style={{
                  width: 180,
                  height: isSmallScreen ? 15 : 30,
                  backgroundColor: "#7EB6A2",
                }}
              />{" "}
              30 Students
            </Stat>
            <Stat>
              D
              <StatBar
                style={{ width: 100, height: isSmallScreen ? 15 : 30 }}
              />{" "}
              10 Students
            </Stat>  */}
          </Col>
        </Row>
    )
}

export default Mcq

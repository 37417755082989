import React from 'react';


 const AnalyticsSvg =()=>{
    return(
        <svg width="43" height="28" viewBox="0 0 43 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.63862 1.89291L40.3189 1.82357C40.8453 1.82267 41.2742 2.34047 41.2742 2.97714L41.3134 27.8289C41.8239 27.5677 42.3862 27.3759 43 27.258L42.9617 2.97354C42.9608 1.33188 41.7731 -0.00269748 40.3181 4.09435e-06L2.63779 0.0684439C1.18189 0.0711455 -0.00249475 1.40932 3.94637e-06 3.05188L0.0383171 27.6542C0.643001 27.7046 1.20937 27.8181 1.7266 28L1.68662 3.04918C1.68662 2.41251 2.11223 1.89381 2.63862 1.89291Z" fill="white"/>
<path d="M17.7159 11.7365L11.9906 20.3519L9.63517 17.9655L4.26465 25.9387L5.86131 27.3255L9.22039 22.5041L12.2396 25.1481L17.9591 16.5084L24.998 22.7058L33.6493 12.1076L38.7549 16.34L38.0095 2.37109L25.5802 5.20955L29.9029 8.9035L24.1967 17.0992L17.7159 11.7365Z" fill="white"/>
</svg>

    )
}
export default AnalyticsSvg;
const video = document.querySelector('video');
export default class VideoProgressTracker {
    constructor(props) {
        this.video = props.video;
        this.videoDuration = 0;
        this.startTime = 0;
        this.watchTime = 0;
        this.status = "";
        this.playerStatus = "stopped";
        
       
    };

    startWatchTimeTracker() {
        this.startTime = Date.now();
        this.playerStatus = "running";
     
        

        console.log(`Tracker started ${this.startTime}`);
    }

    stopWatchTimeTracker() {
        if (this.playerStatus === "stopped") return;
        this.watchTime += (Date.now() - this.startTime);
        
        this.playerStatus = "stopped";
        

        console.log(`Tracker stopped ${this.watchTime}`);
    }

    trackMetaDataEvent() {
        this.video.addEventListener('loadedmetadata', () => {
            this.videoDuration = (this.video.duration * 1000);
        });
    }

    trackPlayEvent() {
        this.video.addEventListener('play', () => {
            this.status = "progress";
           
            this.startWatchTimeTracker();
            console.log("play video");
        });
    }

    trackProgressEvent() {
        this.video.addEventListener('progress', () => {
            this.stopWatchTimeTracker();
        })
    }

    trackPauseEvent() {
        this.video.addEventListener('pause', () => {
            this.stopWatchTimeTracker();
            if (this.videoDuration <= this.watchTime) {
                this.status = "completed";
               
            }
        });
    }

    getTrackerStatus() {
        this.status = this.duration >= this.videoDuration ? "completed" : "progress";
       
        return {
            status: this.status,
            duration: this.watchTime,
            startTime:this.startTime,
            videoDuration:this.videoDuration,
            playerStatus:this.playerStatus
        }
    }

    initTracking() {
        this.trackMetaDataEvent();
        this.trackPlayEvent();
        this.trackPauseEvent();
        this.trackProgressEvent();
    }
}
// const tracker = new VideoProgressTracker(video);
// tracker.initTracking();
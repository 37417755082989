import React, {useState, useEffect, useContext} from "react";
import "./styles/Test.css";
import Question from "./Question";
import { GlobalContext } from "../../context/GlobalState";
import axios from "axios";
import { useAlert } from "react-alert";
import Submitted from "./Submitted";

const Test = (props) => {
  const [test, getTestData] = useState('');
  const [submitted, isSubmitted] = useState(false);
  const [fetched,setFetched] = useState(false)
  const alert = useAlert();
  const { grade, section, authToken } = useContext(GlobalContext).userDetails;
  useEffect(() => {
    const getTask = async () => {
      try {
        let {topicId, testId} = props.match.params
        const testData = await axios(`${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/topics/${topicId}/tests/${testId}`, {
          headers: {
            token: authToken,
          },
        })
        if(testData.statusText === 'OK') {
          getTestData(testData.data.data.test);
          setFetched(true)
        }
      } catch(e) {
        alert.error('Task Not Found')
        console.log(e)
      }
    }
    getTask();
  }, [section, grade, authToken])
  return (
    <>
      <section className="testQuestion my-5">
        {fetched?
          <div className="container">
            <div className="box mb-3">
              <h1 className="fw-bold">{test.name}</h1>
              <p>{test.description}</p>
            </div>
            {
              submitted ? (
                <Submitted />
              ) : (
                <Question questions={test?.questions} taskId={props.match.params.taskId} isSubmitted={isSubmitted}/>
              )
            }
          </div>
        : <h2 className="text-center">Loading...</h2>  
      }
      </section>
    </>
  );
};

export default Test;

import React from 'react';
import { BarChart, Bar, Brush,Cell, XAxis, YAxis, CartesianGrid, Tooltip, Label,LabelList, ResponsiveContainer } from 'recharts';
import useStyles from '../../styles';
import {ReactComponent as Loader} from "../../../../../images/analytics/ChartLoader.svg"

import { useMediaQuery } from 'react-responsive';
const {randomColor} = require("../../utils/Color")

export default function SubjectProgressReport ({data,loading}) {

    const isSmall = useMediaQuery({query:"(max-width: 472px)"})
    const classes = useStyles()

    return (
        <div className={classes.container}> 
            <div className={classes.headingContainer}>
                <h3>Subject Progress Report</h3>
                {/* <p>Total Questions Practised:500</p> */}
            </div>
            {loading?<Loader/>:
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                width={300}
                height={250}
                data={data}
                margin={{
                    top: 25,
                    right: isSmall?3:5,
                    left: isSmall? -7 : 15,
                    bottom: isSmall?20:80,
                }}
                >
                <CartesianGrid strokeDasharray="3 6" />
                <XAxis dataKey="Subject" allowDataOverflow={true}>
                    <Label value="Subjects" offset={isSmall?-25:-30} position="insideBottom"/>
                </XAxis>
                <Brush dataKey="Subject" height={10} endIndex={data.length>0?Math.min(Math.abs(data.length-1),4):1} />
                <YAxis label={{ value: "Questions Practiced", angle: -90,offset: isSmall? 18:-2, position: 'insideLeft' }} />
                <Tooltip />
                    <Bar dataKey={"Attempted"} stackId="a">
                        <LabelList dataKey={"Attempted"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill={randomColor()} ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Pending"} fill="rgba(25, 25, 25, 0.14)" stackId="a">
                        <LabelList dataKey={"Total"} position="top"/>
                    </Bar>
                </BarChart>
            </ResponsiveContainer>}
        </div>
    );
 }


import React from "react";
import "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { NavLink } from "react-router-dom";
import {ReactComponent as Loader} from "../../../../LearningResources/Assement/Questions/Loader/Loader.svg";
const formatTime = require("../../utils/FormatTime")

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    
  },
  table: {
    minWidth: "auto",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 280,
  },
  tableRow: {
    textDecoration: "none",
    "& > td":{
        fontWeight:"bold"
    },
    "&:hover": {
      backgroundColor: "#e2ddddc2 !important",
    },
  },
  tableHeading: {
      ' & > th':{
          fontWeight: "bolder",
          fontSize:"1.02em"
      }
  }
}));

const convertArrayToString = (array) => {

  if(!array){
    return ""
  }
  array = array && array.map(item => item.name)
  return array.join(', ')
}

const TestTable = ({rows,loading,rowColor}) => {

  console.log(rows)
  const classes = useStyles();
  return (
    <div className={classes.tableContainer}>
      {!loading?<TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableHeading}>
              <TableCell align="center">No</TableCell>
              <TableCell align="center">Start Date</TableCell>
              <TableCell align="center">End Date</TableCell>
              <TableCell align="center">Subject</TableCell>
              <TableCell align="center">Chapter</TableCell>
              <TableCell align="center">Topic</TableCell>
              <TableCell align="center">Time Spent</TableCell>
              <TableCell align="center">Questions Done</TableCell>
              <TableCell align="center">Correct</TableCell>
              <TableCell align="center">Incorrect</TableCell>
              <TableCell align="center">Easy</TableCell>
              <TableCell align="center">Medium</TableCell>
              <TableCell align="center">Difficult</TableCell>
              <TableCell align="center">Knowledge</TableCell>
              <TableCell align="center">Understanding</TableCell>
              <TableCell align="center">Analysis</TableCell>
              <TableCell align="center">Apllication</TableCell>
              <TableCell align="center">1st Attempt</TableCell>
              <TableCell align="center">2nd Attempt</TableCell>
              <TableCell align="center">Rank</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { rows && rows.length ? rows.map((row, index) => (
              <TableRow
                key={`${row.subject}${index}`}
                className={classes.tableRow}
                style={{backgroundColor:index%2?rowColor:"white"}}
              >
                <TableCell scope="row" align="center">{index+1}</TableCell>
                <TableCell scope="row" align="center" style={{minWidth:"120px"}}>{row.startedAt.split("T")[0]}</TableCell>
                <TableCell scope="row" align="center" style={{minWidth:"120px"}}>{row.startedAt.split("T")[0]}</TableCell>
                <TableCell scope="row" align="center">{row.subjectId.name}</TableCell>
                <TableCell align="center" style={{wordBreak:'break-word',minWidth:'150px'}} dangerouslySetInnerHTML={{__html:row.chapterId.name}}></TableCell>
                <TableCell align="center" style={{wordBreak:'break-word',minWidth:'150px'}} dangerouslySetInnerHTML={{__html:row.topicId.name}}></TableCell>
                <TableCell align="center">{formatTime(row.timeSpend)}</TableCell>
                <TableCell align="center">{row.questionsAttempted}</TableCell>
                <TableCell align="center">{row.correct || 0}</TableCell>
                <TableCell align="center">{row.incorrect || 0}</TableCell>
                <TableCell align="center">{row.easy || 0}</TableCell>
                <TableCell align="center">{row.intermediate || 0}</TableCell>
                <TableCell align="center">{row.difficult || 0}</TableCell>
                <TableCell align="center">{row.knowledge || 0}</TableCell>
                <TableCell align="center">{row.understanding || 0}</TableCell>
                <TableCell align="center">{row.analysis || 0}</TableCell>
                <TableCell align="center">{row.application || 0}</TableCell>
                <TableCell align="center">{row.firstAttempt || 0}</TableCell>
                <TableCell align="center">{row.secondAttempt || 0}</TableCell>
                <TableCell align="center">{Math.round(row.avgScores) || 0}</TableCell>
              </TableRow>
            )):
            <TableRow>
                <TableCell scope="row" align="center">-</TableCell>
                <TableCell scope="row" align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
              </TableRow>
          }
          </TableBody>
        </Table>
      </TableContainer>
      : <Loader/>  
    }
    </div>
  );
};

export default TestTable;

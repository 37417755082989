import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import TitleImage from "../../images/Navbar/title.png";
import Sidebar from "../Sidebar/Sidebar";
import {ReactComponent as AvatarImage} from "../../images/Navbar/user.svg";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import {ReactComponent as NotificationIcon} from '../../images/Navbar/notification.svg';
import {ReactComponent as WhiteSidebar} from '../../images/Navbar/WhiteSidebar.svg';
import SidebarIcon from '../../images/Navbar/sidebar.png';
import {useMediaQuery} from "react-responsive"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX:"hidden",
    zIndex:"100000000",
    "& > header": {
      boxShadow: "0px 4px 6px 0px rgba(51, 122, 115, 0.16)"
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileTop:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "left",
    "& > *":{
      marginBlockStart:"0px",
      marginBlockEnd:"5px",
    }
  }
}));

const getCurrentDate = ()=>{
  const date = new Date()
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "June","July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  return `${date.getDate()} ${months[date.getMonth()]},${date.getFullYear()}`
}

const fixText =(text)=>{
  const textLimit = 5
  if(text.length>textLimit){
    return text.slice(0,textLimit) + ".."
  }

  return text
}

const Navbar = () => {
  const classes = useStyles();
  const [slider, setSlider] = React.useState(false);
  const isSmall = useMediaQuery({query:"(max-width: 472px)"});

  const { userDetails,setUserDetails } = useContext(GlobalContext);

  const toggleDrawer = (val) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    val && setUserDetails({...userDetails,selectedTab:val});
    setSlider(!slider);
  };

  const profileImageUrl = window.localStorage.getItem('profileImageUrl')

  const signOut = async () => {

    const token = window.localStorage.getItem('authToken')
    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/auth/logout`,{
      headers:{
        'token':token
      }
    }).then(response =>response.json())
      .then(response =>{
        localStorage.removeItem("authToken");
        window.location.href = `${process.env.REACT_APP_REDIRECT}?logout=true`;
      })
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar
          position="fixed"
          style={{ background: isSmall?"#5CE0D2":"white", color: "black" }}
        >
          {
          isSmall?
            <Toolbar>
              <IconButton
                edge="start"
                onClick={toggleDrawer()}
                className={classes.menuButton}
                aria-label="menu"
                style={{color:"white"}}
              >
                <WhiteSidebar></WhiteSidebar>
              </IconButton>

              {
                (userDetails.selectedTab==="2Xcell Resources" || userDetails.selectedTab==="My Assessment")?
                <span className={classes.mobileTop}>
                  <h5 style={{ fontWeight: 'bolder',margin:"5px 0px"}}>{userDetails.selectedTab}</h5>
                  <p style={{ color: 'white',fontSize:'0.8em'}}>
                    {userDetails.selectedPath.subject ? (
                      (userDetails.selectedPath.subject?(fixText(userDetails.selectedPath.subject) + ">"):"") +
                      (userDetails.selectedPath.chapter?(fixText(userDetails.selectedPath.chapter )+ ">"):"") +
                        (userDetails.selectedPath.topic?(fixText(userDetails.selectedPath.topic) + ">"):"" )
                        ):"Subject"}
                  </p>
                </span>:<h3 style={{ fontWeight: 'bolder',margin:"0px"}}>{userDetails.selectedTab}</h3>

                
              }
              {/* <NotificationIcon className="mx-3 my-auto"/> */}
              <p className="text-light" style={{marginLeft:"auto",fontSize:"0.9em"}}>{getCurrentDate()}</p>
            </Toolbar>:
            <Toolbar>
              <IconButton
                edge="start"
                onClick={toggleDrawer()}
                className={classes.menuButton}
                aria-label="menu"
              >
                <img src={SidebarIcon}  style={{width:"25px",height:"20px"}} />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                <img src={TitleImage} alt="TitleImage" style={{width:"70px",height:"70px"}} />
              </Typography>

              {/* <NotificationIcon className="mx-3 my-auto"/> */}
              <p className="mx-3 my-auto fw-bold">{userDetails.name}</p>
              <li className="nav-item" style={{ listStyle: "none" }}>
                {!["undefined",undefined,null,"null"].includes(profileImageUrl)? (
                  <img
                    src={profileImageUrl}
                    alt="ProfileImage"
                    width="40px"
                    height="40px"
                    id="navbarDropdown"
                    className="rounded-circle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  />
                ) : (
                  <AvatarImage
                    alt="ProfileImage"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  />
                )}
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="#" onClick={signOut}>
                      <ExitToAppIcon /> Sign Out
                    </Link>
                  </li>
                </ul>
              </li>
            </Toolbar>
          }
        </AppBar>
      </div>

      <Sidebar slider={slider} toggleDrawer={toggleDrawer} />
    </>
  );
};

export default Navbar;

import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  submitBtn: {
    backgroundColor: "#5CE0D2",
    borderRadius: "7px",
    width: "200px",
    "&:hover": {
      backgroundColor: "#5CE0D2",
    },
  },
});

const Submitted = () => {
  const classes = useStyles();

  const redirect = () =>{
    window.location.href="/dashboard"
  }

  return (
    <>
      <div className="text-center mb-4">
        <small className="text-muted ">Your test is submitted</small>
      </div>

      <div className="text-center">
        <Button variant="contained" className={classes.submitBtn} onClick={redirect} >
          Back
        </Button>
      </div>
    </>
  );
};

export default Submitted;

import { NavLink } from 'react-router-dom';
import React from 'react';
import '../../LearningResources/styles/Assesment.css'
import {ReactComponent as PractiseTest} from '../../../images/assessment/PractiseTest.svg';
import Go from '../../../images/assessment/Go';
import {ReactComponent as OnlineTest }from '../../../images/assessment/OnlineTest.svg';
import AnalyticsSvg from '../../../images/analytics/Analyticssvg.jsx';
import {Link} from 'react-router-dom';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { GlobalContext } from "../../../context/GlobalState";
import { useContext } from "react";
import {useAlert} from "react-alert";
import {useMediaQuery} from 'react-responsive'

const getCurrentDate = ()=>{
    const date = Date().slice(0,15).split(" ");
    return date[0]+","+date[1]+" "+date[2]+","+date[3]
  }

const Assesment = ({displayTopSection = true}) => {

  //Create instances
  const {userDetails,setUserDetails} = useContext(GlobalContext);  
  console.log("User details ====>",userDetails)
  const isSmall = useMediaQuery({query:"(max-width: 472px)"})

  React.useEffect(()=>{
    isSmall && displayTopSection && setUserDetails({...userDetails,selectedTab:"Analytics"})
    isSmall && setUserDetails({...userDetails,selectedPath:{
      subject:"",
      chapter:"",
      topic:""
    }})
  },[])

  return (
    <section className='learningResources my-md-5 my-sm-1 p-lg-0 p-2 p-sm-2'>

        <div className="container">
            {(displayTopSection && !isSmall)?
            <div className='box mb-5 row' style={{ cursor: 'pointer',alignItems: 'center'}}>
              <span style={{display: 'flex',flexDirection: 'row',width: 'fit-content'}}>
                <h2 className='fw-bold white_color' style={{ fontWeight: 'bolder',color: 'white',width:"100% !important" }} >Analytics</h2>
                {/* <h1 style={{ fontWeight: 'bolder',color: 'white'}} >Practice Assessment</h1> */}
              </span>
              <h5 className="col-sm-3" style={{marginLeft:"auto",fontSize:'90%',fontWeight:'bolder',marginBottom:"auto",textAlign:"right"}}>
                <CalendarTodayIcon style={{width:"17px"}}/> {getCurrentDate()}
              </h5>
              <p className=" white_color" style={{color:'white'}}></p>
            </div>:""}

            {/* Tests section  */}
            <div className='tests-links'>

              {/* For the practice session  */}
                <Link to="/analytics/assessment" className='link-box' style={{cursor: 'pointer',padding:"20px 10px"}}>
                  <AnalyticsSvg/>
                  <p>Practice Session</p>
                  {!isSmall && <Go styles={{marginLeft:"auto"}} />}
                </Link>

                {/* For the online test  */}
                <Link to="/analytics/tests/charts" className='link-box secondary-box' style={{cursor: 'pointer'}}>
                  {/* <OnlineTest /> */}
                  <AnalyticsSvg/>
                  <p>Online Test</p>
                  {!isSmall && <Go styles={{marginLeft:"auto"}} />}
                </Link> 
            </div>
        </div>
    </section>
  );
};

export default Assesment;

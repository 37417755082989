
export const overAllAnalytics={
    getData:`${process.env.REACT_APP_VERSION2}api/v1/students/testanalytics/overallteststatus`,
    timeSpent:`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/timespentontests`,
    getTestStatus:`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/testattemptstatus`,
    questionAttemptStatus:`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/testquestionsattemptstatus`,
    correctQuestionLevel:`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/avgcorrectquestionlevelstatus`,
    learningStatus:`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/testlearningstatus`,
    testReports:`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/studenttestreports`,
    testQuestionsReport:`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/studenttestquestionsreport`

    
}

export const FilterBySubject={
    getData:(subjectId)=>`${process.env.REACT_APP_VERSION2}api/v1/students/testanalytics/overallteststatus?subject=${subjectId}`,
    timeSpent:(subjectId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/timespentontests?subject=${subjectId}`,
    getTestStatus:(subjectId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/testattemptstatus?subject=${subjectId}`,
    questionAttemptStatus:(subjectId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/testquestionsattemptstatus?subject=${subjectId}`,
    correctQuestionLevel:(subjectId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/avgcorrectquestionlevelstatus?subject=${subjectId}`,
    learningStatus:(subjectId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/testlearningstatus?subject=${subjectId}`,
    testReports:(subjectId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/studenttestreports?subject=${subjectId}`,




}
export const FilterByChapter={
    getData:(subjectId,chapterId)=>`${process.env.REACT_APP_VERSION2}api/v1/students/testanalytics/overallteststatus?subject=${subjectId}&chapter=${chapterId}`,
    timeSpent:(subjectId,chapterId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/timespentontests?subject=${subjectId}&chapter=${chapterId}`,
    getTestStatus:(subjectId,chapterId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/testattemptstatus?subject=${subjectId}&chapter=${chapterId}`,
    questionAttemptStatus:(subjectId,chapterId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/testquestionsattemptstatus?subject=${subjectId}&chapter=${chapterId}`,
    correctQuestionLevel:(subjectId,chapterId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/avgcorrectquestionlevelstatus?subject=${subjectId}&chapter=${chapterId}`,
    learningStatus:(subjectId,chapterId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/testlearningstatus?subject=${subjectId}&chapter=${chapterId}`,
    testReports:(subjectId,chapterId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/studenttestreports?subject=${subjectId}&chapter=${chapterId}`,

}
export const FilterByTopic={
    getData:(subjectId,chapterId,topicId)=>`${process.env.REACT_APP_VERSION2}api/v1/students/testanalytics/overallteststatus?subject=${subjectId}&chapter=${chapterId}&topic=${topicId}`,
    timeSpent:(subjectId,chapterId,topicId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/timespentontests?subject=${subjectId}&chapter=${chapterId}&topic=${topicId}`,
    getTestStatus:(subjectId,chapterId,topicId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/testattemptstatus?subject=${subjectId}&chapter=${chapterId}&topic=${topicId}`,
    questionAttemptStatus:(subjectId,chapterId,topicId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/testquestionsattemptstatus?subject=${subjectId}&chapter=${chapterId}&topic=${topicId}`,
    correctQuestionLevel:(subjectId,chapterId,topicId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/avgcorrectquestionlevelstatus?subject=${subjectId}&chapter=${chapterId}&topic=${topicId}`,
    learningStatus:(subjectId,chapterId,topicId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/testlearningstatus?subject=${subjectId}&chapter=${chapterId}&topic=${topicId}`,
    testReports:(subjectId,chapterId,topicId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/studenttestreports?subject=${subjectId}&chapter=${chapterId}&topic=${topicId}`,


}


export const QuestionsReport={
    questionDetails:(testId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/studenttestquestionsdetails?test=${testId}`,
    questionReport:(submissionId)=>`${process.env.REACT_APP_ORIGIN2}/api/v1/students/testanalytics/studenttestquestionsreport?submission=${submissionId}`
}
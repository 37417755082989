import React from 'react';
import { BarChart, Bar, Brush,Cell, XAxis, YAxis, CartesianGrid, Tooltip, Label,LabelList, ResponsiveContainer } from 'recharts';
import useStyles from '../../styles';
import { useMediaQuery } from 'react-responsive';
import {ReactComponent as Loader} from "../../../../../images/analytics/ChartLoader.svg"
const {randomColor} = require("../../utils/Color")

export default function SubjectProgressReport ({data,loading}) {

    const isSmall = useMediaQuery({query:"(max-width: 472px)"})
    const classes = useStyles()
    

    return (
        <div className={classes.container}> 
            <div className={classes.headingContainer}>
                <h3>Time Spent on Practice</h3>
                <p>&nbsp;</p>
            </div>
            {loading?<Loader/>:
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                width={300}
                height={250}
                data={data}
                margin={{
                    top: 25,
                    right: isSmall?3:5,
                    left: isSmall? -7 : 15,
                    bottom: isSmall?20:80,
                }}
                >
                <CartesianGrid strokeDasharray="3 6" />
                <XAxis dataKey="Subject" allowDataOverflow={true}>
                    <Label value="Subjects" offset={isSmall?-25:-30} position="insideBottom"/>
                </XAxis>
                <Brush dataKey="Subject" height={10} endIndex={data.length-1} />
                <YAxis label={{ value: "Questions Practiced", angle: -90,offset: isSmall? 18:-2, position: 'insideLeft' }} />
                <Tooltip />
                    <Bar dataKey={"Time"}>
                        <LabelList dataKey={"Time"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill={randomColor()} ></Cell> )}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>}
        </div>
    );
 }


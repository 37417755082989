import React, { useState, useEffect } from "react";
import LeftArrow from "../../../images/assessment/LeftArrow";
import RightArrow from "../../../images/assessment/RightArrow";
import AssesmentHeader from "../../LearningResources/Assement/AssesmentHeader";
import ProgressBar from "../../LearningResources/Assement/ProgressBar";
import "../../LearningResources/Assement/Assesment.css";
// import '../../LearningResoucres/Assement/Questions/McqCards/mcqtest.css';
import useSlider from "./useSlider";
import SubmitTest from "../../LearningResources/Assement/SubmitTest";
import { useParams, useHistory } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalState";
import { useContext } from "react";
import { useAlert } from "react-alert";
import Save from "../../../images/assessment/Icon.png";
import { useMediaQuery } from "react-responsive";
import Drawer from "../../Navbar/Drawer";

const getPercentage = (a, b) => {
  if (b == 0) {
    return 0;
  } else {
    return ((a * 100) / b).toPrecision(4);
  }
};

const Test = () => {
  //Create instances
  const params = useParams();
  const history = useHistory();
  const { userDetails } = useContext(GlobalContext);
  const alert = useAlert();
  const isSmall = useMediaQuery({ query: "(max-width:472px)" });

  //Extracting data
  const testId = params.testId;

  const [onlineTest, setOnlineTest] = useState({});
  const [questions, setQuestions] = useState([]);
  const [attemptedQuestions, setAttemptedQuestions] = useState([]);
  const [loading, setLoading] = useState(false);

  //State and variables for modal and slider
  const [modal, setModal] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [handleSlide, showSlide] = useSlider("#B46B82");
  const [index, setIndex] = useState(0);
  const [allVisited, setAllVisited] = useState(false);
  const [prevButton, setPrevButton] = useState(false);
  const [nextButton, setNextButton] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  window.submitted = submitted;

  //State and variables for questions
  const [question, setQuestion] = useState({});
  const [progress, setProgress] = useState(0);
  const [startingTime, setStartingTime] = useState(new Date().getTime());

  //States for answers
  const [correctAnswer, setCorrectAnswer] = useState({});
  const [allAnswers, setAllAnswers] = useState({});

  //Function to fetch the online test
  const fetchOnlineTest = async () => {
    await fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${userDetails.grade}/sections/${userDetails.section}/assigntests/${testId}/submissions`,
      {
        headers: {
          token: window.localStorage.getItem("authToken"),
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          setOnlineTest(response.data.testSession);
          setQuestions(response.data.testSession.questionSet.questions);
          setQuestion(response.data.testSession.questionSet.questions[0]);
          setProgress(
            getPercentage(
              response.data.testSession?.answers.length,
              response.data.testSession.totalQuestions
            )
          );
          const questionsCount =
            response.data.testSession.questionSet.questions.length;
          setAllVisited(questionsCount === 1);
          setNextButton(questionsCount !== 1);
          for (let answer of response.data.testSession.answers) {
            attemptedQuestions[answer.questionId] = 1;
            setAttemptedQuestions(attemptedQuestions);
          }
        } else {
          alert.error(response.message);
        }
      });
  };

  const preventRefresh = (e) => {
    if (submitted) return;

    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "Do you want to leave this page?";
  };

  const preventBack = () => {
    if (window.submitted) return;
    window.alert("Do you want to leave this page?");
  };

  useEffect(() => {
    fetchOnlineTest();
    //For page refresh
    // window.addEventListener('beforeunload', preventRefresh)
    //for back button
    // if(window.location.href.includes('/myassessment/online-test/tests/')){
    //   window.addEventListener('popstate',preventBack);
    // }
    // if(!window.location.href.includes('/myassessment/online-test/tests/')){
    //   window.removeEventListener('popstate',preventBack);
    // }

    // return ()=>{
    //   window.removeEventListener('popstate',preventBack);
    // }
  }, []);

  //function to handle functioning of carousel
  const handleCarousel = async (_index) => {
    if (_index < 0 || _index === questions.length) {
      return;
    }

    //Check if student has visited all questions
    if (_index + 1 === questions.length) {
      setAllVisited(true);
    }

    if (_index == 0) {
      setPrevButton(false);
    } else {
      setPrevButton(true);
    }

    if (_index + 1 === questions.length) {
      setNextButton(false);
    } else {
      setNextButton(true);
    }

    //Add the question to the list of attempted questions(if answered)
    if (correctAnswer && (correctAnswer[0] || correctAnswer.answer)) {
      attemptedQuestions[questions[index].questionId._id] = 1;
      setAttemptedQuestions(attemptedQuestions);
    }

    //update the progress
    setProgress(
      getPercentage(Object.keys(attemptedQuestions).length, questions.length)
    );

    //Add the answer to the final answer array along with the additional data
    if (allAnswers[questions[index].questionId._id]) {
      allAnswers[questions[index].questionId._id].timeTaken +=
        new Date().getTime() - startingTime;
    } else {
      allAnswers[questions[index].questionId._id] = {
        answer: correctAnswer,
        startTime: startingTime,
        timeTaken: new Date().getTime() - startingTime,
      };
    }

    //Send the answer to the backend
    setLoading(true);
    await fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${userDetails.grade}/sections/${userDetails.section}/assigntests/${testId}/submissions/${onlineTest._id}`,
      {
        method: "PATCH",
        headers: {
          token: `${window.localStorage.getItem("authToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          answer: correctAnswer,
          startTime: startingTime,
          timeTaken: new Date().getTime() - startingTime,
          questionId: questions[index].questionId._id,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.status !== "success") {
          alert.error(response.message);
        }
      });

    setAllAnswers(allAnswers);
    setCorrectAnswer({});
    setStartingTime(new Date().getTime());

    //Update the question based on left or right click of the user
    setIndex(_index);
    setQuestion(questions[_index]);
    setLoading(false);
  };

  //Function to submit the test in the confirmation modal
  const submit = async () => {
    //body to be sent in response
    const body = {
      answers: allAnswers,
    };

    await fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${userDetails.grade}/sections/${userDetails.section}/assigntests/${testId}/submissions/${onlineTest._id}/submit`,
      {
        headers: {
          token: `${window.localStorage.getItem("authToken")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("Submit answer response ===> ", response);

        if (response.status !== "success") {
          console.log(response.message);
        }
      });
  };

  //function to handle click of sumit button
  const handleSubmit = async () => {
    //Add the last question that was answered
    await handleCarousel(index);

    setSubmitted(true);
    //Render carousel
    setModal(true);
  };

  return (
    <>
      <SubmitTest
        modal={modal}
        setModal={setModal}
        setConfirmed={setConfirmed}
        submit={submit}
        questions={questions}
        onlineTestId={onlineTest._id}
        preventRefresh={preventRefresh}
        preventBack={preventBack}
      />
      {isSmall && (
        <Drawer>
          <AssesmentHeader
            title={"Online Assessment"}
            background={"#BA768C"}
            showDetails={false}
            onlineTest={onlineTest}
            totalQuestionsToAttempt={questions.length}
            totalAttemptedQuestions={Object.keys(attemptedQuestions).length}
            submit={submit}
          />
        </Drawer>
      )}
      <section className="my-4">
        <div className="container">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="mainCont">
              {!isSmall && (
                <AssesmentHeader
                  title={"Online Assessment"}
                  background={"#BA768C"}
                  showDetails={false}
                  onlineTest={onlineTest}
                  totalQuestionsToAttempt={questions.length}
                  totalAttemptedQuestions={
                    Object.keys(attemptedQuestions).length
                  }
                  submit={submit}
                />
              )}
              <ProgressBar
                progressText="Completed"
                test
                color="#B46B82"
                width={Math.min(progress, 100) + "%"}
              />
            </div>
          </div>
          {showSlide(
            question.questionId,
            index + 1,
            correctAnswer,
            setCorrectAnswer,
            onlineTest._id,
            loading
          )}
          <div className="slider">
            <LeftArrow
              className="slider-img"
              onClick={() => {
                !loading && handleCarousel(index - 1);
              }}
              color="#B46B82"
              style={{ visibility: prevButton ? "visible" : "hidden" }}
            />
            <button
              className="save-btn submit-btn"
              style={{ background: allVisited ? "#BA768C" : "#adb5bd" }}
              disabled={!allVisited}
              onClick={handleSubmit}
            >
              Submit
            </button>

            {/* <button className='save-btn' onClick={()=>{submitAnswer()}} color='#B46B82'>
              <img src={Save} alt='save-btn' />{allAttemptsWrong?"Next":"Submit"}</button> */}

            <RightArrow
              className="slider-img"
              onClick={() => {
                !loading && handleCarousel(index + 1);
              }}
              color="#B46B82"
              style={{ visibility: nextButton ? "visible" : "hidden" }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Test;

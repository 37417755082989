import React from 'react'
import { Col, Row } from "react-bootstrap";
import Stat from '../common/Stat'
import StatBar from '../common/StatBar'
import "../QuestionReport.css"
import IsSmallScreen from '../common/IsSmallScreen';
import {GlobalContext} from "../../../../../context/GlobalState"
const StatBarWidth = require("../common/StatBarWidth")

function FillUp({data,index}) {

    const isSmallScreen = IsSmallScreen
    const {userDetails} = React.useContext(GlobalContext)

    return (
        <Row className="analytics_question_layer">
          <Col className="analytics_question_content">
            <Row className="analytics_question_heading">
                <p>Question {index+1}</p>
                <p className="analytics_question_heading_card">{data._id.marks} Marks</p>
                <p className="analytics_question_heading_card">{data.questionId.difficulty}</p>
            </Row>
            <Row className="analytics_question_statement">
              {data.questionId.question}
            </Row>
            <Row>
              <Row
                className="analytics_question_fill_in_the_blank"
                style={{ color: "#47DE85" }}
              >
                <u>{data.statistics.find(user=> user.userId === userDetails.userId).answer.fillup.map(answer => answer.answer).join(",")}</u>
              </Row>
            </Row>
            <Row
              className="correct_answer_fill_in_the_blank"
              style={{ marginBottom: isSmallScreen ? 5 : 0 }}
            >
              Correct Answer: {data.questionId.correctAnswers.fillup.map(answer=>answer.answer).join(",")}
            </Row>
          </Col>
          <Col className="analytics_question_stats">
            <Stat>
              <StatBar style={{ width: StatBarWidth(data, undefined), backgroundColor: "#7EB6A2" }} /> 
              {data.statistics.map(user => user.answer.fillup.map(answer => answer.answer).join(",") === data.questionId.correctAnswers.fillup.map(answer => answer.answer).join(",")).length} Student
            </Stat>
          </Col>
        </Row>
    )
}

export default FillUp

import React,{useState} from 'react';
import { Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow } from '@material-ui/core';
import  './table.css';
import { htmlDecode } from '../../../../../utils/parse';
// import {TablePage} from './Table.style';

function TableComponent(props) {
    const {tabHeader,tabContent,handleClick=()=>{}}=props;
    const [page,setPage]=useState(0);
    const [rowsPerPage,setRowsPerPage]=useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
     
    return (
        <div style={{width:"100%",overflowX:"auto"}}>
            <TableContainer style={{maxWidth:"100%",margin:"0 auto"}}>
           
           <Table style={{boxSizing:"border-box"}} stickyHeader aria-label="sticky table">
                    <TableHead  style={{backgroundColor:"transparent !important"}}>
                        <TableRow>
                            {
                                
                                    tabHeader.map((column)=>(
                                        <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{minWidth:column.minWidth,backgroundColor:column.backgroundColor,color:column.color,fontSize:column.fontSize,borderBottom:column.borderBottom}}

                                        >
                                            {column.label}

                                        </TableCell>
                                    ))
                                
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            tabContent.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,idx)=>{
                            return(
                                   <TableRow className="rowHover" style={{cursor:"pointer",backgroundColor:`${idx%2!==0?"#DEBEC8":"#fff"}`,fontWeight:"700"}} onClick={(e)=>handleClick(row)} tabIndex={-1}>
                                       {tabHeader.map((column)=>{
                                           const value=row[column.id];
                                           return(
                                               <TableCell style={{textAlign:"center"}} dangerouslySetInnerHTML={{
                                                __html: htmlDecode(value),
                                              }} />
                                                  
                                               
                                           )
                                       })
                                           
                                       }
                                    </TableRow>

                                )
                            })
                        }
                    </TableBody>
                </Table>
                <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={tabContent.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
            </TableContainer> 

        </div>
    )
} 

TableComponent.defaultProps={
    tabHeader:[],
    tabContent:[],
    handleOnClick:()=>{}
}
export default TableComponent;

import React from 'react'
import {useMediaQuery} from 'react-responsive'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import TestFilter from './TestFilter/TestFilter'
import {makeStyles} from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {Link} from "react-router-dom"
import Button from "@material-ui/core/Button";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles(theme => ({
    root:{
        display: 'flex',
        flexDirection:'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    card:{
        width:"100%",
        borderRadius: "10px",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
        padding:"20px", 
        margin: "20px auto"
    },
    subjectSection: {
        textAlign: "center",
        "& button":{
            border:"none",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
            borderRadius: "5px",
            marginBottom:"10px",
            padding:"4px",
            minWidth:"120px",
            color:"white"
        },
        "& p":{
            marginBlockStart:"0px",
            marginBlockEnd:"0px",
            fontSize:"0.8em",
        }
    },
    report:{
        textAlign: "center",
        "& a":{
            backgroundColor:"rgba(29, 205, 109, 0.78)",
            padding:"7px 10px",
            borderRadius:"10px",
            color:"white",
            textDecoration: "none",
            height:"fit-content",
            transition:"0.2s",
            "& span":{
                backgroundColor:"rgba(112, 253, 175, 0.73)",
                color:"white",
                borderRadius:"50%",
                height:"fit-content",
                width:"fit-content",
                padding:"3px",
                verticalAlign:"middle",
            },
            "&:hover":{
                backgroundColor:"rgba(29, 205, 109, 1)",
                color:"white !important",
                boxShadow:"0px 0px 5px rgba(0, 0, 0, 0.25)",
            }
        }
    },
    menu:{
        textAlign:"center",
    },
    contextMenu:{
        "& .MuiList-root":{
            padding:"10px 10px 5px 10px",
        },
        "& .MuiPaper-rounded":{
            borderRadius:"5px !important"
        },
        "& li":{
            color:"white",
            fontSize:"0.9em",
            background: "rgba(180, 107, 130, 0.8)",
            marginBottom:"5px",
            borderRadius:"5px !important",
            transition:"0.3s",
            "&:hover":{
                background: "rgba(180, 107, 130, 1)",
                fontWeight: 'bold',
            }
        }
    },
    "@media screen and (max-width: 472px)":{
        card:{
            width:"95%",
            padding:"10px 7px"
        },
        subjectSection:{
            "& button":{
                fontSize:"0.8em",
                minWidth: "80px"
            },
            "& p":{
                fontSize:"0.6em"
            }
        },
        report:{
            "& a":{
                fontSize:"0.7em",
            }
        },
        contextMenu:{
            padding:"5px 5px 3px 5px",
            fontSize:"0.7em",
            "& li":{
                minHeight:"35px"
            }
        }
    }
}))

const data = [
    {
        subject:"Mathematics",
        date:"10-05-2021",
        time:"6.00-10.00",
        testId:"1", 
        background:"rgba(36, 147, 110, 1)"
    },
    {
        subject:"English",
        date:"10-05-2021",
        time:"6.00-10.00",
        testId:"2", 
        background:"rgba(229, 152, 155, 1)"
    },
    {
        subject:"Hindi",
        date:"10-05-2021",
        time:"6.00-10.00",
        testId:"3", 
        background:"rgba(102, 153, 187, 0.9)"
    },
    {
        subject:"EVS",
        date:"10-05-2021",
        time:"6.00-10.00",
        testId:"4", 
        background:"rgba(255, 180, 162, 1)"
    },
]

const getCurrentDate = ()=>{
    const date = Date().slice(0,15).split(" ");
    return date[0]+","+date[1]+" "+date[2]+","+date[3]
}

function OnlineTestAnalytics() {

    const classes = useStyles();
    const history=useHistory();
    const isSmall = useMediaQuery({query:"(max-width: 472px)"})

    const [filterData,setFilterData] = React.useState({})
    
    const SubjectCard = ({index,subject,date,time,testId,background}) => {
        
        const [open,setOpen] = React.useState(false)
        const handleClose = ()=> setOpen(false)
        const handleOpen = ()=> setOpen(true)

        return(
        <Grid container className={classes.root}  justifyContent="center" alignItems="center" >
            <Grid item lg={1} style={{textAlign: 'center'}} >{index}</Grid>
            <Grid container item className={classes.card} lg={10} justifyContent={isSmall?"space-between":"center"} alignItems="center" >
                <Grid item lg={6} className={classes.subjectSection}>
                    <button style={{background:background}} >{subject}</button>
                    <p>{date}</p>
                    <p>{time}</p>
                </Grid>
                <Grid item lg={4} sm={6} className={classes.report} style={isSmall ? {marginLeft:"auto"}:{}} >
                    <Link to="/analytics/tests/charts"> 
                        <span><DescriptionOutlinedIcon fontSize="small" /></span>  
                        &nbsp;Report
                    </Link>
                </Grid>
                <Grid item lg={1} sm={0} className={classes.menu} >
                    <Button ariaControls={`context-menu-${index}`} id={`card-${index}`} onClick={handleOpen} >
                        <MoreHorizIcon/>
                    </Button>
                    <Menu
                        id={`context-menu-${index}`}
                        className={classes.contextMenu}
                        anchorEl={document.getElementById(`card-${index}`)}
                        keepMounted
                        open={open}
                        onClose={handleClose}>
                            <MenuItem onClick={handleClose} className={classes.contextMenuItem}>Export as PDF</MenuItem>
                            <MenuItem onClick={handleClose} className={classes.contextMenuItem}>Share</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </Grid>)
    }


    return (
        <section className='learningResources my-md-5 my-sm-1 p-lg-0 p-2 p-sm-2'>
            
            <div className="container">
                {!isSmall?
                <div className='box mb-5 row' style={{ cursor: 'pointer',alignItems: 'center'}}>
                <span style={{display: 'flex',flexDirection: 'row',width: 'fit-content'}}>
                    <h2 className='fw-bold white_color' style={{ fontWeight: 'bolder',color: 'white',width:"100% !important" }} >Analytics</h2>
                    {/* <h1 style={{ fontWeight: 'bolder',color: 'white'}} >Analytics</h1> */}
                </span>
                <h5 className="col-sm-3" style={{marginLeft:"auto",fontSize:'90%',fontWeight:'bolder',marginBottom:"auto",textAlign:"right"}}>
                    <CalendarTodayIcon style={{width:"17px"}}/> {getCurrentDate()}
                </h5>
                <p className=" white_color" style={{color:'white'}}>Online Test</p>
                </div>:""}
                <div style={{textAlign:"end"}}>
                <button onClick={()=>{history.push('/analytics/tests/charts')}} style={{backgroundColor:"rgba(29, 205, 109, 0.78)",color:"#fff",border:"none",borderRadius:"5px",padding:"5px 10px"}} >View Analytics</button>
                </div>
                <TestFilter filterData={filterData} setFilterData={setFilterData} ></TestFilter>
                {data.map((item,index) => 
                    <SubjectCard 
                        index={index+1}
                        subject={item.subject}
                        date={item.date}
                        time={item.time}
                        testId={item.testId}    
                        background={item.background}                    
                    />    
                )}
            </div>
        </section>
    )
}

export default OnlineTestAnalytics

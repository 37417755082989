import React,{ useState,useEffect} from 'react';
import Calendar from '../../../images/assessment/Calender.svg';
import Rank from '../../../images/assessment/ranking-stars 1.png';
import Correct from '../../../images/assessment/correct.png';
import InCorrect from '../../../images/assessment/incorrect.png';
import Clock from '../../../images/assessment/Clock';
import TestClock from '../../../images/assessment/TestClock.png';
import Mark from '../../../images/assessment/Mark';
import {useParams} from 'react-router-dom'
import { GlobalContext } from "../../../context/GlobalState";
import { useContext } from "react";
import {useMediaQuery} from 'react-responsive'
import {useAlert} from 'react-alert'
import Popup from "./Popup"

function msToTime(duration) {

  if(duration<=0){
    return "--:--:--"
  }

  var seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds ;
}

const AssesmentHeader = ({ title, background,totalAttemptedQuestions,totalQuestionsToAttempt,correctlyAnswered,awardedGrade,onlineTest,timeSpend,sessionCompleted,submit }) => {

  const params = useParams()
  const { userDetails } = useContext(GlobalContext);
  const isSmall = useMediaQuery({query:"(max-width:472px)"})
  const alert = useAlert();

  const getDate = ()=>{
    const date = new Date().getDate();
    const month = new Date().toLocaleDateString('default',{month:'long'})
    const year = new Date().getFullYear()
    return date + " "+ month+ " "+year
  }
  
  const getDay = ()=>{
    const date = new Date();  
    const day = date.toLocaleString('default', { weekday: 'long' });
    return day.toUpperCase().slice(0,3)
  }

  const [time,setTime] = useState(0)
  // const [duration,setDuration] = useState(0)
  const [open,setOpen] = useState(false)
  const [isDurationUpdated,setDurationUpdated]=useState(false);
  const [render,setRender]=useState(false);
  const duration=React.useRef(0);
  console.log(onlineTest);
  console.log()
  
  useEffect(() => {

    try{
      if(!onlineTest) return
  
      let currentTime = new Date().getTime()
      let startTime = new Date(onlineTest.startedAt).getTime();
      // onlineTest && onlineTest.assignTest && setDuration(onlineTest.assignTest.duration-(currentTime - startTime))
      if(onlineTest && onlineTest.assignTest)
      {
      duration.current=onlineTest.assignTest.duration-(currentTime - startTime);
      setDurationUpdated(true);
      }
    }catch(err){
      console.log(err)
    }

  },[onlineTest])
  

  useEffect(() => {
    try{
      timeSpend !== undefined && setTime(timeSpend + 1000)
    }catch(err){
      console.log(err)
    }
  },[timeSpend])

  useEffect(() => {
    setTimeout(async () => {
      if(onlineTest && onlineTest.assignTest && onlineTest.assignTest.to&&isDurationUpdated){
        console.log("from timeout ------------------->")

        // setDuration(duration-1000)
        if(duration.current<0){
          await submit()
          setOpen(true)
          setTimeout(() =>{
            window.location.href="/myassessment/online-test/tests"
          },5000)
        }
        duration.current=duration.current-1000;
        setRender(!render);
      }else if(timeSpend > -1){
        // setTime(time + 1000)
        !sessionCompleted && totalQuestionsToAttempt && setTime(time + 1000)
      }

    }, 1000);
  },[duration,time,isDurationUpdated,render])
  console.log(duration);

  console.log(isDurationUpdated);

  return (
    <>
    <Popup modal={open} status="auto-submitted" ></Popup>
    <div className='box'>
      <div className='header' style={{ ...(background && { background }) }}>
        {!isSmall && 
        <div className='details-container'>
          <div className='assesment-detail'>
            <h1>{userDetails.grade}-{userDetails.section}</h1>
            <h2>{title}</h2>
            <p
              className='content'
              style={{ ...(background && { color: 'white' }) }}
            >
              {params.subjectName?params.subjectName+" > ":""} {params.chapterName?params.chapterName+" > ":""} {params.topicName}
            </p>
          </div>
          <div className='assessment-time'>
            <div className='calendar-img'>
              <img src={Calendar} alt='calendar' />
              <span className='day'>{getDay()}</span>
            </div>
            <div className='month'>{getDate()}</div>
          </div>
        </div>}
        <div className='flex headerRight'>
          {!background ? (
            <>
              <div className='detail-holder'>
                <div>
                  <p className='answer'>{correctlyAnswered}</p>
                  <img src={Correct} alt='correct-answer' />
                  <p className='answer'>Correct</p>
                </div>
              </div>

              <div className='detail-holder'>
                <div>
                  <p className='answer'>{totalAttemptedQuestions-correctlyAnswered}</p>
                  <img src={InCorrect} alt='correct-answer' />
                  <p className='answer'>Incorrect</p>
                </div>
              </div>
            </>
          ) : (
            ''
          )}

          <div className='detail-holder'>
            <div className='markCont'>
              <Mark color={background} />
              <span className='marks'>
                <span className='mark-text' style={{ color: '#5AE893' }}>{
                (totalAttemptedQuestions || (onlineTest && onlineTest.answers && onlineTest.answers.length) || 0) <= totalQuestionsToAttempt?
                (totalAttemptedQuestions || (onlineTest && onlineTest.answers && onlineTest.answers.length) || 0):
                totalQuestionsToAttempt
                }</span>
                <span className='divide'></span>
                <span className='mark-text total-mark'>{totalQuestionsToAttempt}</span>
              </span>
            </div>
            <p>Questions Attempted</p>
          </div>
          <div className='detail-holder'>
            <div className='clock'>
              {background ? <img src={TestClock} alt='clock' /> : <Clock />}
            </div>
            <p>{(onlineTest && onlineTest.assignTest && onlineTest.assignTest.to)?msToTime(duration.current):time?msToTime(time):"00:00:00"}</p>
          </div>
          {!background ? (
            <div className='detail-holder'>
              <div className='ranking'>
                <img src={Rank} alt='rank' />
                <span className='rank'>
                 {awardedGrade}
                </span>
              </div>
              <p>Grade</p>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default AssesmentHeader;

import React from 'react';
// import ReactSWF from 'react-swf';

const VideoStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '2rem',
};

function Audio(props) {
  console.log(props.location.useProps?.fileUrl);
  return (
    <div style={VideoStyle}>
      {/* <h4>{props.location.useProps?.moduleName}</h4> */}
      <video
        width='60%'
        style={{ marginTop: '2rem' }}
        controls
        controlsList='nodownload'
      >
        <source src={props.location.useProps?.fileUrl} type='video/mp4' />
        Your browser does not support HTML video.
      </video>
      {/* <object width='400' height='400' data={props.location.useProps?.fileUrl} type="application/x-shockwave-flash">
                <param name="movie" value={props.location.useProps?.fileUrl} />
                <embed src={props.location.useProps?.fileUrl} width='400' height='400' plug/>
            </object> */}
      {/* <ReactSWF src={props.location.useProps?.fileUrl} width='400' height='400'/> */}
    </div>
  );
}

export default Audio;

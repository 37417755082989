import React,{useContext} from "react";
import "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { NavLink } from "react-router-dom";
import {ReactComponent as Loader} from "../../../LearningResources/Assement/Questions/Loader/Loader.svg";
import {useMediaQuery} from "react-responsive"
import TablePagination from '@material-ui/core/TablePagination';
import { GlobalContext } from "../../../../context/GlobalState";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    
  },
  table: {
    minWidth: "fit-content",
    overflow:"scroll",
    paddingBottom:"50px"
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 280,
  },
  tableRow: {
    textDecoration: "none",
    "& > td":{
        fontWeight:"bold",
    },
    "&:hover": {
      backgroundColor: "#e2ddddc2 !important",
    },
  },
  tableHeading: {
    ' & > th':{
      fontWeight: "bolder",
          fontSize:"1.02em"
        }
      }
}));
    
const widen =(val) => ({
  minWidth:`${val}px`
})

const checkstatus = (test) => {

  //When test has been submitted
  if(test.submissions && test.submissions.length && test.submissions[0].submissionStatus==="done") {
    return "Done"
  }

  //when test has not been submitted and the time to submit has passed 
  if((!test.submissions|| !test.submissions.length) && test.to < new Date().toISOString()) {
    return "Not Attempted"
  }

  //When test has started but has not been sbumitted yet
  if(test.to > new Date().toISOString()  && test.from < new Date().toISOString()) {
    return "In Progress"
  }

  //When test has not started yet
  if(test.from > new Date().toISOString()){
    return "Assigned"
  }
}

const convertArrayToString = (array) => {

  if(!array){
    return ""
  }
  array = array && array.map(item => item.name)
  return array.join(', ')
}

function msToTime(duration) {

  if(duration<=0){
    return "--:--:--"
  }

  var seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  // hours = (hours < 10) ? "0" + hours : hours;
  // minutes = (minutes < 10) ? "0" + minutes : minutes;
  // seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + "hr " + minutes + "min " + seconds + "sec " ;
}

const TestTable = ({rows,loading}) => {

  console.log(rows)
  const cls=useContext(GlobalContext).userDetails;
  const classes = useStyles();
  const isSmall = useMediaQuery({query:"(max-width: 472px)"})

  //states for pagination  
  const [page,setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage)=>{
    setPage(newPage);
  }

  return (
    <div className={classes.tableContainer}>
      {!loading?
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableHeading}>
              <TableCell align="center">No</TableCell>
              <TableCell align="center">Test Tittle</TableCell>
              <TableCell align="center">Class</TableCell>

              <TableCell align="center">Subject</TableCell>
              <TableCell align="center">Chapter</TableCell>
              <TableCell align="center">Topic</TableCell>
              <TableCell align="center">Start Date</TableCell>
              <TableCell align="center">Time</TableCell>
              <TableCell align="center">End Date</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Total Marks</TableCell>
              <TableCell align="center">Marks Obtained</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { rows && rows.length ? 
                rows
                  .slice(page*rowsPerPage,(page+1)*rowsPerPage) //Slicing according to page
                  .map((row, index) => (
              <TableRow
                key={`${row.subject}${index}`}
                variant="button"
                component={NavLink}
                to={["Done","Assigned"].includes(checkstatus(row))?"#":`/myassessment/online-test/tests/${row._id}`}
                className={classes.tableRow}
                style={{backgroundColor:index%2?"rgba(180, 107, 130, 0.43)":"white"}}
              >
                <TableCell scope="row" align="center" >{index+1}</TableCell>
                <TableCell scope="row" align="center" style={widen(200)}>{row.questionSet.title}</TableCell>
                <TableCell scope="row" align="center" style={widen(100)}>{cls.grade}-{cls.section}</TableCell>
            
                <TableCell scope="row" align="center"  style={widen(200)}>{row.subjectId.name}</TableCell>
                <TableCell align="center" style={{wordBreak:'break-word',minWidth:'220px'}}  dangerouslySetInnerHTML={{__html:convertArrayToString(row.questionSet.chapters)}}></TableCell>
                <TableCell align="center" style={{wordBreak:'break-word',minWidth:'220px'}} dangerouslySetInnerHTML={{__html:convertArrayToString(row.questionSet.topics)}}></TableCell>
                <TableCell align="center" style={widen(150)}>{row.from.split("T")[0]}</TableCell>
                <TableCell align="center" style={widen(200)}>{msToTime(row.duration)}</TableCell>
                <TableCell align="center" style={widen(150)}>{row.to.split("T")[0]}</TableCell>
                <TableCell align="center">{row.testType}</TableCell>
                <TableCell align="center">{row.questionSet.totalMarks}</TableCell>
                <TableCell align="center">{checkstatus(row)==="Done"?row.submissions[0].totalScore:"-"}</TableCell>
                <TableCell align="center" style={widen(isSmall?150:200)}>{checkstatus(row)}</TableCell>
              </TableRow>
            )):
            <TableRow>
                <TableCell scope="row" align="center">-</TableCell>
                <TableCell scope="row" align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
              </TableRow>
          }
          </TableBody>
        </Table>
          <TablePagination
            component="div"
            count={rows.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </TableContainer>
      : <Loader/>  
    }
    </div>
  );
};

export default TestTable;

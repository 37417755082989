import React,{useEffect,useRef,useState} from 'react';
import useDisableContextMenu from '../../../hooks/useDisableContextMenu';
// import ReactSWF from 'react-swf';
import './MediaViewer.css'
import VideoProgressTracker from './Track';
const VideoStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '2rem',
};

function Video(props) {
  
  useDisableContextMenu();
  const videoTag=useRef();

  const [startedTime,setStartedTime]=useState("");
  const [endTime,setEndTime]=useState("")
  const [progress,setProgress]=useState("");
  const [status,setStatus]=useState("");
  const [playStatus,setPlayStatus]=useState("");
  const tracker=useRef("");
  
  console.log(startedTime);
 
  useEffect(()=>{
    if(props?.location?.useProps?.fileUrl)
    {
      
      tracker.current=new VideoProgressTracker({video:videoTag.current,setStartedTime,setEndTime,setProgress,setStatus,setPlayStatus});
      tracker.current.initTracking();
      // console.log(tracker.getTrackerStatus());
    }
    // window.onbeforeunload = (event) => {
    //   const e = event || window.event;
    //   // Cancel the event
    //   e.preventDefault();
    //   if (e) {
    //     e.returnValue = ''; // Legacy method for cross browser support
    //   }
    //   return ''; // Legacy method for cross browser support
    // };
  },[props])
  const getStatus=()=>{
    console.log(tracker.current.getTrackerStatus());
  }
  return (
    <div style={VideoStyle}>
      {/* <h4>{props.location.useProps?.moduleName}</h4> */}
      <video
        ref={videoTag}
        width='60%'
        style={{
          marginTop: '2rem',
          border: '3px solid #5ce0d2',
          borderRadius: '5px',
        }}
        controls
        controlsList='nodownload'
        onClick={()=>{getStatus()}}
      >
        <source  src={props.location.useProps?.fileUrl} type='video/mp4' />
        <source src={props.location.useProps?.fileUrl} type='video/ogg' />
        <source src={props.location.useProps?.fileUrl} type='video/swf' />
        Your browser does not support HTML video.
      </video>
      {/* <object width='400' height='400' data={props.location.useProps?.fileUrl} type="application/x-shockwave-flash">
                <param name="movie" value={props.location.useProps?.fileUrl} />
                <embed src={props.location.useProps?.fileUrl} width='400' height='400' plug/>
            </object> */}
      {/* <ReactSWF src={props.location.useProps?.fileUrl} width='400' height='400'/> */}
    </div>
  );
}

export default Video;

import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import "../../LearningResources/styles/Assesment.css";
import "./AssessmentAnalytics.css";
import { makeStyles } from "@material-ui/core/styles";
import MiniCards from "./MiniCards/MiniCards";
import Table from "./Table/Table";
import SubjectProgressReport from "./Charts/Subject_Progress_Report/Subject_Progress_Report";
import TimeOnPractice from "./Charts/Time_On_Practice/Time_On_Practice";
import QuestionStatusReport from "./Charts/Question_Status_Report/Question_Status_Report";
import DifficultyReport from "./Charts/Difficulty_Report/Difficulty_Report";
import CognitiveReport from "./Charts/Cognitive_Report/Cognitive_Report";
import LearningStrength from "./Charts/Learning_Strength/Learning_Strength";
import DifficultyPieChart from "./Charts/Difficulty_Report_Pie_Chart/Difficulty_Report_Pie_Chart";
import SideBoard from "../SideBoard/SideBoard";
import { Link } from "react-router-dom";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import { GlobalContext } from "../../../context/GlobalState";

const useStyles = makeStyles({
  graphContainer: {
    width: "100%",
    height: "500px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  linkContainer: {
    marginTop: "1100px",
    width: "100%",
    textAlign: "center",
    "& a": {
      background: "rgba(86, 229, 143, 0.9)",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "5px",
      color: "black",
      textDecoration: "none",
      padding: "5px",
    },
  },
  "@media screen and (max-width: 472px)": {
    graphContainer: {
      flexDirection: "column",
      height: "100%",
      width: "100%",
      marginTop: "1rem",
      "& > *": {
        marginBottom: "1rem",
      },
    },
  },
});

const getCurrentDate = () => {
  const date = Date().slice(0, 15).split(" ");
  return date[0] + "," + date[1] + " " + date[2] + "," + date[3];
};

const convertObjToQuery = (object) => {
  let query = "";
  if (object.subjectId) {
    query += `&subject=${object.subjectId}`;
  }
  if (object.chapterId) {
    query += `&chapter=${object.chapterId}`;
  }
  if (object.topicId) {
    query += `&topic=${object.topicId}`;
  }

  return query;
};

function AssessmentAnalytics() {
  //extracting util data
  const isSmall = useMediaQuery({ query: "(max-width: 472px)" });
  const classes = useStyles();
  const { userDetails } = React.useContext(GlobalContext);
  const sectionId = window.localStorage.getItem("sectionId");

  //Filterdata for sideboard
  const [filterData, setFilterData] = React.useState({
    subjectId: "",
    chapterId: "",
    topicId: "",
  });

  //states for the data
  const [loading, setLoading] = React.useState(false);
  const [practiceSessionDetails, setPracticeSessionDetails] = React.useState(
    []
  );
  const [progressReport, setProgressReport] = React.useState([]);
  const [timeOnPractice, setTimeOnPractice] = React.useState([]);
  const [questionDifficultyStats, setQuestionDifficultyStats] = React.useState(
    []
  );
  const [questionCognitiveStats, setQuestionCognitiveStats] = React.useState(
    []
  );
  const [statusReport, setStatusReport] = React.useState([]);
  const [statusReportPie, setStatusReportPie] = React.useState([]);
  const [learningStrength, setLearningStrength] = React.useState([]);
  const [loaders, setLoaders] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  //fucntion to fetch the practice session details
  const fetchPracticeSessionDetails = async () => {
    try {
      loaders[0] = true;
      setLoaders(() => [...loaders]);
      await fetch(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/students/practiceanalytics/sections/${sectionId}/practicesessiondetails?student=${userDetails.userId}` +
          convertObjToQuery(filterData),
        {
          headers: {
            token: window.localStorage.getItem("authToken"),
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log("Practice Session Details ===> ", response);
          setPracticeSessionDetails(response.data.practiseSessionStats[0]);
          loaders[0] = false;
          setLoaders(() => [...loaders]);
        });
    } catch (err) {
      console.log("Error in Practice Session Details ", err);
    }
  };

  //fucntion to fetch the question completion status
  const fetchProgressReport = async () => {
    try {
      loaders[1] = true;
      setLoaders(() => [...loaders]);
      await fetch(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/students/practiceanalytics/sections/${sectionId}/questioncompletestatus?student=${userDetails.userId}` +
          convertObjToQuery(filterData),
        {
          headers: {
            token: window.localStorage.getItem("authToken"),
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log("Question complete status ===> ", response);
          let mappedData = response.data.questionsCompleteStats.map(
            (item) =>
              item._id && {
                Subject: item._id.name,
                Attempted: item.completed,
                Pending: item.pending,
              }
          );
          setProgressReport(mappedData);
          loaders[1] = false;
          setLoaders(() => [...loaders]);
        });
    } catch (err) {
      console.log("Error in Question Status Report", err);
    }
  };

  //fucntion to fetch the time spent in practice
  const fetchTimeOnPractice = async () => {
    try {
      loaders[2] = true;
      setLoaders(() => [...loaders]);
      await fetch(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/students/practiceanalytics/sections/${sectionId}/timespentonpractice?student=${userDetails.userId}` +
          convertObjToQuery(filterData),
        {
          headers: {
            token: window.localStorage.getItem("authToken"),
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log("Time spent on practice ===> ", response);
          let mappedData = response.data.timeSpendDetails.map(
            (item) =>
              item._id && {
                Subject: item._id.name,
                Time:
                  parseInt(item.timeSpend / 3600) +
                  parseInt(item.timeSpend % 3600),
              }
          );
          setTimeOnPractice(mappedData);
          loaders[2] = false;
          setLoaders(() => [...loaders]);
        });
    } catch (err) {
      console.log("Error in time spent on practice", err);
    }
  };

  //fucntion to fetch the statistics based on difficulty of question
  const fetchQuestionDifficultyStatus = async () => {
    try {
      loaders[5] = true;
      setLoaders(() => [...loaders]);
      await fetch(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/students/practiceanalytics/sections/${sectionId}/questiondifficultystatus?student=${userDetails.userId}` +
          convertObjToQuery(filterData),
        {
          headers: {
            token: window.localStorage.getItem("authToken"),
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log("Question difficulty status ===> ", response);
          let mappedData = response.data.questionsDifficultyStats.map(
            (item) =>
              item._id && {
                Subject: item._id.name,
                Easy: item.easy,
                Intermediate: item.intermediate,
                Difficult: item.hard,
              }
          );
          setQuestionDifficultyStats(mappedData);
          loaders[5] = false;
          setLoaders(() => [...loaders]);
        });
    } catch (err) {
      console.log("Error in difficulty status report", err);
    }
  };

  //fucntion to fetch the statistics based on cognitive of question
  const fetchQuestionCognitiveStatus = async () => {
    try {
      loaders[6] = true;
      setLoaders(() => [...loaders]);
      await fetch(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/students/practiceanalytics/sections/${sectionId}/questioncognitivestatus?student=${userDetails.userId}` +
          convertObjToQuery(filterData),
        {
          headers: {
            token: window.localStorage.getItem("authToken"),
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log("Question cognitive status ===> ", response);
          let mappedData = response.data.questionsCognitiveStats.map(
            (item) =>
              item._id && {
                Subject: item._id.name,
                Knowledge: item.knowledge,
                Analysis: item.analysis,
                Understanding: item.understanding,
                Application: item.application,
              }
          );
          setQuestionCognitiveStats(mappedData);
          loaders[6] = false;
          setLoaders(() => [...loaders]);
        });
    } catch (err) {
      console.log("Error in cognitive status report", err);
    }
  };

  //fucntion to fetch the statistics based on cognitive of question
  //Issue -> cannot determine how will the data be shown
  const fetchLearningStatus = async () => {
    try {
      loaders[7] = true;
      setLoaders(() => [...loaders]);
      await fetch(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/students/practiceanalytics/sections/${sectionId}/questionlearningstatus?student=${userDetails.userId}` +
          convertObjToQuery(filterData),
        {
          headers: {
            token: window.localStorage.getItem("authToken"),
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log("Question learning status ===> ", response);
          let mappedData = response.data.questionsLearningStats[0].contents.map(
            (item) => ({
              Subject: item.content.name,
              Score: item.scorePercentage,
              Type: response.data.questionsLearningStats[0]._id,
            })
          );

          mappedData = mappedData.concat(
            response.data.questionsLearningStats[1]
              ? response.data.questionsLearningStats[1].contents.map(
                  (item) => ({
                    Subject: item.content.name,
                    Score: item.scorePercentage,
                    Type: response.data.questionsLearningStats[1]._id,
                  })
                )
              : []
          );

          console.log("Mapped data: ", mappedData);
          setLearningStrength(mappedData);
          loaders[7] = false;
          setLoaders(() => [...loaders]);
        });
    } catch (err) {
      console.log("Error in learning status report", err);
    }
  };

  //fucntion to fetch the question status report
  const fetchQuestionStatusReport = async () => {
    try {
      loaders[3] = true;
      setLoaders(() => [...loaders]);
      await fetch(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/students/practiceanalytics/sections/${sectionId}/questionAttemptStatus?student=${userDetails.userId}` +
          convertObjToQuery(filterData),
        {
          headers: {
            token: window.localStorage.getItem("authToken"),
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log("Question status report ===> ", response);
          let mappedData = response.data.questionsAttemptStats.map(
            (item) =>
              item._id && {
                Subject: item._id.name,
                Correct: item.correct,
                Incorrect: item.incorrect,
                Unattempted: item.unattempted,
              }
          );
          setStatusReport(mappedData);
          loaders[3] = false;
          setLoaders(() => [...loaders]);
        });
    } catch (err) {
      console.log("Error in learning status report", err);
    }
  };

  //fucntion to fetch the question status report
  const fetchQuestionStatusReportPie = async () => {
    try {
      loaders[4] = true;
      setLoaders(() => [...loaders]);
      await fetch(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/students/practiceanalytics/sections/${sectionId}/questionAttemptStatus?overall=true` +
          convertObjToQuery(filterData),
        {
          headers: {
            token: window.localStorage.getItem("authToken"),
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log("Question status report in Pie chart===> ", response);
          let data = response.data.questionsAttemptStats[0];

          setStatusReportPie([
            { name: "Correct", value: data.correct },
            { name: "Incorrect", value: data.incorrect },
            { name: "Unattempted", value: data.unattempted },
          ]);
          loaders[4] = false;
          setLoaders(() => [...loaders]);
        });
    } catch (err) {
      console.log("Error in learning status report", err);
    }
  };

  async function loadAnalytics() {
    console.log(filterData);
    // setLoading(true)
    await fetchPracticeSessionDetails();
    await fetchProgressReport();
    await fetchTimeOnPractice();
    await fetchQuestionStatusReport();
    await fetchQuestionStatusReportPie();
    await fetchQuestionDifficultyStatus();
    await fetchQuestionCognitiveStatus();
    await fetchLearningStatus();
  }

  React.useEffect(() => {
    loadAnalytics();
  }, [filterData]);

  return (
    <>
      <SideBoard
        filterData={filterData}
        setFilterData={setFilterData}
      ></SideBoard>
      <section className="learningResources my-md-5 my-sm-1 p-lg-0 p-2 p-sm-2">
        <div className="container">
          {!isSmall ? (
            <div
              className="box mb-5 row"
              style={{ cursor: "pointer", alignItems: "center" }}
            >
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "fit-content",
                }}
              >
                <h2
                  className="fw-bold white_color"
                  style={{
                    fontWeight: "bolder",
                    color: "white",
                    width: "100% !important",
                  }}
                >
                  Analytics
                </h2>
                {/* <h1 style={{ fontWeight: 'bolder',color: 'white'}} >Analytics</h1> */}
              </span>
              <h5
                className="col-sm-3"
                style={{
                  marginLeft: "auto",
                  fontSize: "90%",
                  fontWeight: "bolder",
                  marginBottom: "auto",
                  textAlign: "right",
                }}
              >
                <CalendarTodayIcon style={{ width: "17px" }} />{" "}
                {getCurrentDate()}
              </h5>
              <p className=" white_color" style={{ color: "white" }}>
                Practice Assessment
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>

      <>
        <MiniCards data={practiceSessionDetails}> </MiniCards>
        <div className={classes.graphContainer}>
          <SubjectProgressReport
            data={progressReport}
            loading={loaders[1]}
          ></SubjectProgressReport>
          <TimeOnPractice
            data={timeOnPractice}
            loading={loaders[2]}
          ></TimeOnPractice>
          <QuestionStatusReport
            data={statusReport}
            loading={loaders[3]}
          ></QuestionStatusReport>
          <DifficultyPieChart
            data={statusReportPie}
            loading={loaders[4]}
          ></DifficultyPieChart>
          <DifficultyReport
            data={questionDifficultyStats}
            loading={loaders[5]}
          ></DifficultyReport>
          <CognitiveReport
            data={questionCognitiveStats}
            loading={loaders[6]}
          ></CognitiveReport>
          <LearningStrength
            data={learningStrength}
            loading={loaders[7]}
          ></LearningStrength>
        </div>
        <div className={classes.linkContainer}>
          <Link
            to={
              filterData.subjectId
                ? `/analytics/assessment/report/${filterData.subjectId}`
                : "#"
            }
          >
            View Report <TrendingFlatIcon />{" "}
          </Link>
        </div>
        <Table title="Practice Report of All Subjects"></Table>
      </>
    </>
  );
}

export default AssessmentAnalytics;

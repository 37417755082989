import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    container: {
        width: '41%',
        height: '73%',
        boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.25)",
        borderRadius: "17px",
        margin: "1rem 0px",
        border: "1px solid #B46B82"
    },
    headingContainer: {
        textAlign: "center",
        "& > *": {
            marginBlockStart: "0em",
            marginBlockEnd: "0.2em",
        },
        "& > h3": {
            fontSize: "0.9em",
            marginBlockStart: "10px"
        },
        "& > p": {
            fontSize: "0.7em",
        }
    },
    "@media screen and (max-width: 768px)": {
        container: {
            width: '80%',
            // height: '60%',
            borderRadius: '10px',
            margin: "10px auto",
            height: '400px',
            display:"flex",
            flexDirection:"column",
        },
        responsive:{
            flex:"1"
        }
    },
    "@media screen and (max-width: 472px)": {
        container: {
            width: '100%',
            height: '400px',
            display:"flex",
            flexDirection:"column",
            borderRadius: '10px',
            margin: "10px auto"
        },
        responsive:{
            flex:"1"
        }
        
    }

})

export default useStyles;
import React from 'react';
import { BarChart, Bar, Brush,Cell, XAxis, YAxis, CartesianGrid,Legend, Tooltip, Label,LabelList, ResponsiveContainer } from 'recharts';
import useStyles from '../../styles';
import { useMediaQuery } from 'react-responsive';
import {ReactComponent as Loader} from "../../../../../images/analytics/ChartLoader.svg"

export default function DifficultyReport ({data,loading}) {

    const isSmall = useMediaQuery({query:"(max-width: 472px)"})
    const classes = useStyles()    

    return (
        <div className={classes.container}> 
            <div className={classes.headingContainer}>
                <h3>Difficulty Report of All Subjects</h3>
                {/* <p>Total Questions Practiced- 500</p> */}
            </div>
            {loading?<Loader/>:
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                width={300}
                height={250}
                data={data}
                barGap={0}
                margin={{
                    top: 25,
                    right: isSmall?3:5,
                    left: isSmall? -7 : 15,
                    bottom: isSmall?20:80,
                }}
                >
                <CartesianGrid strokeDasharray="3 6" />
                <XAxis dataKey="Subject" allowDataOverflow={true}>
                    <Label value="Subjects" offset={isSmall?-25:-30} position="insideBottom"/>
                </XAxis>
                <Brush dataKey="Subject" height={10} endIndex={Math.min(Math.abs(data.length-1),4)} />
                <YAxis label={{ value: "Questions Practiced", angle: -90,offset: isSmall? 18:-2, position: 'insideLeft' }} />
                <Tooltip />
                    <Bar dataKey={"Easy"} fill="rgba(125, 230, 219, 1)">
                        <LabelList dataKey={"Easy"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(125, 230, 219, 1)" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Intermediate"} fill="rgba(255, 152, 152, 1)">
                        <LabelList dataKey={"Intermediate"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(255, 152, 152, 1)" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Difficult"} fill="rgba(120, 234, 165, 1)">
                        <LabelList dataKey={"Difficult"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(120, 234, 165, 1)" ></Cell> )}
                    </Bar>
                <Legend></Legend>
                </BarChart>
            </ResponsiveContainer>
                }
        </div>
    );
 }


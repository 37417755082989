import React from 'react';

const PractiseTest = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='46'
      height='61'
      viewBox='0 0 46 61'
      fill='none'
    >
      <path
        d='M19.1667 0C17.0496 0 15.3333 1.70691 15.3333 3.8125C15.3333 5.91809 17.0496 7.625 19.1667 7.625H26.8333C28.9504 7.625 30.6667 5.91809 30.6667 3.8125C30.6667 1.70691 28.9504 0 26.8333 0H19.1667Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 11.4375C0 7.22633 3.43248 3.8125 7.66667 3.8125C7.66667 10.1293 12.8154 15.25 19.1667 15.25H26.8333C33.1846 15.25 38.3333 10.1293 38.3333 3.8125C42.5675 3.8125 46 7.22633 46 11.4375V53.375C46 57.5862 42.5675 61 38.3333 61H7.66667C3.43248 61 0 57.5862 0 53.375V11.4375ZM11.5 26.6875C9.38291 26.6875 7.66667 28.3944 7.66667 30.5C7.66667 32.6056 9.38291 34.3125 11.5 34.3125H11.5383C13.6554 34.3125 15.3717 32.6056 15.3717 30.5C15.3717 28.3944 13.6554 26.6875 11.5383 26.6875H11.5ZM23 26.6875C20.8829 26.6875 19.1667 28.3944 19.1667 30.5C19.1667 32.6056 20.8829 34.3125 23 34.3125H34.5C36.6171 34.3125 38.3333 32.6056 38.3333 30.5C38.3333 28.3944 36.6171 26.6875 34.5 26.6875H23ZM11.5 41.9375C9.38291 41.9375 7.66667 43.6444 7.66667 45.75C7.66667 47.8556 9.38291 49.5625 11.5 49.5625H11.5383C13.6554 49.5625 15.3717 47.8556 15.3717 45.75C15.3717 43.6444 13.6554 41.9375 11.5383 41.9375H11.5ZM23 41.9375C20.8829 41.9375 19.1667 43.6444 19.1667 45.75C19.1667 47.8556 20.8829 49.5625 23 49.5625H34.5C36.6171 49.5625 38.3333 47.8556 38.3333 45.75C38.3333 43.6444 36.6171 41.9375 34.5 41.9375H23Z'
        fill='white'
      />
    </svg>
  );
};

export default PractiseTest;

import React,{useContext,useState,useEffect} from 'react'
import '../../../../LearningResources/styles/Assesment.css'
import './styles/TestList.css'
import {GlobalContext} from '../../../../../context/GlobalState'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import TestTable from './TestTable/TestTable'
import SearchBar from './SearchBar/SearchBar'
import TestFilter from './TestFilter/TestFilter'
import Buttons from './Buttons/Buttons'
import {useAlert} from 'react-alert'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {useMediaQuery} from 'react-responsive'
import exportFromJSON from 'export-from-json'
const formatTime = require("../../utils/FormatTime")

const getCurrentDate = ()=>{
    const date = new Date()
    const months = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    
    return `${date.getDate()} ${months[date.getMonth()]},${date.getFullYear()}`
  }

const convertFilterToQuery = (filterData) => {
    let query = "?"
    for(let key in filterData) {
        if(filterData[key]){
            query+=`${key}=${filterData[key]}&`
        }
    }
    return query.slice(0,-1)
} 

const convertArrayToString = (array) => {

    if(!array){
      return ""
    }
    array = array && array.map(item => item.name)
    return array.join(', ');
}


function Table({title,tableRowColor="rgba(242, 134, 132, 0.52)"}) {

    //Creating instances
    const params = useParams()
    const {userDetails} = useContext(GlobalContext);
    const alert = useAlert();
    const isSmall = useMediaQuery({query:"(max-width: 472px)"});
    const sectionId = window.localStorage.getItem('sectionId')
    const {subjectId} = useParams()

    //States
    const [tableData,setTableData] = useState([])

    const [subjects,setSubjects] = useState([])
    const [chapters,setChapters] = useState([])
    const [topics,setTopics] = useState([])
    const [testType,setTestType] = useState(["CT","UT","FT","HY"])
    const [filterData,setFilterData] = useState({
        chapter:'',
        chapterId:'',
        topics:'',
        topicId:params.topicId,
        'from[gte]':null,
        testType:''
    })
    const [searchValue,setSearchValue] = useState("")
    const [loading,setLoading] = useState(false)

    //function to fetch the subjects
    const getSubjects = async()=>{
        const response = await axios(`${process.env.REACT_APP_ORIGIN}/api/v1/grades/${userDetails.grade}/sections/${userDetails.section}/subjects`,{
            headers:{
                token:window.localStorage.getItem('authToken')
            }
        })
        console.log("Response of fetching subjects ====> ",response)
        if(response.data.status === "success"){
            setSubjects(response.data.data.subjects)
        }else{
            alert.error(response.message)
        }
    }

    //function to fetch the chapters
    const getChapters = async(subjectId)=>{
        const response = await axios(`${process.env.REACT_APP_ORIGIN}/api/v1/subjects/${subjectId}`,{
            headers:{
                token:window.localStorage.getItem('authToken')
            }
        })
        console.log("Response of fetching chapters ====> ",response)
        if(response.data.status === "success"){
            setChapters(response.data.data.subject.chapters)
        }else{
            alert.error(response.message)
        }
    }

    //function to fetch the topics
    const getTopics = async()=>{
        const response = await axios(`${process.env.REACT_APP_ORIGIN}/api/v1/chapters/${filterData.chapterId}`,{
            headers:{
                token:window.localStorage.getItem('authToken')
            }
        })
        console.log("Response of fetching topics ====> ",response)
        if(response.data.status === "success"){
            setTopics(response.data.data.chapter.topics)
        }else{
            alert.error(response.message)
        }
    }
    
    //fetch the assigned tests
    const getPracticeReport = async()=>{

        try{
            await fetch(`${process.env.REACT_APP_ORIGIN2}/api/v1/practiceanalytics/sections/${sectionId}/subjects/${subjectId}/students/${userDetails.userId}/questionreports` + convertFilterToQuery(filterData),{
                headers:{
                    token: window.localStorage.getItem('authToken')
                }
            }).then(response => response.json())
                .then((response)=>{
                    console.log("Question Practice Report ===> ",response)
                    setTableData(response.data.reports)
                })
        }catch(err) {
            console.log("Error in practice report",err)
        }
    }

    //function to export the data
    const exportQuestionDetails = async()=>{
        try{
            const data = tableData.map((item,index)=>({
                Number: index+1,
                Question: item.questionId.question,
                Subject: item.subjectId.name,
                Topic: item.topicId.name,
                Difficulty: item.difficulty,
                Cognitive: item.cognitive,
                Time_Taken: formatTime(item.timeTaken),
                Status: item.status === "passed"?"Correct":"Incorrect"
            }))
    
            const fileName = 'Question Details'
            const exportType =  exportFromJSON.types.csv
    
            exportFromJSON({ data, fileName, exportType })
        }catch(err){
            console.log("Error in exporting question details: ",err.message)
        }
    }

    //function Search through the data when search value is changed
    const search = () =>{
        let searchData = tableData.filter(test=>convertArrayToString(test.questionSet.chapters).toLowerCase().includes(searchValue.toLowerCase()) || convertArrayToString(test.questionSet.topics).toLowerCase().includes(searchValue.toLowerCase()))
        setTableData(searchData)
    }

    //Fetch the subjects when teh page loads 
    useEffect(() => {
        getPracticeReport()
        getSubjects()
    }, [])
    
    useEffect(() => {
        getPracticeReport()
    },[filterData])

    //Fetch the chapters when the subjectId changes
    useEffect(() => {
        console.log("Filter data ===>",filterData)
        getChapters(filterData.subjectId)
    },[filterData.subjectId])

    //Fetch the topics when the chapter id changes 
    useEffect(() => {
        getTopics()
    },[filterData.chapterId])

    //Call teh search function whenever the user types in search box
    useEffect(() =>{

        //when the search box is empty
        if(!searchValue){
            getPracticeReport()
        }else{
            search()
        }
    },[searchValue])

    return (
            <div className='container upperbox test_list_upper_box'>
                {/* <TestFilter                         <===== Filter in design not valid
                    filterData = {filterData}
                    setFilterData = {setFilterData}
                    subjects = {subjects}
                    chapters = {chapters}
                    topics = {topics}
                    testType = {testType}
                /> */}
                <div className="table_container">
                    <div className="search_container">
                        <h3>{title}</h3>
                        <Buttons exportQuestionDetails={exportQuestionDetails}></Buttons>
                    </div>
                    <TestTable rows = {tableData} loading={loading} rowColor={tableRowColor} ></TestTable>
                </div>
            </div>
    )
}

export default Table

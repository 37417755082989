const CORRECT_ANSWER_BACKGROUND = "linear-gradient(90deg, #FF675E 32.75%, #FFFFFF 96.35%)"
const INCORRECT_ANSWER_BACKGROUND = "linear-gradient(90deg, #7EB6A2 32.75%, #FFFFFF 96.35%)"
const UNSELECTED_ANSWER_BACKGROUND = "white"

const OptionColor = (question, optionId) => {


    try {

        switch (question.questionId.questionType) {
            case "mcq":
                if (optionId === question.questionId.correctAnswers.mcq) return CORRECT_ANSWER_BACKGROUND
                if (optionId === question.statistics[0].answer.mcq) return INCORRECT_ANSWER_BACKGROUND
                return UNSELECTED_ANSWER_BACKGROUND
            case "mmcq":
                if (question.questionId.correctAnswers.mmcq.includes(optionId)) return CORRECT_ANSWER_BACKGROUND
                if (question.statistics[0].answer.mmcq.includes(optionId) && !question.questionId.correctAnswers.mmcq.includes(optionId)) return INCORRECT_ANSWER_BACKGROUND
                return UNSELECTED_ANSWER_BACKGROUND
            default:
                return UNSELECTED_ANSWER_BACKGROUND
        }
    } catch (e) {
        console.log(e.message)
        return UNSELECTED_ANSWER_BACKGROUND
    }
}

module.exports = OptionColor
import React from 'react';
import PriorityHighIcon from '@material-ui/icons/PriorityHighOutlined';
import Triangle from '../../../images/assessment/Triangle';
import Success from '../../../images/assessment/Success';
import Hurray from '../../../images/assessment/Hurray.png'

const Popup = ({ modal, setModal,status,noQuestionsCreated,message=""}) => {

  const parseString = (str) => {
    return new DOMParser()
      .parseFromString(str, "text/html")
      .body.textContent.trimStart();
  }

  switch(status){
    case "correct":
        return (
              <section className='assesmentPopup' style={{ display: modal ? 'flex' : 'none' }}>
                  <div className='assesmentPopupCont'>
                    <div className='warning-popup'>
                      <Success />
                    {/* <div className='warning-symbol'><PriorityHighIcon /></div> */}
                  </div>
                  <p className='error-msg'>Correct</p>
                  {/* <p className='error-text'>Try Again</p> */}
                  <button className='save-btn popupClose' onClick={() => setModal((state) => !state)}>OKAY  </button>
                </div>
              </section>
      );
      
    case "incorrect":
        return (
              <section className='assesmentPopup' style={{ display: modal ? 'flex' : 'none' }}>
                  <div className='assesmentPopupCont'>
                    <div className='warning-popup'>
                      <Triangle />
                    <div className='warning-symbol'><PriorityHighIcon /></div>
                  </div>
                  <p className='error-msg'>Incorrect</p>
                  {message && <p className='error-text' dangerouslySetInnerHTML={{__html:parseString(message) || ''}} ></p>}
                  <button className='save-btn popupClose' onClick={() => setModal((state) => !state)}>OKAY  </button>
                </div>
              </section>
      );
      
    case "error":
        return (
              <section className='assesmentPopup' style={{ display: modal ? 'flex' : 'none' }}>
                  <div className='assesmentPopupCont'>
                    <div className='warning-popup'>
                      <Triangle />
                    <div className='warning-symbol'><PriorityHighIcon /></div>
                  </div>
                  <p className='error-msg'>{message}</p>
                  <p className='error-text'>Try Again</p>
                  <button className='save-btn popupClose' onClick={() => setModal((state) => !state)}>OKAY  </button>
                </div>
              </section>
      );
    case "completed":
        return (
              <section className='assesmentPopup' style={{ display: modal ? 'flex' : 'none' }}>
                  <div className='assesmentPopupCont'>
                    <div className='warning-popup'>
                      {noQuestionsCreated?
                        <>
                          <Triangle></Triangle>
                          <div className='warning-symbol'><PriorityHighIcon /></div>
                        </>
                        :<img src={Hurray} style={{width:"105px",height:"100px"}} alt=""/>}
                  </div>
                  <p className='error-msg'>{message}</p>
                  <button className='save-btn popupClose' onClick={() => setModal((state) => !state)}>OKAY </button>
                </div>
              </section>
      );
    case "auto-submitted":
        return (
              <section className='assesmentPopup' style={{ display: modal ? 'flex' : 'none' }}>
                  <div className='assesmentPopupCont'>
                  <p className='error-msg'>
                    Your test has been automatically submitted.<br/>
                    You will be redirected in few seconds.
                  </p>
                  {/* <button className='save-btn popupClose' onClick={() => setModal((state) => !state)}>OKAY </button> */}
                </div>
              </section>
      );
    default:
        return (<></>)

  }



};

export default Popup;

import React from "react";
import { Col, Row } from "react-bootstrap";
import Stat from "../common/Stat";
import StatBar from "../common/StatBar";
import "../QuestionReport.css";
import IsSmallScreen from "../common/IsSmallScreen";

import { htmlDecode } from "../../../../../../utils/parse";

function OneWord(props) {
 
  const isSmallScreen = IsSmallScreen;
  const getPercent = () => {
    let an = props?.crctAnswer[0]?.answer?.toLowerCase();
    let total = 0;
    let crct = 0;
    for (let key in props.stats) {
      if (key?.toLocaleLowerCase() === an) {
        crct += props.stats?.[key];
      }
      total += props.stats?.[key];
    }
    return (crct / total) * 100;
  };

  return (
    <Row className="analytics_question_layer">
      <Col className="analytics_question_content">
        <Row className="analytics_question_heading">
          <p>Question {props?.no}</p>
          <p className="analytics_question_heading_card">2 Marks</p>
          <p className="analytics_question_heading_card">
            {props.difficulty.charAt(0).toUpperCase() +
              props?.difficulty.split("").slice(1).join("")}
          </p>
        </Row>
        <Row
          className="analytics_question_statement"
          dangerouslySetInnerHTML={{ __html: htmlDecode(props?.question) }}
        />
        {/* {props?.question} */}
        {/* </Row> */}
        <Row>
          <Row
            className="analytics_question_fill_in_the_blank"
            style={{
              color: `${
                props?.userAnswer?.toLowerCase() ===
                props?.crctAnswer[0]?.answer?.toLowerCase()
                  ? "#47DE85"
                  : "#FA582E"
              }`,
            }}
          >
            <u>{props?.userAnswer}</u>
          </Row>
        </Row>
        <Row
          className="correct_answer_fill_in_the_blank"
          style={{ marginBottom: isSmallScreen ? 5 : 0 }}
        >
          Correct Answer:{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: htmlDecode(props?.crctAnswer[0]?.answer),
            }}
          />
        </Row>
      </Col>
      <Col className="analytics_question_stats">
        <Stat>
          <StatBar style={{ width: 180, backgroundColor: "#7EB6A2" }} />{" "}
          {getPercent()}%
        </Stat>
      </Col>
    </Row>
  );
}

export default OneWord;

import React,{ useEffect} from 'react';
import { head, input, remarkStyle, signature } from '../../helper';
import './mmcqCard.css';
import DefaultImage from '../../../../../images/Navbar/title.png'
import { htmlDecode } from '../../../../../utils/parse';


const parseData = (id) => JSON.parse(localStorage.getItem(id))

export default function MmcqCard({ color = '',data,number,correctAnswer,setCorrectAnswer}) {

  const question = data
  
  const handleClick = (e)=>{
    if(correctAnswer.includes(e.target.value)){
      correctAnswer = correctAnswer.filter(answer=>answer!==e.target.value);
      setCorrectAnswer(correctAnswer)
    }else{
      setCorrectAnswer([...correctAnswer,e.target.value])
    }
  }
  
  //Uncheck all the options when a new question is rendered
  useEffect(()=>{
    setCorrectAnswer([])    
    const radios = document.querySelectorAll(".mmcq_radio")
    radios.forEach((radio)=>{
      radio.checked = false
    })
  },[number])

  return (
    <>
      <div className='container question_card my-4'>
        <div className='superhead'>
          <div className='remark' style={remarkStyle(color)}>Select Multiple Options</div>
          <div className='mmcq__head' style={head(color)}>
            <div className='mmcqCard'>
              <div className='mmcqCard__left'><h6 style={{fontWeight:"bolder",marginTop:"5px"}} dangerouslySetInnerHTML={{__html:htmlDecode(`Q${number}. ${question?.question}`)}}></h6></div>
            </div>
            <div className='main_div'>
              <div className='question_image'>
              {question?.images?.questionImg ? (<img className='q-img' alt='question' src={question?.images?.questionImg} />) : null}
              </div>
              <div className='mmcq' style={question?.images?.op1 ? { width: '70%' } : { width: '80%' }} >
                <div className='up top-left'>
                  <label className='opt1-container' style={input(color)}>
                    <span dangerouslySetInnerHTML={{__html:question?.options[0]?.value || "-"}}></span>
                    <input type='checkbox' value={question?.options[0]?.optionId} className='mmcq_radio' onClick={handleClick} name='radio' />
                    
                    <span className='checkmark' style={input(color, 3)}></span>
                    <div className='imgBlock' style={ question?.images?.op1? {background: `url(${question?.images?.op1}) no-repeat center center`,backgroundSize: 'cover',}: { display: 'none' }}></div>
                    {/* <div className="imgBlock" style={{background:`url(${img2}) no-repeat center center`,backgroundSize: "cover"}}></div> */}
                    {/* {img2? <img className="ans_mcq_image" src={img2}/> : null} */}
                  </label>
                </div>
                <div className='up top-right ml-6'>
                  <label className='opt1-container' style={input(color)}>
                    <span dangerouslySetInnerHTML={{__html:question?.options[1]?.value || "-"}}></span>
                    <input type='checkbox' value={question?.options[1]?.optionId} className='mmcq_radio' onClick={handleClick} name='radio' />
                    <span className='checkmark' style={input(color, 3)}></span>
                    <div className='imgBlock' style={ question?.images?.op2? {background: `url(${question?.images?.op2}) no-repeat center center`,backgroundSize: 'cover',}: { display: 'none' }}></div>
                    {/* <div className="imgBlock" style={{background:`url(${img2}) no-repeat center center`,backgroundSize: "cover"}}></div> */}
                    {/* {img2? <img className="ans_mcq_image" src={img2}/> : null} */}
                  </label>
                </div>
                <div className='up bottom-left'>
                  <label className='opt1-container' style={input(color)}>
                    <span dangerouslySetInnerHTML={{__html:question?.options[2]?.value || "-"}}></span>
                    <input type='checkbox' value={question?.options[2]?.optionId} className='mmcq_radio' onClick={handleClick} name='radio' />
                    <span className='checkmark' style={input(color, 3)}></span>
                    <div className='imgBlock' style={question?.images?.op3? {background: `url(${question?.images?.op3}) no-repeat center center`,backgroundSize: 'cover',}: { display: 'none' }}></div>
                    {/* <div className="imgBlock" style={{background:`url(${img2}) no-repeat center center`,backgroundSize: "cover"}}></div> */}
                    {/* {img2? <img className="ans_mcq_image" src={img2}/> : null} */}
                  </label>
                </div>
                <div className='up bottom-right ml-6'>
                  <label className='opt1-container' style={input(color)}>
                    <span dangerouslySetInnerHTML={{__html:question?.options[3]?.value || "-"}}></span>
                    <input type='checkbox' value={question?.options[3]?.optionId} className='mmcq_radio' onClick={handleClick} name='radio' /><span className='checkmark' style={input(color, 3)}></span>
                    <div className='imgBlock' style={question?.images?.op4? {background: `url(${question?.images?.op4}) no-repeat center center`,backgroundSize: 'cover',}: { display: 'none' }}></div>
                    {/* <div className="imgBlock" style={{background:`url(${img2}) no-repeat center center`,backgroundSize: "cover"}}></div> */}
                    {/* {img2? <img className="ans_mcq_image" src={img2}/> : null} */}
                  </label>
                </div>
              </div>
            </div>
            <div className='last'>
              {question.onModel==="admin"?<img src={DefaultImage} className='signarture' style={{...signature(color),width:'50px'}} />:<div className='signarture' style={signature(color)}>{question.creatorId?question.creatorId.name:""}<br />{question.schoolId.name}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

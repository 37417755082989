export const data = [
  {
    type: 'mmcq',
  },
  {
    type: 'correctstatement',
  },
  {
    type: 'fill',
  },
];

export const remarkStyle = (color) => ({
  ...(color && { background: color }),
});

export const head = (color) => ({
  ...(color && { borderLeft: `11px solid ${color}` }),
});

export const input = (color, border = 1) => ({
  ...(color && { border: `${border}px solid ${color}` }),
});

export const button = (color, border = 1) => ({
  ...(color && { border: `${border}px solid ${color}` }),
  ...(color && { background: color }),
});

export const signature = (color) => ({
  ...(color && { borderBottom: `3px solid ${color}` }),
});

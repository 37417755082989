import React from 'react';
import SelectSubject from './SelectSubject/SelectSubject';
import './styles/LearningResources.css';
// import TablePagination from '@material-ui/core/TablePagination';
// import { useParams } from 'react-router-dom';
// import SingleChapter from './Chapter/SingleChapter';
// import axios from 'axios';
// import { useAlert } from 'react-alert';
// import SelectSubjectModal from './SelectSubjectModal/SelectSubjectModal';

// const LearningResourcesStyle = (url) => ({
//   backgroundImage: `url(${
//     url ? url : process.env.PUBLIC_URL + '/backgrounds/revisionTask/nature.png'
//   })`,
//   backgroundSize: 'cover',
//   minHeight: '245px',
//   height: '100%',
//   borderBottomRightRadius: '17px',
//   borderBottomLeftRadius: '17px',
// });

// const CardStyle = {
//   background: 'linear-gradient(0deg,#000000a6,transparent)',
//   height: '100%',
//   borderRadius: '17px',
//   color: 'white',
// };

const LearningResources = () => {
  document.title = '2xcell.in';
  // const grade = window.localStorage.getItem('grade');
  // const section = window.localStorage.getItem('section');

  // const [page, setPage] = useState(2);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [openModal, setOpenModal] = useState(false);
  // const [selectedSubject, setSelectedSubject] = useState(null);
  // const [subjectId, setSubjectId] = useState(null);

  // const [fetched, setFectehd] = useState(false);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // fetching chapters using subect ID
  // loading for chapters
  // const [loaderChapter, setLoaderChapter] = useState(false);
  // const [chaptersList, setChapterList] = useState([]);

  //States and Function to get the subjects
  //that will be displayed in the popup
  // const [allSubjects, setAllSubjects] = useState([]);
  // const getAllSubjects = async () => {
  //   await fetch(
  //     `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/subjects`,
  //     {
  //       headers: {
  //         token: `${window.localStorage.getItem('authToken')}`,
  //       },
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((response) => {
  //       setAllSubjects(response.data.subjects);
  //     });
  // };

  // useEffect(() => {
  //   getAllSubjects();
  // }, []);

  //Function to get the chapters of selected subject
  // const getChapters = async () => {
  //   setLoaderChapter(true);
  //   await fetch(
  //     `${process.env.REACT_APP_ORIGIN}/api/v1/subjects/${subjectId}`,
  //     {
  //       headers: {
  //         token: `${window.localStorage.getItem('authToken')}`,
  //       },
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((response) => {
  //       console.log();
  //       setChapterList(response.data.subject.chapters);
  //       setLoaderChapter(false);
  //       setFectehd(true);
  //     });
  // };

  // useEffect(() => {
  //   if (subjectId) {
  //     getChapters();
  //   }
  // }, [subjectId]);

  // useEffect(() => {
  //   const authToken = localStorage.getItem("authToken");
  //   const apiForChpaters = ``

  //   setLoaderChapter(true);
  //   axios.get(apiForChpaters,
  //     {
  //       headers: {
  //         token: authToken
  //       }
  //     })
  //     .then((resp) => {
  //       console.log(resp);
  //       setChapterList(resp.data.data.subject.chapters);
  //       setLoaderChapter(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       alert.error("Chapters not fetched");
  //       setLoaderChapter(false);
  //     })
  // }, []);

  return (
    <>
      <SelectSubject />
    </>
  );
};

export default LearningResources;

// eslint-disable-next-line
{
  /* <section className='learningResources my-md-5 my-sm-1 p-lg-0 p-2 p-sm-2'>
        <div className='container'>
          <div
            className='box mb-5'
            style={{ cursor: 'pointer' }}
            // onClick={() => {
            //   setOpenModal((modal) => !modal);
            // }}
          >
            <h1
              className='fw-bold'
              onClick={() => {
                setOpenModal((modal) => !modal);
              }}
            >
              {selectedSubject || 'Subject'}
            </h1>
            {selectedSubject || 'Subject not selected'}
          </div>
          <SelectSubjectModal
            open={openModal}
            toggle={setOpenModal}
            setSubjectId={setSubjectId}
            allSubjects={allSubjects}
            setSelectedSubject={setSelectedSubject}
          ></SelectSubjectModal>
          {/* <div>
            <TablePagination
              component="div"
              count={100}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div> */
  // }
  // {
  /* 
          <div className='row my-3 gy-4'>
            {/* displaying fetch chapters */
}
// {
/* {chaptersList.length === 0 ? (
              <h1 className='text-center'>
                {selectedSubject
                  ? fetched
                    ? '0 Chapter Found'
                    : 'Loading...'
                  : 'Select a subject'}
              </h1>
            ) : (
              chaptersList.map((val, i) => {
                return (
                  <SingleChapter
                    key={i}
                    name={val.name}
                    chapterId={val._id}
                    selectedSubject={selectedSubject}
                    subjectId={subjectId}
                    num={val.totalTopics}
                    thumbnail={val.thumbnail}
                  ></SingleChapter>
                );
              })
            )}
          </div>
        </div> */
// }
// {
/* </section>  */
// }

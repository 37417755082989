export const OnlineTest=[
    {
        id:"sno",
        align:"center",
        label:"No",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"startDate",
        align:"center",
        label:"Start Date",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"endDate",
        align:"center",
        label:"End Date",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"subject",
        align:"center",
        label:"Subject",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"chapter",
        align:"center",
        label:"Chapter",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"topic",
        align:"center",
        label:"Topic",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"testType",
        align:"center",
        label:"Test Type",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"date",
        align:"center",
        label:"Date",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"startTime",
        align:"center",
        label:"Start Time of Test",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none",
        minWidth:"150px"
    },
    {
        id:"timeSpent",
        align:"center",
        label:"Time Spent",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        minWidth:"150px",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"marks",
        align:"center",
        label:"Marks",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"marksObtained",
        align:"center",
        label:"Marks Obtained",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"classAverage",
        align:"center",
        label:"Class Average",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"correct",
        align:"center",
        label:"Correct",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"inCorrect",
        align:"center",
        label:"Incorrect",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"easy",
        align:"center",
        label:"Easy",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"medium",
        align:"center",
        label:"Medium",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"difficult",
        align:"center",
        label:"Difficult",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"knowledge",
        align:"center",
        label:"Knowledge",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"understanding",
        align:"center",
        label:"Understanding",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"analysis",
        align:"center",
        label:"Analysis",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"application",
        align:"center",
        label:"Application",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
]


export const QuestionsReportHeader=[
    {
        id:"no",
        align:"center",
        label:"No.",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"grade",
        align:"center",
        label:"Grade",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"subject",
        align:"center",
        label:"Subject",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    }, {
        id:"qno",
        align:"center",
        label:"Question No.",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    }, {
        id:"questionText",
        align:"center",
        label:"Question",
        backgroundColor:"transparent",
        color:"#000",
        minWidth:"300px",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    }, {
        id:"chapter",
        align:"center",
        label:"Chapter",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    }, {
        id:"topic",
        align:"center",
        label:"Topic",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        minWidth:"200px",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"difficultyLevel",
        align:"center",
        label:"Difficulty Level",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"cognitive",
        align:"center",
        label:"Cognitive",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"correct",
        align:"center",
        label:"Correct/Incorrect",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    // {
    //     id:"marks",
    //     align:"center",
    //     label:"Marks",
    //     backgroundColor:"transparent",
    //     color:"#000",
    //     fontWeight:"600",
    //     fontSize:"13px",
    //     borderBottom:"none"
    // },
    // {
    //     id:"marksObtained",
    //     align:"center",
    //     label:"Marks Obtained",
    //     backgroundColor:"transparent",
    //     color:"#000",
    //     fontWeight:"600",
    //     fontSize:"13px",
    //     borderBottom:"none"
    // },
     {
        id:"timeSpent",
        align:"center",
        label:"Time Spent",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"date",
        align:"center",
        label:"Date",
        backgroundColor:"transparent",
        color:"#000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },

]

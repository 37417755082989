import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import axios from 'axios';
import { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import './styles/FlippedClass.css';

// const FlippedClassStyle = {
//   backgroundImage: `url(${
//     process.env.PUBLIC_URL + '/backgrounds/revisionTask/nature.png'
//   })`,

//   backgroundSize: 'cover',
//   minHeight: '245px',
//   height: '100%',
//   borderBottomRightRadius: '17px',
//   borderBottomLeftRadius: '17px',
// };

// const CardStyle = {
//   background: 'linear-gradient(0deg,#000000a6,transparent)',
//   height: '100%',
//   borderRadius: '17px',
//   color: 'white',
// };

const FlippedClass = () => {
  const alert = useAlert();
  const [task, getTaskData] = useState([]);
  const { grade, section, authToken } = useContext(GlobalContext).userDetails;
  const history = useHistory();
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    try {
      const getRevisionTask = async () => {
        const startDate = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000).toISOString();
        //const date = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()).toISOString()
        const taskData = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/tasks?taskType=flipped class&limit=15&from[gte]=${startDate}&sort=-createdAt`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (taskData.data.status === 'success') {
          let { tasks } = taskData.data.data;
          getTaskData([...tasks]);
          setFetched(true);
        }
      };

      getRevisionTask();
    } catch (e) {
      alert.error('Not able to fetch tasks, Please refresh');
      console.log(e);
    }
  }, [grade, section, authToken, alert]);
  return (
    <>
      <div className='row my-3 gy-4'>
        {task.length ? (
          task.map((e, idx) => (
            // <div
            //   className='col-sm-3'
            // key={`${e.subject}${idx}`}
            // onClick={() => history.push(`/tasklist/topic/${e._id}`)}
            // >
            //   <div className='text-center' style={FlippedClassStyle}>
            //     <div className='cardData' style={CardStyle}>
            //       <p>{e.subject}</p>
            //       <p>{e.content?.name}</p>
            //     </div>
            //   </div>
            // </div>
            <div
              className='col-sm-2 col-6'
              key={`${e.subject}${idx}`}
              onClick={() => history.push(`/tasklist/topic/${e._id}`)}
            >
              <div className='card card-height card-radius'>
                <div className='card-img'>
                  <img
                    src={ e?.content?.thumbnail || process.env.PUBLIC_URL + '/backgrounds/revisionTask/nature.png'}
                    className='card-thumbnail'
                    alt={e?.subject || 'sub'}
                  />
                </div>
                <div className='text-center text-dark'>
                  <p className='mar-p'>{e?.subject}</p>
                  <p>{e?.content?.name}</p>
                  <span style={{fontSize:"0.7rem",margin:"auto"}}>{new Date(e.from).toDateString()}</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h5 className='text-center'>
            {fetched ? 'No flipped task assigned' : 'Loading...'}
          </h5>
        )}
      </div>
    </>
  );
};

export default FlippedClass;

import React from 'react';

const Clock = () => {
  return (
    <svg
      width='104'
      height='104'
      viewBox='0 0 104 104'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_13:5739)'>
        <path
          d='M52 95.6974C78.5097 95.6974 100 74.2748 100 47.8487C100 21.4226 78.5097 0 52 0C25.4903 0 4 21.4226 4 47.8487C4 74.2748 25.4903 95.6974 52 95.6974Z'
          fill='#FF675E'
        />
        <path
          opacity='0.55'
          d='M52.0001 91.3359C76.0933 91.3359 95.6248 71.866 95.6248 47.8488C95.6248 23.8315 76.0933 4.36163 52.0001 4.36163C27.9069 4.36163 8.37549 23.8315 8.37549 47.8488C8.37549 71.866 27.9069 91.3359 52.0001 91.3359Z'
          fill='white'
        />
        <path
          d='M51.9999 86.8976C73.6342 86.8976 91.1722 69.4148 91.1722 47.8488C91.1722 26.2827 73.6342 8.79993 51.9999 8.79993C30.3657 8.79993 12.8276 26.2827 12.8276 47.8488C12.8276 69.4148 30.3657 86.8976 51.9999 86.8976Z'
          fill='white'
        />
        <path
          d='M51.9723 17.6212H51.8954C51.3323 17.6212 50.877 17.1672 50.877 16.6059V11.5236C50.877 10.9623 51.3323 10.5084 51.8954 10.5084H51.9723C52.5354 10.5084 52.9908 10.9623 52.9908 11.5236V16.6059C52.9908 17.1672 52.5354 17.6212 51.9723 17.6212Z'
          fill='#F28684'
        />
        <path
          d='M52.1047 85.1891H52.0278C51.4647 85.1891 51.0093 84.7352 51.0093 84.1739V79.0914C51.0093 78.5301 51.4647 78.0762 52.0278 78.0762H52.1047C52.6678 78.0762 53.1232 78.5301 53.1232 79.0914V84.1739C53.1232 84.7352 52.6678 85.1891 52.1047 85.1891Z'
          fill='#F28684'
        />
        <path
          d='M82.3232 47.8211V47.7444C82.3232 47.1831 82.7786 46.7291 83.3417 46.7291H88.4402C89.0032 46.7291 89.4586 47.1831 89.4586 47.7444V47.8211C89.4586 48.3824 89.0032 48.8363 88.4402 48.8363H83.3417C82.7786 48.8363 82.3232 48.3824 82.3232 47.8211Z'
          fill='#F28684'
        />
        <path
          d='M14.5415 47.9487V47.8721C14.5415 47.3108 14.9969 46.8568 15.56 46.8568H20.6584C21.2215 46.8568 21.6769 47.3108 21.6769 47.8721V47.9487C21.6769 48.5101 21.2215 48.964 20.6584 48.964H15.56C14.9969 48.964 14.5415 48.5101 14.5415 47.9487Z'
          fill='#F28684'
        />
        <path
          d='M74.378 25.504L74.3226 25.4488C73.9257 25.0531 73.9257 24.409 74.3226 24.0133L76.1872 22.1546C76.5841 21.7589 77.2303 21.7589 77.6272 22.1546L77.6826 22.2098C78.0795 22.6055 78.0795 23.2496 77.6826 23.6453L75.818 25.504C75.418 25.8997 74.7749 25.8997 74.378 25.504Z'
          fill='#F28684'
        />
        <path
          d='M26.3443 73.5735L26.2889 73.5183C25.892 73.1226 25.892 72.4785 26.2889 72.0829L28.5812 69.7978C28.9782 69.4021 29.6243 69.4021 30.0212 69.7978L30.0766 69.853C30.4736 70.2487 30.4736 70.8928 30.0766 71.2885L27.7843 73.5735C27.3843 73.9692 26.7412 73.9692 26.3443 73.5735Z'
          fill='#F28684'
        />
        <path
          d='M74.4154 70.1873L74.4708 70.1321C74.8677 69.7364 75.5138 69.7364 75.9108 70.1321L77.7754 71.9908C78.1723 72.3865 78.1723 73.0306 77.7754 73.4263L77.72 73.4815C77.3231 73.8771 76.6769 73.8771 76.28 73.4815L74.4154 71.6227C74.0184 71.224 74.0184 70.583 74.4154 70.1873Z'
          fill='#F28684'
        />
        <path
          d='M26.1937 22.3048L26.2491 22.2496C26.646 21.854 27.2921 21.854 27.6891 22.2496L29.9814 24.5347C30.3783 24.9304 30.3783 25.5745 29.9814 25.9702L29.926 26.0254C29.5291 26.4211 28.8829 26.4211 28.486 26.0254L26.1937 23.7403C25.7968 23.3446 25.7968 22.7005 26.1937 22.3048Z'
          fill='#F28684'
        />
        <path
          d='M52.0276 48.453H51.9414C51.3199 48.453 50.8184 47.9499 50.8184 47.3334V22.8141C50.8184 22.1945 51.323 21.6945 51.9414 21.6945H52.0276C52.6491 21.6945 53.1506 22.1975 53.1506 22.8141V47.3334C53.1537 47.9499 52.6491 48.453 52.0276 48.453Z'
          fill='#F38C88'
        />
        <path
          d='M50.8184 49.367V49.2811C50.8184 48.6615 51.323 48.1616 51.9414 48.1616H68.9507C69.5722 48.1616 70.0737 48.6646 70.0737 49.2811V49.367C70.0737 49.9866 69.5691 50.4866 68.9507 50.4866H51.9414C51.3199 50.4896 50.8184 49.9866 50.8184 49.367Z'
          fill='#F28785'
        />
        <path
          d='M69.64 32.0189L52.1323 47.7261'
          stroke='#F28684'
          strokeWidth='2'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M51.9998 52.0662C54.3364 52.0662 56.2306 50.178 56.2306 47.8488C56.2306 45.5196 54.3364 43.6313 51.9998 43.6313C49.6632 43.6313 47.769 45.5196 47.769 47.8488C47.769 50.178 49.6632 52.0662 51.9998 52.0662Z'
          fill='#F28684'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_13:5739'
          x='0'
          y='0'
          width='104'
          height='103.697'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_13:5739'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_13:5739'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Clock;

import React from 'react';

const OnlineTest = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='72'
      height='72'
      viewBox='0 0 72 72'
      fill='none'
    >
      <path
        d='M37.7726 0.363848C36.6407 -0.121283 35.3593 -0.121283 34.2274 0.363848L2.72736 13.8639C1.07279 14.573 0 16.1999 0 18C0 19.8002 1.07279 21.4271 2.72736 22.1362L14.6267 27.2359C15.0594 26.7456 15.6018 26.3463 16.2274 26.0782L34.2274 18.3639C36.5117 17.3849 39.1572 18.4431 40.1362 20.7275C41.1152 23.0118 40.057 25.6573 37.7726 26.6363L25.5001 31.8959L34.2274 35.6362C35.3593 36.1213 36.6407 36.1213 37.7726 35.6362L69.2726 22.1362C70.9272 21.4271 72 19.8002 72 18C72 16.1999 70.9272 14.573 69.2726 13.8639L37.7726 0.363848Z'
        fill='white'
      />
      <path
        d='M5.8952 33.2853L13.5 36.5445V55.002C11.9575 54.6521 10.3817 54.3904 8.77819 54.2223C6.66422 54.0006 4.99363 52.33 4.77198 50.216C4.59208 48.5003 4.5 46.7598 4.5 44.9993C4.5 40.9649 4.98335 37.0418 5.8952 33.2853Z'
        fill='white'
      />
      <path
        d='M32.8498 65.5764C29.8219 62.608 26.3325 60.1129 22.5 58.2081V40.4017L30.6821 43.9083C34.078 45.3637 37.922 45.3637 41.3179 43.9083L66.1048 33.2853C67.0167 37.0418 67.5 40.9649 67.5 44.9993C67.5 46.7598 67.4079 48.5003 67.228 50.216C67.0064 52.33 65.3358 54.0006 63.2218 54.2223C53.8941 55.2003 45.5057 59.3459 39.1502 65.5764C37.4004 67.2918 34.5996 67.2918 32.8498 65.5764Z'
        fill='white'
      />
      <path
        d='M18 72C20.4853 72 22.5 69.9853 22.5 67.5V58.2081C19.6711 56.8021 16.6553 55.7177 13.5 55.002V67.5C13.5 69.9853 15.5147 72 18 72Z'
        fill='white'
      />
    </svg>
  );
};

export default OnlineTest;

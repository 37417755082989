import React, { useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import "../../../LearningResources/styles/Assesment.css";
import "./TestCharts.css";
import { makeStyles } from "@material-ui/core/styles";
import MiniCards from "./MiniCards/MiniCards";
import { useHistory } from "react-router-dom";
import Table from "./Table/Table";
import TimePerSubject from "./Charts/Time_Per_Subject/Time_Per_Subject";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TestStatusPieChart from "./Charts/Test_Status_Pie_Chart/Test_Status_Pie_Chart";
import TestStatus from "./Charts/Test_Status/Test_Status";
import QuestionStatusReport from "./Charts/Question_Status_Report/Question_Status_Report";
import DifficultyReport from "./Charts/Difficulty_Report/Difficulty_Report";
import CognitiveReport from "./Charts/Cognitive_Report/Cognitive_Report";
import LearningStrength from "./Charts/Learning_Strength/Learning_Strength";
import DifficultyPieChart from "./Charts/Difficulty_Report_Pie_Chart/Difficulty_Report_Pie_Chart";
import {ReactComponent as ExportIcon} from "../../../../images/analytics/Export.svg"
import SideBoard from "../../SideBoard/SideBoard";
import Grid from '@material-ui/core/Grid';
// import { Link } from "react-router-dom";
import Buttons from "../../Table/Buttons/Buttons";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import { overAllAnalytics,FilterBySubject,FilterByChapter,FilterByTopic } from "../../../../Api/OnlineTest";
import { GlobalContext } from "../../../../context/GlobalState";
import DifficultyChart from "./Charts/Difficulty_Report/DifficultyChart";
import exportFromJSON from "export-from-json";
import { OnlineTest } from "./Table2/TableHeader";
import TableComponent from "./Table2/OnlineTestTable";
import TestFilter from '../TestFilter/TestFilter';
import {FilterComponent,FilterComponentt} from '../TestCharts/Table2/Sidebar/DropDown';
import axios from "axios";


const useStyles = makeStyles({
  graphContainer: {
    width: "100%",
    height: "500px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  linkContainer: {
    marginTop: "1100px",
    width: "100%",
    textAlign: "center",
    "& a": {
      background: "rgba(86, 229, 143, 0.9)",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "5px",
      color: "black",
      textDecoration: "none",
      padding: "5px",
    },
  },
  "@media screen and (max-width: 768px)": {
    graphContainer: {
      flexDirection: "column",
      height: "100%",
      width: "100%",
      marginTop: "1rem",
      "& > *": {
        marginBottom: "1rem",
      },
    },
    linkContainer:{
      marginTop:"0px"
    },
    filterDiv:{
      marginLeft:"0px !important" 
    }

  },
  "@media screen and (max-width: 472px)": {
    graphContainer: {
      flexDirection: "column",
      height: "auto",
      width: "100%",
      marginTop: "1rem",
      "& > *": {
        marginBottom: "1rem",
      },
    },
    table:{
      maxWidth:"100vw"
    }
  },
});

const getHours = (data) => {
  const [hr, min] = data.split(".");

  return `${hr} hrs ${parseFloat((min * 60) / 100).toFixed(0)} min `;
};
const getMin = (data) => {
  const second = Math.ceil(data);
  let s = second % 60;
  let m = Math.floor(second / 60);
  return `${m} min ${s} second`;
};

const getLabel=(stAg,clsAvg,testAttempt,testsAssign,attemptQuestions,questions,timeSpent,avgTime,concepts,chapters)=> [
  { heading: "Student Average Score", value: stAg||"0" },
  { heading: "Class Average", value: clsAvg||"0" },
  {
    heading: "Test Assigned",
    value: `${testAttempt||"0"}/${testsAssign||"0"}`,
  },
  {
    heading: "Questions Attempted",
    value: `${attemptQuestions||"0"}/${questions||"0"}`,
  },
  {
    heading: "Time Spent on Test",
    value: `${getHours(
      parseFloat((timeSpent||0) / (1000 * 60 * 60)).toFixed(2)
    )}`,
  },
  {
    heading: "Avg Time Spent On Each Question",
    value: `${getMin(
      parseFloat((avgTime||0) / 1000).toFixed(2)
    )}`,
  },
  { heading: "Concept Covered in Test", value: concepts||"0" },
  { heading: "Chapters Covered", value: chapters||"0" }
];

const getCurrentDate = () => {
  const date = Date().slice(0, 15).split(" ");
  return date[0] + "," + date[1] + " " + date[2] + "," + date[3];
};

function TestCharts() {
  const isSmall = useMediaQuery({ query: "(max-width: 472px)" });
  const classes = useStyles();
  // const {userDetails} = useContext(GlobalContext);
  const [overAllData, setOverAllData] = React.useState(getLabel());
  const [timeSpent, setTimeSpent] = React.useState([]);
  const [testStatus, setTestStatus] = React.useState([
    { name: "Completed", value: 0 },
    { name: "Pending", value: 0 },
  ]);
  const [questionAttemptStatus, setQuestionAttemptStatus] = React.useState([
    { name: "Correct", value: 0 },
    { name: "Incorrect", value: 0 },
    { name: "Unattempted", value: 0 },
  ]);
  const [questionStatus, setQuestionStatus] = React.useState([]);
  const [testStatusGraph, setTestStatusGraph] = React.useState([]);
  const [difficultyChart, setDifficulty] = React.useState([]);
  const [subjectData,setSubjectData]=React.useState([]);
  const [cognitiveChart, setCognitveChart] = React.useState([]);
  const [learningStatus, setLearningStatus] = React.useState([]);
  const [tabContent, setTabContent] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [tabContent2,setTabContent2]=React.useState([]);
  const [testAssign,setTestAssign]=React.useState(0);
  const [disabled,setDisabled]=React.useState(false);
  const history=useHistory();
  const [headerData,setHeaderData]=React.useState({subject:"",chapter:"",topic:""})
  const activeFilter=React.useRef("subjectId");
  const [loaders,setLoaders]=React.useState([true,true,true,true,true,true,true,true]);
console.log(testStatusGraph);
  const [filterData, setFilterData] = React.useState({
    subjectId: "",
    chapterId: "",
    topicId: "",
  });
  const [formData,setFormData]=React.useState({startDate:"",endDate:"",subject:""})
  
//   console.log(filterData);


const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
  const getOverAllData = async() => {
    let str;
    if(filterData.chapterId)
    {
      str=FilterByChapter.getData(filterData.subjectId,filterData.chapterId)
    }
    else if(filterData.subjectId)
    {
      str=FilterBySubject.getData(filterData.subjectId)
    }
    else{
      str=overAllAnalytics.getData;
    }

   await axios
      .get(str, {
        headers: {
          token: window.localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        let data = res.data.data.analytics[0];
        const dt=getLabel(data?.score,data?.gradeAvgScore,data?.attemptedTests,data?.totalTests,data?.attemptedQuestions,data?.totalQuestions,data?.timeTakenInTest,data?.avgTimeTakenInQues,data?.topics,data?.chapters )
       
        setOverAllData([...dt]);
      })
      .catch((err) => console.log(err));
  };
  const getTimeSpent = async() => {
      let str;
     
      if(filterData.chapterId)
      {
        str=FilterByChapter.timeSpent(filterData.subjectId,filterData.chapterId)
      }
      else if(filterData.subjectId)
      {
        str=FilterBySubject.timeSpent(filterData.subjectId)
      }
      else{
        str=overAllAnalytics.timeSpent
      }
    await axios
      .get(str, {
        headers: {
          token: window.localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        console.log(data);
        console.log(activeFilter);
        let temp=[];
        
          data.forEach((item) => {
            
              if(item?.[activeFilter.current])
              {
                
            temp.push({
              Subject: item?.[activeFilter.current]?.name,
              Time: `${parseFloat(item?.timeTaken / (1000 * 60)).toFixed(2)}`,
              "time taken":`${Math.floor(item?.timeTaken/(1000*60))}m ${Math.ceil((item?.timeTaken/(1000))%60)}s`,
              subjectId: item?.[activeFilter.current]?._id,
            })
        }
           
          })
          setTimeSpent(()=>[...temp])
          loaders[0]=false;
          setLoaders(()=>[...loaders]);
        
      })
      .catch((err) => console.log(err));
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 320) {
      setVisible(true);
    } else if (scrolled <= 320) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisible);

  const getTestStatus = async() => {
    let str;
   
     if(filterData.chapterId)
    {
      str=FilterByChapter.getTestStatus(filterData.subjectId,filterData.chapterId)
    }
    else if(filterData.subjectId)
    {
      str=FilterBySubject.getTestStatus(filterData.subjectId)
    }
    else{
      str=overAllAnalytics.getTestStatus
    }
   await axios
      .get(str, {
        headers: {
          token: window.localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        console.log(data);
        let temp1 = [];
        let temp2 = [
          { name: "Completed", value: 0 },
          { name: "Pending", value: 0 },
        ];

         data.forEach((item) => {
            if(item?.[activeFilter.current])
              {
          temp1.push({
            Subject: item?.[activeFilter.current]?.name,
            Completed: item.completed,
            Pending: item.pending,
            subjectId: item?.[activeFilter.current]?._id,
          })
        }
        
        });
        let dt=0;
        setTestStatusGraph(()=>[...temp1]);
        data.forEach((item) => {
          dt+=item.completed+item.pending;
          temp2[0].value += item.completed;
          temp2[1].value += item.pending;
        });
        setTestAssign(dt);
        loaders[1]=false;
        loaders[2]=false;
        setLoaders(()=>[...loaders]);
        setTestStatus(() => [...temp2]);
      })
      .catch((err) => console.log(err));
  };
  const getQuestionAttemptStatus = async() => {
    let str;
    
    if(filterData.chapterId)
    {
      str=FilterByChapter.questionAttemptStatus(filterData.subjectId,filterData.chapterId)
    }
    else if(filterData.subjectId)
    {
      str=FilterBySubject.questionAttemptStatus(filterData.subjectId)
    }
    else{
      str=overAllAnalytics.questionAttemptStatus
    }
    
   await axios
      .get(str, {
        headers: {
          token: window.localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        console.log(data);
        let temp1 = [];
        data.forEach((item) => {
            if(item?.[activeFilter.current])
              {
          temp1.push({
            Subject: item?.[activeFilter.current].name,
            Correct: item.totalCorrect,
            Incorrect: item.totalIncorrect,
            Unattempted: item.totalUnattempted,
            subjectId: item?.[activeFilter.current]._id,
          })
        }
        
        });
        setQuestionStatus([...temp1]);
        let temp2 = [
          { name: "Correct", value: 0 },
          { name: "Incorrect", value: 0 },
          { name: "Unattempted", value: 0 },
        ];

        data.forEach((temp) => {
          temp2[0].value += temp.totalCorrect;
          temp2[1].value += temp.totalIncorrect;
          temp2[2].value += temp.totalUnattempted;
        });
        setQuestionAttemptStatus(() => [...temp2]);
        loaders[3]=false;
        loaders[4]=false;
        setLoaders(()=>[...loaders]);
      })
      .catch((err) => console.log(err));
  };

  const getCorrectQuestionLevel = async() => {
    let str;
    
    if(filterData.chapterId)
    {
      str=FilterByChapter.correctQuestionLevel(filterData.subjectId,filterData.chapterId)
    }
    else if(filterData.subjectId)
    {
      str=FilterBySubject.correctQuestionLevel(filterData.subjectId)
    }
    else{
      str=overAllAnalytics.correctQuestionLevel
    }
    
    await axios
      .get(str, {
        headers: {
          token: window.localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        let temp1 = [],
          temp2 = [];
        data.forEach((item) => {
            if(item?.[activeFilter.current])
              {
          let subject = item?.[activeFilter.current].name;
          let subjectId = item?.[activeFilter.current]._id;
          let { cognitive, difficulty } = item;

          console.log(difficulty, cognitive);
          temp1.push({
            Subject: subject,
            "Easy": difficulty.easy.correct,
            "Total Easy": difficulty.easy.total,
            "Intermediate": difficulty.intermediate.correct,
            "Total Intermediate": difficulty.intermediate.total,
            "Difficult": difficulty.hard.correct,
            "Total Difficult": difficulty.hard.total,
            subjectId,
          });

          temp2.push({
            Subject: subject,
            "Analysis": cognitive.analysis.correct,
            "Total Analysis": cognitive.analysis.total,
            "Application": cognitive.application.correct,
            "Total Application": cognitive.application.total,
            "Knowledge": cognitive.knowledge.correct,
            "Total Knowledge": cognitive.knowledge.total,
            "Understanding": cognitive.understanding.correct,
            "Total Understanding": cognitive.understanding.total,
          });
        }
        });
        setDifficulty(() => [...temp1]);
        setCognitveChart(() => [...temp2]);
        loaders[5]=false;
        loaders[6]=false;
        setLoaders(()=>[...loaders]);
      })
      .catch((err) => console.log(err));
  };

  console.log(FilterComponent);
  const getLearningStatus = async() => {
    let str;
    
    if(filterData.chapterId)
    {
      str=FilterByChapter.learningStatus(filterData.subjectId,filterData.chapterId)
    }
    else if(filterData.subjectId)
    {
      str=FilterBySubject.learningStatus(filterData.subjectId)
    }
    else{
      str=overAllAnalytics.learningStatus
    }
    
    await axios
      .get(str, {
        headers: {
          token: window.localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        let temp = [];
         data.forEach((item) => {
            if(item?.[activeFilter.current])
              {
          temp.push({
            Subject: item?.[activeFilter.current].name,
            Strength: item.strength,
            Gap: item.gap,
            subjectId: item?.[activeFilter.current]._id,
          })
        }
    
        });
        setLearningStatus([...temp]);
        loaders[7]=false;
        setLoaders(()=>[...loaders]);
      })
      .catch((err) => console.log(err));
  };
  const getTestReports = async() => {
    let str;
    if(filterData.topicId)
    {
      str=FilterByTopic.testReports(filterData.subjectId,filterData.chapterId,filterData.topicId)
    }
    else if(filterData.chapterId)
    {
      str=FilterByChapter.testReports(filterData.subjectId,filterData.chapterId)
    }
    else if(filterData.subjectId)
    {
      str=FilterBySubject.testReports(filterData.subjectId)
    }
    else{
      str=overAllAnalytics.testReports
    }
    
    await axios
      .get(str, {
        headers: {
          token: window.localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        console.log(data);
        let temp=[];
         temp= data.map((item, idx) => {
            return {
              sno: idx + 1,
              startDate: new Date(item?.testId?.from).toLocaleDateString(),
              endDate: new Date(item?.testId?.to).toLocaleDateString(),
              subject: item?.subjectId?.name,
              subjectId:item?.subjectId?._id,
              chapter: item.chapters,
              topic: item.topics,
              testType: item.testType,
              date: new Date(item.startedAt).toLocaleDateString(),
              startTime: new Date(item.startedAt).toLocaleTimeString(),
              timeSpent: `${getHours(
                parseFloat(item?.timeSpent / (1000 * 60 * 60)).toFixed(2)
              )}`,
              marks: item.totalMarks,
              testId:item?.testId?._id,
              subId:item?.submissionId,
              marksObtained: item.totalScore,
              classAverage: item?.avgScore,
              correct: item.correct,
              inCorrect: item.incorrect,
              easy: item?.correctAttempt?.easy,
              medium: item?.correctAttempt?.intermediate,
              difficult: item?.correctAttempt?.hard,
              knowledge: item?.correctAttempt?.knowledge,
              understanding: item?.correctAttempt?.understanding,
              analysis: item?.correctAttempt?.analysis,
              application: item?.correctAttempt?.application,
            };
          })
          setTabContent([...temp]);
          setTabContent2([...temp]);
        
      })
      .catch((err) => console.log(err));

  };
  console.log(filterData);

  const getTimeSpentOfTest=async()=>{
     await axios.get(FilterByTopic.timeSpent(filterData.subjectId,filterData.chapterId,filterData.topicId),{
          headers:{
              token: window.localStorage.getItem("authToken")
          }
      }).then(res=>{
          let data=res.data.data.analytics;
          let temp=[];
          console.log(data);
          data.forEach((item)=>{
              if(item.testId)
              {
                  temp.push({
                      Subject:item?.testId?.questionSet?.title.split("_")[0],
                      Time:parseFloat((item?.timeTaken)/(1000*60)).toFixed(2)
                  })
              }
              setTimeSpent(()=>[...temp])
              loaders[0]=false;
              setLoaders(()=>[...loaders]);
          })

      }).catch(err=>console.log(err))
  }
//   console.log(timeSpent);
const getTestStatusOfTest=async()=>{
    await axios.get(FilterByTopic.getTestStatus(filterData.subjectId,filterData.chapterId,filterData.topicId),{
        headers:{
            token:window.localStorage.getItem("authToken")
        }
    }).then(res=>{
        let data=res.data.data.analytics;
        let temp1 = [];
        let temp2 = [
          { name: "Completed", value: 0 },
          { name: "Pending", value: 0 },
        ];
          data?.forEach((item) => {
            if(item?.testId)
              {
           temp1.push({
            Subject: item?.testId?.questionSet?.title.split("_")[0],
            Completed: item.completed,
            Pending: item.pending,
            subjectId: item?.testId?._id,
          })
        }
        
        });
        setTestStatusGraph(()=>[...temp1]);
        data.forEach((item) => {
          temp2[0].value += item.completed;
          temp2[1].value += item.pending;
        });

        setTestStatus(() => [...temp2]);
        loaders[1]=false;
        loaders[2]=false;
        setLoaders(()=>[...loaders]);

    }).catch(err=>console.log(err));
}


const getQuestionAttemptStatusOfTest=async ()=>{
   await axios.get(FilterByTopic.questionAttemptStatus(filterData.subjectId,filterData.chapterId,filterData.topicId),{
        headers:{
            token:window.localStorage.getItem("authToken")
        }
    }).then(res=>{
        let data=res.data.data.analytics;
        console.log(data);
        let temp1 =[];
        // let temp2=[]
        data.forEach((item) => {
            if(item?.testId)
              {
          temp1.push({
            Subject:  item?.testId?.questionSet?.title.split("_")[0],
            Correct: item.totalCorrect,
            Incorrect: item.totalIncorrect,
            Unattempted: item.totalUnattempted,
            subjectId: item?.testId?.questionSet?._id,
          })
        }
       
        });
        setQuestionStatus([...temp1]);
        let temp2 = [
          { name: "Correct", value: 0 },
          { name: "Incorrect", value: 0 },
          { name: "Unattempted", value: 0 },
        ];

        data.forEach((temp) => {
          temp2[0].value += temp.totalCorrect;
          temp2[1].value += temp.totalIncorrect;
          temp2[2].value += temp.totalUnattempted;
        });
        setQuestionAttemptStatus(() => [...temp2]);
        loaders[3]=false;
        loaders[4]=false;
        setLoaders(()=>[...loaders]);

    })
}

const getCorrectQuestionLevelOfTest=async()=>{
    await axios.get(FilterByTopic.correctQuestionLevel(filterData.subjectId,filterData.chapterId,filterData.topicId),{
        headers:{
            token:window.localStorage.getItem("authToken")
        }
    }).then(res=>{
        let data=res.data.data.analytics;
        let temp1 = [],
        temp2 = [];
      data.forEach((item) => {
          if(item?.testId?.questionSet)
            {
        let subject = item?.testId?.questionSet?.title.split("_")[0];
        let subjectId = item?.testId?._id;
        let { cognitive, difficulty } = item;

        // console.log(difficulty, cognitive);
        temp1.push({
          Subject: subject,
          Easy: difficulty.easy.correct,
          "Total Easy": difficulty.easy.total,
          Intermediate: difficulty.intermediate.correct,
          "Total Intermediate": difficulty.intermediate.total,
          Difficult: difficulty.hard.correct,
          "Total Difficult": difficulty.hard.total,
          subjectId,
        });

        temp2.push({
          Subject: subject,
          Analysis: cognitive.analysis.correct,
          "Total Analysis": cognitive.analysis.total,
          Application: cognitive.application.correct,
          "Total Application": cognitive.application.total,
          Knowledge: cognitive.knowledge.correct,
          "Total Knowledge": cognitive.knowledge.total,
          Understanding: cognitive.understanding.correct,
          "Total Understanding": cognitive.understanding.total,
        });
      }
      });
      setDifficulty(() => [...temp1]);
      setCognitveChart(() => [...temp2]);
      loaders[5]=false;
      loaders[6]=false;
      setLoaders(()=>[...loaders]);
    })
    .catch((err) => console.log(err));
   
}
console.log(cognitiveChart);
const getLearningStatusOfTest=async()=>{
    await axios.get(FilterByTopic.learningStatus(filterData.subjectId,filterData.chapterId,filterData.topicId),{
        headers:{
            token:window.localStorage.getItem("authToken")
        }
    }).then(res=>{
        let data=res.data.data.analytics;
        let temp = [];
        data.forEach((item) => {
            if(item?.testId?.questionSet)
              {
           temp.push({
            Subject: item?.testId?.questionSet?.title.split("_")[0],
            Strength: item.strength,
            Gap: item.gap,
            subjectId: item?.testId?.questionSet?._id,
          })
        }
        
        });
        setLearningStatus([...temp]);
        loaders[7]=false;
        setLoaders(()=>[...loaders]);
    }).catch(err=>console.log(err))
}
  useEffect(() => {
      const asyncFunction=async()=>{
        setDisabled(true);
        if(filterData.topicId)
      {
        setLoaders([true,true,true,true,true,true,true,true])
      activeFilter.current="topicId";
    
      await getTimeSpentOfTest();
      await getTestStatusOfTest();
      await getQuestionAttemptStatusOfTest();
      await getCorrectQuestionLevelOfTest();
      await getLearningStatusOfTest();
      setDisabled(false);

      }
      else{
    if(filterData.chapterId)
      activeFilter.current="topicId"
      else if(filterData.subjectId)
      activeFilter.current="chapterId";
      else
      {
          activeFilter.current="subjectId";
      }
      setLoaders([true,true,true,true,true,true,true,true])
      await getOverAllData();
      await getTimeSpent();
      await getTestStatus();
      await getQuestionAttemptStatus();
      await getCorrectQuestionLevel();
      await getLearningStatus();
      await getTestReports();
      setDisabled(false);
      }
      }
      asyncFunction();
      
  }, [filterData]);
  // console.log(overAllData);
  console.log(formData);
  console.log(timeSpent);
  const handleClick=(row)=>{
    console.log(row);
    history.push(`/analytics/tests/charts/report/${row?.testId}/${row?.subId}?subject=${row.subject}&subId=${row.subjectId}`);
  }


  useEffect(()=>{
    let tabData=tabContent2;
    if(formData.startDate)
    {
      tabData=tabData.filter((item,idx)=>new Date(item?.startDate)>=new Date(formData?.startDate))
    }
    if(formData.endDate)
    {
      tabData=tabData.filter((item,idx)=>new Date(item?.endDate)<=new Date(formData?.endDate))
    }
    if(formData.subject)
    {
      console.log(formData);
      console.log(tabData);
      tabData=tabData.filter(item=>item.subject===formData.subject.name);
      
    }
    setTabContent(()=>tabData.map((item,idx)=>{return{...item,sno:idx+1}}));

  },[formData])
  

  // /function to run for exporting practice report data
    const exportPracticeReportData = async()=>{
      console.log("hdfvkn");

        try{
            const data = tabContent && tabContent.length && tabContent.map((item,index)=>({
                Number: index+1,
                "Start Date": item.startDate,
                "End Date": item.endDate,
                // "End Date": item.startedAt.split("T")[0],
                Subject: item.subject,
                Chapter: item.chapter,
                Topic: item.topic,
                "Start Time":item?.startTime,
                "Test Type":item.testType,
                "Time Spent": item.timeSpent,
                Marks:item?.marks,
                "Marks Obtained":item?.marksObtained,
                
               
                Correct: item.correct || 0,
                Incorrect: item.inCorrect || 0,
                Easy: item.easy || 0,
                Intermediate: item.intermediate || 0,
                Difficulty: item.difficult || 0,
                Knowledge: item.knowledge || 0,
                Understanding: item.understanding || 0,
                Analytics: item.analysis || 0,
               
                
    
            }))
               
            const fileName = 'Online Test Details'
            const exportType =  exportFromJSON.types.csv
    
            exportFromJSON({ data, fileName, exportType })
        }catch(err) {
            console.log("Error in downloading practice report data:",err.message)
        }
    }
    // console.log(headerData);
    console.log(subjectData);
  return (
    <>
      <SideBoard
        filterData={filterData}
        setFilterData={setFilterData}
        type="online"
        setSubjectData={setSubjectData}
        headerData={headerData}
        setData={setHeaderData}
        disabled={disabled}
        
      ></SideBoard>
      <section className="learningResources my-md-5 my-sm-1 p-lg-0 p-2 p-sm-2">
        <div  style={{}} className="container">
          {!isSmall ? (
            <div
              className="box mb-5 row"
              style={{ cursor: "pointer", alignItems: "center",background:"linear-gradient(91.91deg, #C08497 1.44%, #B46A81 99.96%)" }}
            >
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "fit-content",
                }}
              >
                <h2
                  className="fw-bold white_color"
                  style={{
                    fontWeight: "bolder",
                    color: "white",
                    width: "100% !important",
                  }}
                >
                  Analytics  Online Test
                </h2>
                {/* <h1 style={{ fontWeight: 'bolder',color: 'white'}} >Analytics</h1> */}
              </span>
              <h5
                className="col-sm-3"
                style={{
                  marginLeft: "auto",
                  fontSize: "90%",
                  fontWeight: "bolder",
                  marginBottom: "auto",
                  textAlign: "right",
                }}
              >
                <CalendarTodayIcon style={{ width: "17px" }} />{" "}
                {getCurrentDate()}
              </h5>
              <p className=" white_color" style={{ color: "white" }}>
                 {headerData.subject} {headerData?.chapter&&`-> ${headerData.chapter}`} {headerData?.topic&&` -> ${headerData.topic}`}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
      <Grid
      container spacing={5}
      // maxWidth="90%"
      style={{maxWidth:"90%",margin:"0 auto"}}
      
        // style={{
        //   display: "flex",
        //   flexWrap: "wrap",
        //   // justifyContent: "space-between",
        //   maxWidth: "1320px",
        //   margin: "0 auto",
        // }}
      >
        {overAllData.map((item) => (
          <MiniCards heading={item.heading} body={item.value} />
        ))}
      </Grid>
      {/* <MiniCards data={overAllData}/>  */}

      <div className={classes.graphContainer}>
        <TimePerSubject tests={testAssign} data={timeSpent} loading={loaders[0]} />
        <TestStatusPieChart data={testStatus} loading={loaders[1]} />
        <DifficultyPieChart data={questionAttemptStatus} loading={loaders[2]} />
        <TestStatus data={testStatusGraph} loading={loaders[3]} />
        <QuestionStatusReport data={questionStatus} loading={loaders[4]} />
        <DifficultyReport data={difficultyChart}  loading={loaders[5]}/>
        <CognitiveReport data={cognitiveChart} loading={loaders[6]} />
        <LearningStrength data={learningStatus} loading={loaders[7]}/>
      </div>
      <div className={classes.linkContainer}>
        {/* <Link to="/analytics/tests/charts/report">
          View Report <TrendingFlatIcon />{" "}
        </Link> */}
        <div className={classes.filterDiv} style={{display:"flex",marginLeft:"150px",flexWrap:"wrap"}}>
          <FilterComponentt options={subjectData} formData={formData} setFormData={setFormData} name="subject"/>
        <FilterComponent formData={formData} name="startDate" setFormData={setFormData}  placeholder="Start Date"   />
        <FilterComponent name="endDate" formData={formData} setFormData={setFormData} placeholder="End Date"   />
        </div>
      </div>
      <div
        style={{
          maxWidth: "90vw",
          margin: "20px auto",
          boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.37)",
          borderRadius: "19px",
          padding: "20px 10px",
          overFlowX: "auto",
        }}
        className={classes.table}
      >   
          <div style={{display:"flex",justifyContent:"space-between"}}>
           <h2>Test Reports</h2>
           <div>
          {/* <Buttons handleClick={exportPracticeReportData}/> */}
          <ExportIcon style={{cursor:"pointer" ,height:"40px",width:"38px"}} onClick={()=>exportPracticeReportData()}/>
          </div>
          </div>
        <TableComponent tabHeader={OnlineTest} handleClick={handleClick} tabContent={tabContent} />
      </div>
      <div  className="scroll_button_head">
          {visible && (
            <div style={{display: "flex",
              background: "#20c997",
              height: "50px",
              width: "50px",
             
              alignItems: "center",
              justifyContent:"center",
              position:"fixed",
              bottom:"10px",
              left:"10px",
              cursor:"pointer",
              borderRadius: "50%"}} className="scroll_button" onClick={scrollToTop}>
              <KeyboardArrowUpIcon className="scroll" />
              {/* <span>scroll up</span> */}
            </div>
          )}
        </div>
    </>
  );
}

export default TestCharts;

import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';

export const GlobalContext = createContext();

export const GlobalProvider = (props) => {
  const [userDetails, setUserDetails] = useState({
    isAuthenticated: false,
    name: '',
    userId: '',
    schoolId: '',
    role: '',
    profileImageUrl: undefined,
    selectedTab:"Dashboard",
    selectedPath:{
      subject:"",
      chapter:"",
      topic:""
    }
  });
  const [loading, setLoading] = useState(false);

  const CheckAuth = async () => {
    const authToken = localStorage.getItem('authToken');
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/auth/validate/${authToken}`
      );
      if (response.data.status === 'authorized') {
        const { name, userId, school, role, grade, section, sectionId, profile } =
          response.data.data.user;
        localStorage.setItem('userGrade', `${grade}-${section}`);
        localStorage.setItem('sectionId', sectionId);
        setUserDetails({
          isAuthenticated: true,
          name,
          userId,
          school,
          role,
          grade,
          section,
          profileImageUrl: profile?.fileUrl,
          authToken,
          selectedTab:userDetails.selectedTab,
          selectedPath:userDetails.selectedPath
        });
      }

      setLoading(true);
    } catch (error) {
      setUserDetails({ isAuthenticated: false });
      setLoading(true);
    }
  };
  useEffect(() => {

    CheckAuth();
  }, []);

  return (
    <>
      {loading && (
        <GlobalContext.Provider
          value={{
            userDetails,
            setUserDetails,
          }}
        >
          {props.children}
        </GlobalContext.Provider>
      )}
    </>
  );
};

import React, { useState, useEffect } from "react";
import "./Assesment.css";
import ProgressBar from "./ProgressBar";
import AssesmentHeader from "./AssesmentHeader";
// import RightArrow from '../../../images/assessment/RA.png';
// import LeftArrow from '../../../images/assessment/LA.png';
import RightArrow from "../../../images/assessment/LeftArrow";
import LeftArrow from "../../../images/assessment/RightArrow";
import Save from "../../../images/assessment/Icon.png";
import Popup from "./Popup";
import useSlider from "./useSlider";
import { useParams, useHistory } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalState";
import { useContext } from "react";
import { useAlert } from "react-alert";
import { useMediaQuery } from "react-responsive";
import Drawer from "../../Navbar/Drawer";

// const sliders = [DescCard,FillBlankCard,MmcqCard,OneWord,TFCard,McqCard];

const getPercentage = (a, b) => {
  a = parseInt(a);
  b = parseInt(b);

  if (b == 0) {
    return 0;
  } else {
    return ((a * 100) / b).toPrecision(4);
  }
};

const getTotal = (object) => {
  if (!object) {
    return 0;
  }

  return object.easy + object.intermediate + (object.difficult || object.hard);
};

const Assesment = () => {
  //Create instances
  const params = useParams();
  const history = useHistory();
  const { userDetails } = useContext(GlobalContext);
  const alert = useAlert();
  const isSmall = useMediaQuery({ query: "(max-width: 472px)" });

  //Extracting data
  const studentId = userDetails.userId;
  const sectionId = window.localStorage.getItem("sectionId");
  const subjectId = params.subjectId;
  const subjectName = params.subjectName;
  const chapterId = params.chapterId;
  const chapterName = params.chapterName;
  const topicId = params.topicId;

  //state and fucntion for practice sessions
  const [practiceSession, setPracticeSession] = useState({});
  const [totalAttemptedQuestions, setAttemptedQuestions] = useState(0);
  const [totalQuestionsToAttempt, setTotalQuestionsToAttempt] = useState(1);
  const [correctlyAnswered, setCorrectlyAnswered] = useState(0);
  const [progress, setProgress] = useState(0);
  const [sessionCompleted, setSessionCompleted] = useState(false);
  const [awardedGrade, setAwardGrade] = useState("");
  const [startingTime, setStartingTime] = useState(new Date().getTime());
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [noQuestionsCreated, setNoQuestionsCreated] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchPracticeSession = async () => {
    setLoading(true);
    try {
      await fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/students/${studentId}/sections/${sectionId}/subjects/${subjectId}/chapters/${chapterId}/practiceSession?topicId=${topicId}`,
        {
          headers: {
            token: `${window.localStorage.getItem("authToken")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(
            "Session creation response for Practice Session ====> ",
            response
          );

          //If success then move to the practice session component
          if (response.status === "success") {
            if (response.data.practiceSession.totalQuestionsToAttempt === 0) {
              setNoQuestionsCreated(true);
            }
            setPracticeSession(response.data.practiceSession);
            setAttemptedQuestions(
              getTotal(response.data.practiceSession.totalQuestionsAttempted)
            );
            setTotalQuestionsToAttempt(
              getTotal(response.data.practiceSession.totalQuestionsToAttempt)
            );
            setCorrectlyAnswered(
              getTotal(response.data.practiceSession.correct)
            );
            setProgress(
              getPercentage(
                getTotal(response.data.practiceSession.totalQuestionsAttempted),
                getTotal(response.data.practiceSession.totalQuestionsToAttempt)
              )
            );
            console.log(
              "Practice session now after fetching ===>",
              practiceSession
            );
            setQuestionCount(questionCount + 1);
          } else {
            alert.error(response.message);
            console.log(response);
          }
        });
    } catch (error) {
      alert.error(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPracticeSession();
  }, []);

  //State and variables for modal and slider
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);
  const [handleSlide, showSlide] = useSlider();

  //Satte and variables for questions
  const [question, setQuestion] = useState({});
  const [questionCount, setQuestionCount] = useState(0);

  //Function to fetch question
  const fetchQuestion = async () => {
    if (!practiceSession._id) {
      return;
    }
    setLoading(true);
    await fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/students/${studentId}/sections/${sectionId}/subjects/${subjectId}/chapters/${chapterId}/practiceSession/${practiceSession._id}/questions`,
      {
        headers: {
          token: `${window.localStorage.getItem("authToken")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("Question count ===> ", questionCount);
        console.log("Question ===> ", response);

        if (response.status === "success") {
          if (response.sessionStatus === "completed") {
            setSessionCompleted(true);
            return;
          }

          setQuestion(response.data.question);
          setStartingTime(new Date().getTime());
        } else {
          alert.error(response.message);
        }
      });
    setButtonDisabled(false);
    setLoading(false);
  };

  useEffect(() => {
    fetchQuestion();
  }, [questionCount]);

  //to check whether the student has started an already-completed session
  useEffect(() => {
    if (totalAttemptedQuestions === totalQuestionsToAttempt) {
      if (totalQuestionsToAttempt === 0) {
        setNoQuestionsCreated(true);
      }
      setSessionCompleted(true);
    } else {
      setSessionCompleted(false);
    }
  }, [practiceSession]);

  //To render the modal once the student completes the session
  useEffect(() => {
    if (sessionCompleted) {
      setStatus("completed");
      setMessage(
        totalQuestionsToAttempt === 0
          ? "Questions are not created yet"
          : "No more questions left to attempt"
      );
      setModal(true);
    }
  }, [sessionCompleted]);

  //States for answers
  const [correctAnswer, setCorrectAnswer] = useState({});
  const [allAttemptsWrong, setAllAttemptsWrong] = useState(false);

  //Function to submit correct answer
  const submitAnswer = async () => {
    //Check if the studnt clicks the button to move to next question
    if (allAttemptsWrong) {
      setAllAttemptsWrong(false);
      setQuestionCount(questionCount + 1);
      return;
    }

    //Disable the button
    setButtonDisabled(true);
    setLoading(true);

    //body to be sent in response
    const body = {
      answer: correctAnswer,
      questionId: question._id,
      timeTaken: new Date().getTime() - startingTime,
    };

    console.log("Request body ===>", body);

    await fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/students/${studentId}/sections/${sectionId}/subjects/${subjectId}/chapters/${chapterId}/practiceSession/${practiceSession._id}/questions`,
      {
        method: "POST",
        headers: {
          token: `${window.localStorage.getItem("authToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("Submit answer response ===> ", response);

        setAllAttemptsWrong(false);
        if (response.status === "success") {
          //Update stats of practice session
          setAttemptedQuestions(
            getTotal(response.data.practiceSession.totalQuestionsAttempted)
          );
          setCorrectlyAnswered(getTotal(response.data.practiceSession.correct));
          setProgress(
            getPercentage(
              getTotal(response.data.practiceSession.totalQuestionsAttempted),
              totalQuestionsToAttempt
            )
          );

          if (response.sessionStatus === "completed") {
            setSessionCompleted(true);
            return;
          }

          //If the answer is correct, move to next question
          if (response.data.correct) {
            // alert.success("Correct")
            setStatus("correct");
            setMessage(null);
            setQuestionCount(questionCount + 1);
            setButtonDisabled(false);
          }
          //Check if tthere s an attempt left
          else if (!response.data.correct && response.data.attemptLeft === 1) {
            // alert.error("Wrong Answer")
            setStatus("incorrect");
            setMessage(null);
            // if(question.questionType==="mmcq"){
            //   setCorrectAnswer([])
            // }else{
            //   setCorrectAnswer({})
            // }
            setButtonDisabled(false);
          }
          //If wrong, then give another chance
          else if (!response.data.correct && !response.data.attemptLeft) {
            setStatus("incorrect");
            // alert.error("Wrong Answer")
            setMessage("Correct answer is: " + response.data.correctAnswers);
            setAllAttemptsWrong(true);
            setButtonDisabled(false);
          }
        } else {
          setStatus("error");

          //Handle the incoming of any abnormal behaviour such that the Submit button autmatically turns to Next
          if (
            response.message.includes("wrong") ||
            response.message.includes("already")
          ) {
            setAllAttemptsWrong(true);
          }
          setMessage(response.message);
          // alert.error(response.message)
          setButtonDisabled(false);
        }
        setModal(true);
      });

    setButtonDisabled(false);
    setLoading(false);
  };

  const exit = async () => {
    await fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/students/${studentId}/sections/${sectionId}/subjects/${subjectId}/chapters/${chapterId}/practiceSession/${practiceSession._id}/exit`,
      {
        headers: {
          token: `${window.localStorage.getItem("authToken")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("Response ===> ", response);

        if (response.status === "success") {
          history.push("/myassessment");
        } else {
          alert.error(response.message);
        }
      });
    setButtonDisabled(false);
  };

  return (
    <>
      <Popup
        modal={modal}
        setModal={setModal}
        status={status}
        message={message}
        noQuestionsCreated={totalQuestionsToAttempt === 0}
      />
      {isSmall && (
        <Drawer>
          <AssesmentHeader
            title={"Practice Assessment"}
            totalAttemptedQuestions={totalAttemptedQuestions}
            totalQuestionsToAttempt={totalQuestionsToAttempt}
            correctlyAnswered={correctlyAnswered}
            awardedGrade={awardedGrade}
            timeSpend={practiceSession.timeSpend}
            sessionCompleted={sessionCompleted}
          />
        </Drawer>
      )}
      <section className="my-4">
        <div className="container">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="mainCont">
              {!isSmall && (
                <AssesmentHeader
                  title={"Practice Assessment"}
                  totalAttemptedQuestions={totalAttemptedQuestions}
                  totalQuestionsToAttempt={totalQuestionsToAttempt}
                  correctlyAnswered={correctlyAnswered}
                  awardedGrade={awardedGrade}
                  timeSpend={practiceSession.timeSpend}
                  sessionCompleted={sessionCompleted}
                />
              )}
              <ProgressBar
                width={progress + "%"}
                createdAt={practiceSession.createdAt}
              />
            </div>
          </div>
          {!sessionCompleted
            ? showSlide(
                question,
                questionCount,
                correctAnswer,
                setCorrectAnswer,
                loading
              )
            : ""}
          {!sessionCompleted ? (
            <div className="slider">
              {/* <img
              src={LeftArrow}
              alt='left-arrow'
              className='slider-img'
              onClick={(e) => handleSlide(e, true)}
            /> */}
              {/* <RightArrow
              className='slider-img'
              onClick={(e) => handleSlide(e, true)}
            /> */}
              <button
                className="save-btn"
                onClick={() => {
                  setModal((state) => !state);
                  exit();
                }}
              >
                <img src={Save} alt="save-btn" />
                Save and Exit
              </button>

              <button
                className={
                  buttonDisabled ? "save-btn disabled_button" : "save-btn"
                }
                onClick={() => {
                  submitAnswer();
                }}
                disabled={buttonDisabled}
              >
                <img src={Save} alt="save-btn" />
                {allAttemptsWrong
                  ? "Next"
                  : buttonDisabled
                  ? "Submitting"
                  : "Submit"}
              </button>
              {/* <LeftArrow className='slider-img' onClick={submitAnswer} /> */}
              {/* <img
              src={RightArrow}
              alt='right-arrow'
              className='slider-img'
              onClick={(e) => handleSlide(e)}
            /> */}
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>
    </>
  );
};

export default Assesment;

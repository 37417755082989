import React from 'react';

const Success = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='119'
      height='119'
      viewBox='0 0 119 119'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M59.5 119C92.361 119 119 92.361 119 59.5C119 26.6391 92.361 0 59.5 0C26.6391 0 0 26.6391 0 59.5C0 92.361 26.6391 119 59.5 119ZM87.0716 49.8841C89.9761 46.9796 89.9761 42.2704 87.0716 39.3659C84.1671 36.4614 79.4579 36.4614 76.5534 39.3659L52.0625 63.8568L42.4466 54.2409C39.5421 51.3364 34.8329 51.3364 31.9284 54.2409C29.0239 57.1454 29.0239 61.8546 31.9284 64.7591L46.8034 79.6341C49.7079 82.5386 54.4171 82.5386 57.3216 79.6341L87.0716 49.8841Z'
        fill='#5CDA8E'
      />
    </svg>
  );
};

export default Success;

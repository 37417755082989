import React from 'react';
import { PieChart, Pie,Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { makeStyles } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import {ReactComponent as Loader} from "../../../../../images/analytics/ChartLoader.svg"

const PIE_CHART_COLORS = ["rgba(15, 147, 168, 1)","rgba(255, 137, 137, 1)","rgba(157, 171, 221, 1)"]

const useStyles = makeStyles({
    container:{
        width: '40%',
        height: '70%',
        boxShadow:"0px 0px 7px rgba(0, 0, 0, 0.25)",
        borderRadius:"17px",
        margin:"1rem 0px",
        "& .recharts-default-legend":{
            paddingLeft:"300px !important"
        }
    },
    headingContainer:{
        textAlign:"center",
        "& > *":{
            marginBlockStart:"0em",
            marginBlockEnd:"0.2em",
        },
        "& > h3":{
            fontSize:"0.9em",
            marginBlockStart:"10px"
        },
        "& > p":{
            fontSize:"0.7em",
        }
    },
    "@media screen and (max-width: 472px)":{
        container:{
            width: '97%',
            height: '60%',
            borderRadius: '10px',
            margin:"10px auto",
            "& .recharts-default-legend":{
                paddingLeft:"200px !important",
                fontSize:"0.8em"
            },
            "& .recharts-wrapper":{
                height:"230px !important"
            }
        }
    }

})

export default function DifficultyPieChart({data,loading}) {

    const classes = useStyles()
    const isSmall = useMediaQuery({query:"(max-width: 472px)"})

    return (
    <div className={classes.container}> 
        <div className={classes.headingContainer}>
            <h3>Performance Report</h3>
            {/* <p>Total Questions Practiced- 500</p> */}
        </div>
        {loading?<Loader/>:
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
            <Pie
                dataKey="value"
                isAnimationActive={false}
                data={data}
                cx="25%"
                cy="40%"
                outerRadius={isSmall?85:120}
                fill="#8884d8"
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]} />
                ))}
            </Pie>
          <Tooltip />
          {isSmall?
            <Legend width="90%" height="fit-content" layout="vertical" verticalAlign="middle"/>:
            <Legend width="90%" height="fit-content" layout="vertical" verticalAlign="middle"/>
            }
        </PieChart>
      </ResponsiveContainer>
        }
    </div>
    );
  }

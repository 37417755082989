import React, { useEffect } from 'react';
import { head, input, remarkStyle, signature } from '../../helper';
import './mmcqCard.css';
import DefaultImage from '../../../../../images/Navbar/title.png'
import { htmlDecode } from '../../../../../utils/parse';


const parseData = (id) => JSON.parse(localStorage.getItem(id))

export default function MmcqCard({ color = '', data, number, correctAnswer, setCorrectAnswer, onlineTestId }) {

  const question = data
  const id = onlineTestId + "," + question._id

  const handleClick = (e) => {
  
    if (localStorage.getItem(id)) {
      let parsedData = parseData(id)
      if (parsedData.includes(e.target.value)) {
        parsedData = parsedData.filter(answer => answer !== e.target.value)
        setCorrectAnswer(parsedData);
      } else {
        parsedData.push(e.target.value)
        setCorrectAnswer(parsedData);
      }
      localStorage.setItem(id, JSON.stringify(parsedData))
    } else {
      let temp = new Array(e.target.value)
      setCorrectAnswer(temp);
      localStorage.setItem(id, JSON.stringify(temp))
    }
  }

  //Uncheck all the options when a new question is rendered
  useEffect(() => {
    setCorrectAnswer([])
    if (localStorage.getItem(id)) return

    const radios = document.querySelectorAll(".mmcq_radio")
    radios.forEach((radio) => {
      radio.checked = false
    })
  }, [number])

  const parseString = (str) => {
    return new DOMParser()
      .parseFromString(str, "text/html")
      .body.textContent.trimStart();
  }

  return (
    <>
      <div className='container question_card my-4'>
        <div className='superhead'>
          <div className='remark' style={remarkStyle(color)}>Select Multiple Options</div>
          <div className='mmcq__head' style={head(color)}>
            <div className='mmcqCard'>
              <div className='mmcqCard__left'><h6 style={{ fontWeight: "bolder", marginTop: "5px" }} dangerouslySetInnerHTML={{ __html: htmlDecode(`Q${number}. ${question?.question}`) }}></h6></div>
            </div>
            <div className='main_div'>
              {question?.images?.questionImg ? (
                <div className='question_image'>
                <img className='q-img'  alt='question' src={question?.images?.questionImg} />
                </div>
                ) : null}
              <div className='mmcq' style={question?.images?.op1 ? { width: '70%' } : { width: '80%' }} >
                <div className='up top-left'>
                  <label className='opt1-container' style={input(color)}>
                    <div className="opt-top-card">
                      <span dangerouslySetInnerHTML={{ __html: htmlDecode(question?.options[0]?.value || "-") }}></span>
                      <input type='checkbox' value={question?.options[0]?.optionId} checked={localStorage.getItem(id) && parseData(id).includes(question?.options[0]?.optionId)} className='mmcq_radio' onClick={handleClick} name='radio' />
                      <span className='checkmark' style={input(color, 3)}></span>
                    </div>
                    {question?.images?.op1 && <img className='imgBlock' src={question?.images?.op1} />}
                    {/* <div className="imgBlock" style={{background:`url(${img2}) no-repeat center center`,backgroundSize: "cover"}}></div> */}
                    {/* {img2? <img className="ans_mcq_image" src={img2}/> : null} */}
                  </label>
                </div>
                <div className='up top-right ml-6'>
                  <label className='opt1-container' style={input(color)}>
                    <div className="opt-top-card">
                      <span dangerouslySetInnerHTML={{ __html: htmlDecode(question?.options[1]?.value || "-") }}></span>
                      <input type='checkbox' value={question?.options[1]?.optionId} checked={localStorage.getItem(id) && parseData(id).includes(question?.options[1]?.optionId)} className='mmcq_radio' onClick={handleClick} name='radio' />
                      <span className='checkmark' style={input(color, 3)}></span>
                    </div>
                    {question?.images?.op2 && <img className='imgBlock' src={question?.images?.op2} />}
                    {/* <div className="imgBlock" style={{background:`url(${img2}) no-repeat center center`,backgroundSize: "cover"}}></div> */}
                    {/* {img2? <img className="ans_mcq_image" src={img2}/> : null} */}
                  </label>
                </div>
                <div className='up bottom-left'>
                  <label className='opt1-container' style={input(color)}>
                    <div className="opt-top-card">
                      <span dangerouslySetInnerHTML={{ __html: htmlDecode(question?.options[2]?.value || "-") }}></span>
                      <input type='checkbox' value={question?.options[2]?.optionId} checked={localStorage.getItem(id) && parseData(id).includes(question?.options[2]?.optionId)} className='mmcq_radio' onClick={handleClick} name='radio' />
                      <span className='checkmark' style={input(color, 3)}></span>
                    </div>
                    {question?.images?.op3 && <img className='imgBlock' src={question?.images?.op3} />}
                    {/* <div className="imgBlock" style={{background:`url(${img2}) no-repeat center center`,backgroundSize: "cover"}}></div> */}
                    {/* {img2? <img className="ans_mcq_image" src={img2}/> : null} */}
                  </label>
                </div>
                <div className='up bottom-right ml-6'>
                  <label className='opt1-container' style={input(color)}>
                    <div className="opt-top-card">
                      <span dangerouslySetInnerHTML={{ __html: htmlDecode(question?.options[3]?.value || "-") }}></span>
                      <input type='checkbox' value={question?.options[3]?.optionId} checked={localStorage.getItem(id) && parseData(id).includes(question?.options[3]?.optionId)} className='mmcq_radio' onClick={handleClick} name='radio' /><span className='checkmark' style={input(color, 3)}></span>
                      <span className='checkmark' style={input(color, 3)}></span>
                    </div>
                    {question?.images?.op4 && <img className='imgBlock' src={question?.images?.op4} />}
                    {/* <div className="imgBlock" style={{background:`url(${img2}) no-repeat center center`,backgroundSize: "cover"}}></div> */}
                    {/* {img2? <img className="ans_mcq_image" src={img2}/> : null} */}
                  </label>
                </div>
              </div>
            </div>
            <div className='last'>
              {question.onModel === "admin" ? <img src={DefaultImage} className='signarture' style={{ ...signature(color), width: '50px !important' }} /> : <div className='signarture' style={signature(color)}>{question.creatorId ? question.creatorId.name : ""}<br />{question.schoolId.name}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

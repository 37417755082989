import React, { useEffect, useMemo } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { useHistory } from "react-router-dom";

import "@react-pdf-viewer/core/lib/styles/index.css";

export default function PDFViewer(props) {
  const history = useHistory();
  useEffect(() => {
    if (!props.location?.useProps?.fileUrl) {
      history.goBack();
    }
  }, [props.location]);

  const isMobile = useMemo(() => {
    return document.body.clientWidth <= 750;
  }, []);

  return isMobile ? (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <div id="pdf-viewer">
        <Viewer fileUrl={props.location?.useProps?.fileUrl} />
      </div>
    </Worker>
  ) : (
    <div
      style={{
        width: "90%",
        height: "90%",
        margin: "auto",
        marginTop: "1rem",
        paddingTop: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <iframe
        title="PDF Viewer"
        id="pdf_iframe"
        src={props.location?.useProps?.fileUrl}
        width="90%"
        height="800px"
      ></iframe>
    </div>
  );
}

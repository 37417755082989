import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalState";
import Navbar from "../Navbar/Navbar";

const SecuredRoute = (props) => {
  const { userDetails } = useContext(GlobalContext);

  return userDetails.isAuthenticated ? (
    <>
      <Navbar />
      <Route {...props} />
    </>
  ) : (
    <Redirect to="/" />
  );
};

export default SecuredRoute;

import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/GlobalState";
import { useAlert, types } from "react-alert";

function Auth() {
  console.log("Entered auth");

  const alert = useAlert();

  const { userDetails, setUserDetails } = useContext(GlobalContext);

  const url = window.location.href.split("/");
  const token = url[url.length - 2];
  const id = url[url.length - 1];

  console.log("Got Token", token);

  window.localStorage.setItem("authToken", token);
  window.localStorage.setItem("userId", id);

  //Function to check whether the user has a profile picture
  const image = (profile) => {
    if (!profile) {
      return null;
    } else if (!profile.fileName) {
      return null;
    } else {
      return profile.fileUrl;
    }
  };

  const getUserDetails = async () => {
    console.log("Getting user details..");
    console.log("ORIGIN", process.env.REACT_APP_ORIGIN);
    try {
      await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/students/${id}`, {
        headers: {
          token: token,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (response.status === "success") {
            setUserDetails({
              isAuthenticated: true,
              name: response.data.student.name,
              userId: response.data.student.userId,
              schoolId: response.data.student.school,
              role: response.data.student.role,
            });
            window.localStorage.setItem("name", response.data.student.name);
            window.localStorage.setItem("userId", response.data.student.userId);
            window.localStorage.setItem(
              "profileImageUrl",
              response.data.student?.profile?.fileUrl
            );
            window.localStorage.setItem("grade", response.data.student.grade);
            window.localStorage.setItem(
              "section",
              response.data.student.section
            );
            window.localStorage.setItem(
              "sectionId",
              response.data.student.sectionId
            );
            window.localStorage.setItem("language", "english");
            window.location.href = "/dashboard";
          } else {
            alert.show(response.message, { type: "error" });
            window.location.href = `${process.env.REACT_APP_REDIRECT}`;
          }
        });
    } catch (error) {
      alert.show(error, { type: "error" });
      console.log("Faced error");
      console.log(error);
      window.location.href = `${process.env.REACT_APP_REDIRECT}`;
    }
  };

  useEffect(() => {
    console.log("Now runnning getUserDetails...");
    getUserDetails();
  });

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <p style={{ marginTop: "2rem" }}>Authenticating, please wait..</p>
    </div>
  );
}

export default Auth;

import React from 'react';

const Info = (props) => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM16.875 7.5C16.875 8.53553 16.0355 9.375 15 9.375C13.9645 9.375 13.125 8.53553 13.125 7.5C13.125 6.46447 13.9645 5.625 15 5.625C16.0355 5.625 16.875 6.46447 16.875 7.5ZM13.125 13.125C12.0895 13.125 11.25 13.9645 11.25 15C11.25 16.0355 12.0895 16.875 13.125 16.875V22.5C13.125 23.5355 13.9645 24.375 15 24.375H16.875C17.9105 24.375 18.75 23.5355 18.75 22.5C18.75 21.4645 17.9105 20.625 16.875 20.625V15C16.875 13.9645 16.0355 13.125 15 13.125H13.125Z'
        fill={props.color ? props.color : '#F8AA9D'}
      />
    </svg>
  );
};

export default Info;

import React from 'react';
import { BarChart, Bar, Brush,Cell, XAxis, YAxis, CartesianGrid,Legend, Tooltip, Label,LabelList, ResponsiveContainer } from 'recharts';
import useStyles from '../../styles';
import { useMediaQuery } from 'react-responsive';
import {ReactComponent as Loader} from "../../../../../../images/analytics/ChartLoader.svg";

export default function CognitiveReport ({data,loading=false,heading=""}) {

    const isSmall = useMediaQuery({query:"(max-width: 472px)"})
    const classes = useStyles()
    const renderCustomizedLabels  = (props) => {
        const { x, y, width, height, value,stroke } = props;
        const radius = 10;
        console.log(props.value);
      
        return (
          <g>
            {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
            <text x={x + width / 2} y={y +height/2} fill={stroke}   textAnchor="middle" dominantBaseline="middle">
            {/* <foreignObject x={0} y={0} width={100} height={100}> */}
            {props.value?props.value:""}
            {/* </foreignObject> */}
             
            </text>
          </g>
        );
      };
      

    // const [data,setData] = React.useState([
    //                 {
    //                     Subject: 'English',
    //                     Knowledge: 52,
    //                     Understanding: 42,
    //                     Analysis: 72,
    //                     Application: 73,
    //                 },
    //                 {
    //                     Subject: 'Hindi',
    //                     Knowledge: 15,
    //                     Understanding: 20,
    //                     Analysis: 82,
    //                     Application: 83,
    //                 },
    //                 {
    //                     Subject: 'Maths',
    //                     Knowledge: 85,
    //                     Understanding: 82,
    //                     Analysis: 12,
    //                     Application: 13,
    //                 },
    //                 {
    //                     Subject: 'History',
    //                     Knowledge: 4,
    //                     Understanding: 52,
    //                     Analysis: 25,
    //                     Application: 23,
    //                 },
    //                 {
    //                     Subject: 'Science',
    //                     Knowledge: 65,
    //                     Understanding: 0,
    //                     Analysis: 2,
    //                     Application: 3,
    //                 },
    //                 {
    //                     Subject: 'Geography',
    //                     Knowledge: 63,
    //                     Understanding: 5,
    //                     Analysis: 77,
    //                     Application: 73,
    //                 },
    //             ])
    
    const getTotal=()=>{
        let total=0;
        console.log(data);
        data.forEach((item)=>{
            total+=item["Total Understanding"]+item["Total Analysis"]+item["Total Application"]+item["Total Knowledge"];
        })
        return total;
    }
    console.log(data);
    return (
        <div className={classes.container}> 
        {loading?<Loader/>:(<>
            <div className={classes.headingContainer}>
                <h3>Cognitive Level Report </h3>
                <p>Total Questions  {getTotal()}</p>
            </div>
        
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                width={300}
                height={250}
                data={data}
                barGap={2}
                margin={{
                    top: 25,
                    right: isSmall?3:5,
                    left: isSmall? -7 : 15,
                    bottom: isSmall?20:80,
                }}
                barSize={100}
                
                >
                <CartesianGrid strokeDasharray="3 6" />
                <XAxis dataKey="Subject" allowDataOverflow={true}>
                    <Label value="Subjects" offset={isSmall?-25:-30} position="insideBottom"/>
                </XAxis>
                <Brush dataKey="Subject" height={10} endIndex={data.length-1} />
                <YAxis label={{ value: "Questions ", angle: -90,offset: isSmall? 18:-2, position: 'insideLeft' }} />
                <Tooltip />
                    <Bar dataKey={"Knowledge"} stackId={"a"} fill="rgba(125, 230, 219, 1)">
                        <LabelList content={ renderCustomizedLabels} style={{fontWeight:"600",fontSize:".7rem"}} dataKey={"Knowledge"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(125, 230, 219, 1)" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Total Knowledge"} tooltipType="none" stackId={"a"} fill="rgba(125, 230, 219, 1)">
                        <LabelList content={ renderCustomizedLabels}  style={{fontWeight:"600",fontSize:".7rem"}} dataKey={"Total Knowledge"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#cccc" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Understanding"} stackId={"b"} fill="rgba(255, 152, 152, 1)">
                        <LabelList content={ renderCustomizedLabels}  style={{fontWeight:"600",fontSize:".7rem"}} dataKey={"Understanding"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(255, 152, 152, 1)" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Total Understanding"} tooltipType="none" stackId={"b"} fill="rgba(255, 152, 152, 1)">
                        <LabelList content={ renderCustomizedLabels}  style={{fontWeight:"600",fontSize:".7rem"}} dataKey={"Total Understanding"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#cccc" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Analysis"} stackId={"c"} fill="rgba(120, 234, 165, 1)">
                        <LabelList content={ renderCustomizedLabels}  style={{fontWeight:"600",fontSize:".7rem"}} dataKey={"Analysis"}  position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(120, 234, 165, 1)" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Total Analysis"} tooltipType="none" stackId={"c"} fill="rgba(120, 234, 165, 1)">
                        <LabelList content={ renderCustomizedLabels}  style={{fontWeight:"600",fontSize:".7rem"}} dataKey={"Total Analysis"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#cccc" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Application"} stackId={"d"} fill="#FF6A99">
                        <LabelList content={ renderCustomizedLabels}  style={{fontWeight:"600",fontSize:".7rem"}} dataKey={"Application"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#FF6A99" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Total Application"} tooltipType="none" stackId={"d"} fill="rgba(51, 148, 238, 1)">
                        <LabelList content={ renderCustomizedLabels}  style={{fontWeight:"600",fontSize:".7rem"}} dataKey={"Total Application"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#cccc" ></Cell> )}
                    </Bar>
                <Legend payload={[{
    id:"easy",
    value:"Knowledge",
    type:"rect",
    color:"#5CE0D2"
  },{
    id:"medium",
    value:"Understanding",
    type:"rect",
    color:"#FF7E7E"
  },
  {
    id:"difficult",
    value:"Analytics",
    type:"rect",
    color:"#56E58F"
  },
  {
    id:"application",
    value:"Application",
    type:"rect",
    color:"#FF6A99"
  }
  
  ]}/>
                </BarChart>
            </ResponsiveContainer>
            
            </>)}
        </div>
    );
 }


import React from 'react';

const Triangle = (props) => {
  return (
    <svg
      width='112'
      height='103'
      viewBox='0 0 112 103'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M65.8841 7.20041C63.2756 2.55075 59.7053 0 55.8144 0C51.9235 0 48.3386 2.55075 45.7447 7.20041L2.18702 85.0348C-0.392341 89.6407 -0.698367 94.0863 1.31266 97.5408C3.33826 101.01 7.40403 102.919 12.7376 102.919H98.8766C104.21 102.919 108.261 101.01 110.302 97.5408C112.327 94.1009 112.007 89.6553 109.427 85.0494L65.8841 7.20041Z'
        fill={props.color || '#FF5352'}
      />
    </svg>
  );
};

export default Triangle;

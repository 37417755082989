import React from "react";
import "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { NavLink } from "react-router-dom";
import {ReactComponent as Loader} from "../../../../../LearningResources/Assement/Questions/Loader/Loader.svg";
const formatTime = require("../../../utils/FormatTime")

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    
  },
  table: {
    minWidth: "auto",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 280,
  },
  tableRow: {
    textDecoration: "none",
    "& > td":{
        fontWeight:"bold"
    },
    "&:hover": {
      backgroundColor: "#e2ddddc2 !important",
    },
  },
  tableHeading: {
      ' & > th':{
          fontWeight: "bolder",
          fontSize:"1.02em"
      }
  }
}));

const convertArrayToString = (array) => {

  if(!array){
    return ""
  }
  array = array && array.map(item => item.name)
  return array.join(', ')
}

const TestTable = ({rows,loading,rowColor}) => {

  console.log(rows)
  const classes = useStyles();
  return (
    <div className={classes.tableContainer}>
      {!loading?<TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableHeading}>
              <TableCell align="center">No</TableCell>
              <TableCell align="center">Question</TableCell>
              <TableCell align="center">Subject</TableCell>
              <TableCell align="center">Topic</TableCell>
              <TableCell align="center">Difficulty Level</TableCell>
              <TableCell align="center">Cognitive Level</TableCell>
              <TableCell align="center">Time Spent</TableCell>
              <TableCell align="center">Correct/Incorrect</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { rows && rows.length ? rows.map((row, index) => (
              <TableRow
                key={`${row.subject}${index}`}
                className={classes.tableRow}
                style={{backgroundColor:index%2?rowColor:"white"}}
              >
                <TableCell scope="row" align="center">{index+1}</TableCell>
                <TableCell scope="row" align="center" style={{minWidth:"300px"}}>{row.questionId.question}</TableCell>
                <TableCell scope="row" align="center">{row.subjectId.name}</TableCell>
                <TableCell scope="row" align="center">{row.topicId.name}</TableCell>
                <TableCell align="center">{row.difficulty}</TableCell>
                <TableCell align="center">{row.cognitive}</TableCell>
                <TableCell align="center">{formatTime(row.timeTaken)}</TableCell>
                <TableCell align="center">{row.status==="passed"?"Correct":"Incorrect"}</TableCell>
              </TableRow>
            )):
            <TableRow>
                <TableCell scope="row" align="center">-</TableCell>
                <TableCell scope="row" align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
              </TableRow>
          }
          </TableBody>
        </Table>
      </TableContainer>
      : <Loader/>  
    }
    </div>
  );
};

export default TestTable;

import React from 'react';
import { BarChart, Bar, Brush,Cell, XAxis, YAxis, CartesianGrid,Legend, Tooltip, Label,LabelList, ResponsiveContainer } from 'recharts';
import useStyles from '../../styles';
import { useMediaQuery } from 'react-responsive';
import {ReactComponent as Loader} from "../../../../../../images/analytics/ChartLoader.svg";

export default function QuestionStatusReport ({data,loading=false}) {

    const isSmall = useMediaQuery({query:"(max-width: 472px)"})
    const classes = useStyles()


    // const [data,setData] = React.useState([
    //                 {
    //                     Subject: 'English',
    //                     Correct: 52,
    //                     Incorrect: 42,
    //                     Unattempted: 72,
    //                 },
    //                 {
    //                     Subject: 'Hindi',
    //                     Correct: 15,
    //                     Incorrect: 20,
    //                     Unattempted: 82,
    //                 },
    //                 {
    //                     Subject: 'Maths',
    //                     Correct: 85,
    //                     Incorrect: 82,
    //                     Unattempted: 12,
    //                 },
    //                 {
    //                     Subject: 'History',
    //                     Correct: 4,
    //                     Incorrect: 52,
    //                     Unattempted: 25,
    //                 },
    //                 {
    //                     Subject: 'Science',
    //                     Correct: 65,
    //                     Incorrect: 0,
    //                     Unattempted: 2,
    //                 },
    //                 {
    //                     Subject: 'Geography',
    //                     Correct: 63,
    //                     Incorrect: 5,
    //                     Unattempted: 77,
    //                 },
    //             ])
    const getTotal=()=>{
        let total=0;
        data.forEach((item)=>{
            total+=item["Correct"]+item["Incorrect"]+item["Unattempted"]
        })
        return total;
    }
    

    
    const renderCustomizedLabels  = (props) => {
        const { x, y, width, height, value,stroke } = props;
        const radius = 10;
        console.log(props.value);
      
        return (
          <g>
            {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
            <text x={x + width / 2} y={y +height/2} fill={stroke}   textAnchor="middle" dominantBaseline="middle">
            {/* <foreignObject x={0} y={0} width={100} height={100}> */}
            {props.value?props.value:""}
            {/* </foreignObject> */}
             
            </text>
          </g>
        );
      };
      

    return (
        
        <div className={classes.container}> 
        {loading?<Loader/>:(<>
            <div className={classes.headingContainer}>
                <h3>Questions Status  of All Subject</h3>
                <p>Total Questions  {getTotal()}</p>
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                width={300}
                height={250}
                data={data}
                barGap={0}
                margin={{
                    top: 25,
                    right: isSmall?3:5,
                    left: isSmall? -7 : 15,
                    bottom: isSmall?20:80,
                }}
                barSize={100}
                
                >
                <CartesianGrid strokeDasharray="3 6" />
                <XAxis dataKey="Subject" allowDataOverflow={true}>
                    <Label value="Subjects" offset={isSmall?-25:-30} position="insideBottom"/>
                </XAxis>
                <Brush dataKey="Subject" height={10} endIndex={data.length-1} />
                <YAxis label={{ value: "Questions ", angle: -90,offset: isSmall? 18:-2, position: 'insideLeft' }} />
                <Tooltip />
                    <Bar dataKey={"Correct"} fill="rgba(125, 230, 219, 1)">
                        <LabelList content={renderCustomizedLabels} style={{fontWeight:"800"}} dataKey={"Correct"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(125, 230, 219, 1)" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Incorrect"} fill="rgba(255, 152, 152, 1)">
                        <LabelList content={renderCustomizedLabels} style={{fontWeight:"800"}} dataKey={"Incorrect"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(255, 152, 152, 1)" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Unattempted"} fill="rgba(120, 234, 165, 1)">
                        <LabelList content={renderCustomizedLabels} style={{fontWeight:"800"}} dataKey={"Unattempted"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(120, 234, 165, 1)" ></Cell> )}
                    </Bar>
                <Legend></Legend>
                </BarChart>
            </ResponsiveContainer>
            </>)}
        </div>
    );
 }


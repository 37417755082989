import React from "react";

const fileType = (type) => {
  // const wordList = name.split(".")
  // const ty
  console.log(type);
  if (type == "png" || type == "jpg" || type == "jpeg" || type == "gif") {
    return "Image";
  } else if (type == "pdf" || type == "txt" || type==="url") {
    return "Document";
  } else if (
    type == "mp4" ||
    type == "mkv" ||
    type == "webm" ||
    type === "m4v"
  ) {
    return "Video";
  } else if (type == "swf") {
    return "swfVideo";
  } else if (type == "ppt" || type == "pptx" || type == "pps") {
    return "PowerPoint";
  } else if (type == "html") {
    return "Activity";
  } else if (type == "mp3") {
    return "Audio";
  } else if (type == "docx" || type == "doc") {
    return "WordDocument";
  } else {
    return "Unsupported";
  }
};

function TaskViewerPlayer({ module, metaData }) {
  console.log(module);
  console.log(metaData);
  //check if module has been fetched or not
  if (module && metaData) {
    //Detetct the type of main file in the module
    switch (fileType(metaData.fileType)) {
      //render the suitable player based on the file type to be shown
      case "Image":
        return (
          <img
            src={metaData?.resource?.fileUrl}
            alt={metaData?.resource?.fileUrl}
          />
        );
        break;

      case "Video":
        return (
          <video
            width="60%"
            style={{
              marginTop: "2rem",
              border: "3px solid #5ce0d2",
              borderRadius: "5px",
            }}
            controls
            controlsList="nodownload"
          >
            <source src={metaData?.resource?.fileUrl} type="video/mp4" />
            <source src={metaData?.resource?.fileUrl} type="video/ogg" />
            <source src={metaData?.resource?.fileUrl} type="video/swf" />
            Your browser does not support HTML video.
          </video>
        );
        break;
      case "Document":
      case "PowerPoint":
      case "WordDocument":
        return (
          <iframe
            id="pdf_iframe"
            src={metaData?.resource?.fileUrl}
            width="80%"
            height="800px"
            title={"Document"}
          ></iframe>
        );
        break;

      case "Activity":
        window.location.href = metaData?.resource?.fileUrl;
        break;

      //If none of the files match then return unsupported file message
      default:
        return <h4 style={{ marginTop: "2rem" }}>Unsupported media type</h4>;
    }
  } else {
    //Show loading message till module is fetched
    return <h4 style={{ marginTop: "100px" }}>Loading</h4>;
  }
}

export default TaskViewerPlayer;

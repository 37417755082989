import React from 'react';
import { PieChart, Pie,Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { makeStyles } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import {ReactComponent as Loader} from "../../../../../../images/analytics/ChartLoader.svg";

const PIE_CHART_COLORS = ["rgba(15, 147, 168, 1)","rgba(255, 137, 137, 1)","rgba(157, 171, 221, 1)"]

const useStyles = makeStyles({
    container:{
        width: '41%',
        height: '72%',
        boxShadow:"0px 0px 7px rgba(0, 0, 0, 0.25)",
        borderRadius:"17px",
        margin:"1rem 0px",
        border:"1px solid #B46B82",
        "& .recharts-default-legend":{
            paddingLeft:"300px !important"
        },
        textAlign:"center"
    },
    headingContainer:{
        textAlign:"center",
        "& > *":{
            marginBlockStart:"0em",
            marginBlockEnd:"0.2em",
        },
        "& > h3":{
            fontSize:"0.9em",
            marginBlockStart:"10px"
        },
        "& > p":{
            fontSize:"0.7em",
        }
    },
    response:{
     textAlign:"center"
    },
    "@media screen and (max-width: 768px)": {
      container: {
          width: '80%',
          // height: '60%',
          borderRadius: '10px',
          margin: "10px auto",
          height: '400px',
          display:"flex",
          flexDirection:"column",
      }
  },
  "@media screen and (max-width: 1000px)": {
    container:{
     "& .recharts-default-legend":{
       paddingLeft:"170px !important",
       fontSize:"0.8em"
   },
    }
 },
    "@media screen and (max-width: 472px)":{
        container:{
            width: '97%',
            // height: '60%',
            borderRadius: '10px',
            height: '400px',
            display:"flex",
            flexDirection:"column",
            margin:"7px auto",
            "& .recharts-default-legend":{
                paddingLeft:"200px !important",
                fontSize:"0.8em"
            },
            "& .recharts-wrapper":{
                height:"230px !important"
            }
        }
    }

})

const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {percent!==0&&`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };


// const data = [
//     { name: 'Correct', value: 40 },
//     { name: 'Incorrect', value: 30 },
//     { name: 'Unattempted', value: 65 }
//   ];


export default function DifficultyPieChart({data=[],loading=false}) {

    const classes = useStyles()
    const isSmall = useMediaQuery({query:"(max-width: 472px)"})
    const [width, setWidth] = React.useState(window.innerWidth);

    const updateWidthAndHeight = () => {
      setWidth(window.innerWidth);
      // setHeight(window.innerHeight);
    };

    React.useEffect(() => {
      window.addEventListener("resize", updateWidthAndHeight);
      return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

    return (
    <div className={classes.container}> 
    {loading?<Loader/>:(<>
        <div className={classes.headingContainer}>
            <h3>Questions Report</h3>
            <p>Total Question- {data[0]?.value+data[1]?.value+data[2]?.value}</p>
        </div>
      <ResponsiveContainer width="100%" className={classes.response}  height="100%">
        <PieChart width={400} height={400}>
            <Pie
                dataKey="value"
                isAnimationActive={false}
                data={data}
                cx="25%"
                cy="40%"
                outerRadius={isSmall?80:width<1000?85:120}
                fill="#8884d8"
                label={renderCustomizedLabel}
                labelLine={false}
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]} />
                ))}
            </Pie>
          <Tooltip />
          {isSmall?
            <Legend width="90%" height="fit-content" layout="vertical" verticalAlign="middle"/>:
            <Legend width="90%" height="fit-content" layout="vertical" verticalAlign="middle"/>
            }
        </PieChart>
      </ResponsiveContainer>
      </>)}
    </div>
    );
  }

import {useState,useEffect,useContext} from 'react';
import Mcq from './Mcq'
import OneWord from './OneWord'
import TrueFalse from './TrueFalse';
import { GlobalContext } from '../../../../../../context/GlobalState';
import Mmcq from './Mmcq';

function Question({type,data={},id}) {
    
    const {statistics,questionId}=data;
    const [question,setQuestion]=useState("");
    const [option,setOption]=useState([]);
    const [userAnswer,setUserAnswer]=useState("");
    const [stats,setStats]=useState({});
    const {userId}=useContext(GlobalContext).userDetails
    const [correctAnswer,setCorrectAnswer]=useState("");
    const [difficulty,setDifficulty]=useState("");
    // console.log(userId)
    console.log(data);

    useEffect(()=>{
        if(questionId&&statistics)
        {   
            setQuestion(questionId?.question);
            setOption(questionId?.options);
            setCorrectAnswer(questionId?.correctAnswers?.[type]);
            setDifficulty(questionId?.difficulty)
            let temp={};

            if(type==="mmcq")
            {
                let temp2={};

                console.log(statistics);
              

                statistics.forEach((item)=>{
                    let an=item?.answer?.[type];
                    if(item.userId===userId)
                    {
                        setUserAnswer(an);
                    }
                    an.forEach(item=>{
                        if(temp2[item])
                    {
                     temp2[item]+=1;
                    }
                    else{
                         temp2[item]=1;
                    } 
                    })
                    

                })
                setStats({...temp2});
            }
            else{
            statistics.forEach((item)=>{
                
                if(item?.answer?.[type])
                {
                    let b=item?.answer?.[type]
                    if(item.userId===userId)
                    {
                        setUserAnswer(b);
                    }
                    
                   if(temp[b])
                   {
                    temp[b]+=1;
                   }
                   else{
                        temp[b]=1;
                   }    
                }
               

                
            })
            setStats({...temp})
        }
           



        }
    },[statistics,questionId])
    // console.log(stats);
    // console.log(userAnswer);

    if(type==='mcq') 
    {return <Mcq no={id} difficulty={difficulty} userAnswer={userAnswer} question={question} option={option} crctAnswer={correctAnswer} stats={stats} />
    }
    if(type==='oneword') 
    {return <OneWord no={id} difficulty={difficulty} userAnswer={userAnswer} question={question} option={option} crctAnswer={correctAnswer} stats={stats} />
    }
    if(type==='TrueFalse'||type==="correctstatement"){
   return <Mcq no={id} difficulty={difficulty} userAnswer={userAnswer} question={question} option={option} crctAnswer={correctAnswer} stats={stats}/>
    }
   if(type==="mmcq")
   {
      return <Mmcq no={id} difficulty={difficulty} userAnswer={userAnswer} question={question} option={option}  crctAnswer={correctAnswer} stats={stats} />
   }
   else{
       return <div></div>
   }
}

export default Question

import React, { useState, useEffect,useContext } from 'react';
import SingleChapter from './SingleChapter/SingleChapter';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {GlobalContext} from "../../../context/GlobalState"
import {useMediaQuery} from 'react-responsive'
const {isDisabled} = require('../../LearningResources/util.js')

const AssessmentChapter = (props) => {

  const {userDetails,setUserDetails} = useContext(GlobalContext);
  const isSmall = useMediaQuery({query:"(max-width: 472px)"});

  const subjectId = props?.match?.params?.subjectId;
  const [pageData, setPageData] = useState({
    chapters: [],
    loading: true,
  });
  const { chapters, loading } = pageData;
  const selectedSubject = localStorage.getItem('selectedSubject') || 'Chapters';
  
  useEffect(() => {
    if (!subjectId) return;
    fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/subjects/${subjectId}`, {
      headers: {
        token: `${window.localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        let chapters = response?.data?.subject?.totalChapters ? response?.data?.subject?.chapters.filter(item=>item.lang===window.localStorage.getItem("language")):[]
        setPageData((data) => ({
          ...data,
          loading: false,
          chapters: chapters || [],
        }));
      })
      .catch((e) =>
        setPageData((data) => ({
          ...data,
          loading: false,
        }))
      );
  }, []);

  const getCurrentDate = ()=>{
    const date = new Date()
    const months = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    
    return `${date.getDate()} ${months[date.getMonth()]},${date.getFullYear()}`
  }

  useEffect(()=>{
    setUserDetails({...userDetails,selectedTab:"My Assessment"})
    setUserDetails({...userDetails,selectedPath:{
      subject:selectedSubject,
      chapter:"",
      topic:""
    }})
  },[])

  return (
    <section className='learningResources my-md-5 my-sm-1 p-lg-0 p-2 p-sm-2 py-sm-4 col-md-9 col-sm-12  mx-auto'>
      <div className='container'>
        {!isSmall && <div className='box mb-5 row' style={{ cursor: 'pointer',alignItems: 'center'}}>
          <span style={{display: 'flex',flexDirection: 'column',width: 'fit-content'}}>
            <h2 className='fw-bold col-sm-9 white_color' style={{ fontWeight: 'bolder',color: 'white' }} >{userDetails.grade}</h2>
            <h1 style={{ fontWeight: 'bolder',color: 'white'}} >Practice Assessment</h1>
          </span>
          <h5 className="col-sm-3" style={{marginLeft:"auto",fontSize:'90%',fontWeight:'bolder',marginBottom:"auto",textAlign:"right"}}>
            <CalendarTodayIcon style={{width:"17px"}}/> {getCurrentDate()}
          </h5>
          <p className=" white_color" style={{color:'white'}}>{selectedSubject + " > Chapter"}</p>
        </div>}
        <div className='row my-3 gy-4 col-15'>
          {loading
            ? 'Loading ...'
            : chapters.length
            ? chapters.map((val, i) => (
                <SingleChapter
                  key={i}
                  name={val.name}
                  chapterId={val._id}
                  selectedSubject={selectedSubject}
                  subjectId={subjectId}
                  num={val.totalTopics}
                  thumbnail={val.thumbnail}
                  disabled={ val.disabled || isDisabled(val.topics)}
                />
              ))
            : '0 chapters to display'}
        </div>
      </div>
    </section>
  );
};

export default AssessmentChapter;

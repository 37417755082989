import React from 'react';
import { BarChart, Bar, Brush,Cell, XAxis, YAxis, CartesianGrid,Legend, Tooltip, Label,LabelList, ResponsiveContainer } from 'recharts';
import useStyles from '../../styles';
import { useMediaQuery } from 'react-responsive';
import {ReactComponent as Loader} from "../../../../../../images/analytics/ChartLoader.svg";

export default function LearningStrength ({data=[],loading=false}) {

    const isSmall = useMediaQuery({query:"(max-width: 472px)"})
    const classes = useStyles()



    const renderCustomizedLabels  = (props) => {
        const { x, y, width, height, value,stroke } = props;
        const radius = 10;
        console.log(props.value);
      
        return (
          <g>
            {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
            <text x={x + width / 2} y={y +height/2} fill={stroke}   textAnchor="middle" dominantBaseline="middle">
            {/* <foreignObject x={0} y={0} width={100} height={100}> */}
            {props.value?props.value:""}
            {/* </foreignObject> */}
             
            </text>
          </g>
        );
      };
      
    // const [data,setData] = React.useState([
    //                 {
    //                     Subject: 'English',
    //                     Strength: 52,
    //                     Gap: 42,
    //                 },
    //                 {
    //                     Subject: 'Hindi',
    //                     Strength: 15,
    //                     Gap: 20,
    //                 },
    //                 {
    //                     Subject: 'Maths',
    //                     Strength: 85,
    //                     Gap: 82,
    //                 },
    //                 {
    //                     Subject: 'History',
    //                     Strength: 4,
    //                     Gap: 52,
    //                 },
    //                 {
    //                     Subject: 'Science',
    //                     Strength: 65,
    //                     Gap: 0,
    //                 },
    //                 {
    //                     Subject: 'Geography',
    //                     Strength: 63,
    //                     Gap: 5,
    //                 },
    //             ])
    

    return (
        <div className={classes.container}> 
        {loading?<Loader/>:(<>
            <div className={classes.headingContainer}>
                <h3>Learning Strength & Gap</h3>
                {/* <p>Total Questions Practiced- 500</p> */}
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                width={300}
                height={250}
                data={data}
                barGap={0}
                margin={{
                    top: 25,
                    right: isSmall?3:5,
                    left: isSmall? -7 : 15,
                    bottom: isSmall?20:80,
                }}
                barSize={100}
                
                >
                <CartesianGrid strokeDasharray="3 6" />
                <XAxis dataKey="Subject" allowDataOverflow={true}>
                    <Label style={{fontWeight:"800"}} value="Subjects" offset={isSmall?-25:-30} position="insideBottom"/>
                </XAxis>
                <Brush dataKey="Subject" height={10} endIndex={data.length-1} />
                <YAxis label={{ value: "Learning Strength & Gap", angle: -90,offset: isSmall? 18:-2, position: 'insideCenter' }} />
                <Tooltip />
                    <Bar dataKey={"Strength"} fill="rgba(125, 230, 219, 1)">
                        <LabelList content={renderCustomizedLabels} style={{fontWeight:"800"}} dataKey={"Strength"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(125, 230, 219, 1)" ></Cell> )}
                    </Bar>
                    <Bar dataKey={"Gap"} fill="rgba(255, 152, 152, 1)">
                        <LabelList content={renderCustomizedLabels} style={{fontWeight:"800"}} dataKey={"Gap"} position="top"/>
                        {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(255, 152, 152, 1)" ></Cell> )}
                    </Bar>
                <Legend></Legend>
                </BarChart>
            </ResponsiveContainer>
            </>)}
        </div>
    );
 }


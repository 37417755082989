import React from "react";
import TaskViewerPlayer from "./TaskViewerPlayer";
import "./styles/TaskViewer.css";
import { useLocation } from "react-router-dom";

const TaskURLViewer = () => {
  const { search } = useLocation();
  const getQuery = (search, name) => {
    if (!search) return;
    return new URLSearchParams(search).get(name);
  };
  return (
    <div className="media_player">
      <TaskViewerPlayer
        module={"url"}
        metaData={{
          resource: { fileUrl: getQuery(search, "url") },
          fileType: "url",
        }}
      ></TaskViewerPlayer>
    </div>
  );
};

export default TaskURLViewer;

import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import {ReactComponent as SideBoardButton} from "../../../images/analytics/SideboardControl.svg"
import axios from "axios"
import {GlobalContext} from "../../../context/GlobalState"

const useStyles = makeStyles({
    root:{
        width:"fit-content",
        height:"fit-content",
        position:"absolute",
        top:"100px",
        backgroundColor:"white",
        transition:"0.3s",
        zIndex:"10",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
        "& MuiAccordionSummary-content":{
            fontSize:"0.9em !important",
        },
        "& .Mui-expanded":{
            height:"fit-content",
            minHeight:"20px !important",
            margin:"0px !important",
            background: "rgba(245, 161, 159, 0.68)",
            // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
            "& .MuiAccordionSummary-content":{
                paddingLeft:"20px "
            }
        },
        "& .MuiAccordionDetails-root":{
            padding:"0px"
        }
    },
    sideBoardButton:{
        position: "absolute",
        right:"-40px",
        top:"0px",
        marginRight:"0px",
        width:"40px",
        height:"48px",
        background:"rgba(240, 128, 128, 1)",
        border:"none",
        borderRadius:"0px 50% 50% 0px",
        "& svg":{
            transition:"0.2s",
            transform:"rotate(180deg)"
        }
    },
    accordion: {
        display: 'flex',
        flexDirection: 'column',
        background: "white",
    },
    "@media screen and (max-width: 472px)":{
        root:{
            width:"80% !important"
        }
    }
})

function SideBoard({filterData,setFilterData,setData=()=>{},headerData={},setSubjectData=()=>{},disabled=false}) {
    
    const classes = useStyles()
    const {userDetails} = React.useContext(GlobalContext)

    const [open,setOpen] = React.useState(false)
    const [isExpanded,setIsExpanded] = React.useState({})
    const [subjects,setSubjects] = React.useState([])
    const [chapters,setChapters]=React.useState([]);
    const [topics,setTopics]=React.useState([]);

    //Function to fetch the data for sideboard 
    const fetchData = async() => {
        // /grades/:grade/sections/section/allresources
        try{
            // const response = await axios.get(`${process.env.REACT_APP_ORIGIN2}/api/v1/students/grades/${userDetails.grade}/sections/${userDetails.section}/allresources`,{
            //     headers:{
            //         token:`${window.localStorage.getItem('authToken')}`
            //     }
            // })
            const response = await axios.get(`${process.env.REACT_APP_ORIGIN2}/api/v1/grades/${userDetails.grade}/sections/${userDetails.section}/subjects`,{
                headers:{
                    token:`${window.localStorage.getItem('authToken')}`
                }
            })
            console.log("Sideboard data response ===> ",response)
            console.log(window.localStorage.getItem("language"));
            let dt1=response.data.data.subjects.filter(item=>item.subjectData.lang === window.localStorage.getItem("language")&&!item.subjectData.disabled)
            console.log(dt1);
            setSubjects(dt1.map(item=>{return{...item,_id:item.subjectId}}));

            let dt=response.data.data.subjects.filter(item=>item.subjectData.lang === window.localStorage.getItem("language")&&!item.subjectData.disabled)
            setSubjectData(dt.map(item=>{return{name:item.subject,_id:item.subjectId}}))
        }catch(error){
            console.log(error)
        }
    }
    
    //fucntion to update states upon clicking the subject
    const handleSubject = (_subjectId,subject) => {
        setFilterData({...filterData,...{subjectId:_subjectId === filterData.subjectId?"":_subjectId,chapterId:"",topicId:""}})
        setData({...headerData,subject:headerData?.subject===subject?"":subject,chapter:"",topic:""})
    }

    //fucntion to update states upon clicking the chapter
    const handleChapter = (_chapterId,chapter) => {
        setFilterData({...filterData,...{chapterId:_chapterId === filterData.chapterId?"":_chapterId,topicId:""}})
        setData({...headerData,chapter:headerData?.chapter===chapter?"":chapter,topic:""})
    }    

    //fucntion to update states upon clicking the topic
    const handleTopic = (_topicId,topic) => {
        setFilterData({...filterData,...{topicId:_topicId === filterData.topicId?"":_topicId}})
        setData({...headerData,topic:headerData?.topic?"":topic?.name})
    }

    console.log(filterData)

    
    //function to toggle the sidebar
    const toggleDrawer = () => {
    
        if(!document.getElementById("sideboard")) return
    
        if(open){
            document.getElementById("sideboard").style.left=`-${document.getElementById("sideboard").offsetWidth}px`
            document.getElementById("sideboard-btn-svg").style.transform = "rotate(180deg)"
        }else{
            document.getElementById("sideboard").style.left="0px"
            document.getElementById("sideboard-btn-svg").style.transform = "rotate(0deg)"
        }
        setOpen(!open)
    };
    
    React.useEffect(() => {
        fetchData()
        document.getElementById("sideboard").style.left = `-${document.getElementById("sideboard").offsetWidth}px`;
        
    },[])
    React.useEffect(()=>{
        if(subjects.length)
        {
        document.getElementById("sideboard").style.left = `-${document.getElementById("sideboard").offsetWidth}px`
        }
    },[subjects])

    const fetchChapter=async()=>{
        const response = await axios.get(`${process.env.REACT_APP_ORIGIN2}/api/v1/subjects/${filterData.subjectId}`,{
            headers:{
                token:`${window.localStorage.getItem('authToken')}`
            }
        })
        let dt1=response.data.data.subject.chapters.filter(item=>item.lang === window.localStorage.getItem("language")&&!item.disabled)
        // console.log(window.localStorage.getItem("language"));
        setChapters(dt1);
    }

    const fetchTopic=async()=>{
        const response = await axios.get(`${process.env.REACT_APP_ORIGIN2}/api/v1/chapters/${filterData.chapterId}`,{
            headers:{
                token:`${window.localStorage.getItem('authToken')}`
            }
        })
        let dt1=response.data.data.chapter.topics.filter(item=>item.lang === window.localStorage.getItem("language")&&!item.disabled)
        // console.log(window.localStorage.getItem("language"));
        // setChapters(dt1);
        setTopics(dt1);
    }
    React.useEffect(()=>{
        if(filterData.chapterId)
        {
            fetchTopic();
        }
        else{
            setTopics([]);
        }

    },[filterData.chapterId])
    React.useEffect(()=>{
        if(filterData.subjectId)
        {
            fetchChapter();
        }
        else{
            setChapters([]);
        }

    },[filterData.subjectId])


    const TopicsAccordion = ({topic}) =>
        <Accordion>
            <AccordionSummary
                expanded = {filterData.topicId == topic._id}
                onClick={()=>{!disabled&&handleTopic(topic._id,topic)}}
                style={{
                    "& .MuiAccordionSummary-content":{
                        minHeight:"48px"
                    }
                }}
            >
                <Typography className={classes.heading}>&nbsp;&nbsp;&nbsp;&nbsp;{topic.name}</Typography>
            </AccordionSummary>
        </Accordion> 

    const ChapterAccordion = ({chapter}) => 
        <Accordion
            expanded = {filterData.chapterId == chapter._id}
            onChange={()=>{!disabled&&handleChapter(chapter._id,chapter.name)}}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>&nbsp;&nbsp;{chapter.name}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordion} >
                {topics.map(topic => <TopicsAccordion topic={topic} />)}
            </AccordionDetails>
        </Accordion>

    const SubjectAccordion = ({subject}) => 
        <Accordion 
            expanded = {filterData.subjectId == subject._id}
            onChange={()=>{!disabled&&handleSubject(subject._id,subject.subject)}}
        >
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id={subject._id}
            >
                <Typography className={classes.heading}>{subject.subject}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordion} >
            {chapters.map(chapter=> <ChapterAccordion chapter={chapter}/>)}
            </AccordionDetails>
        </Accordion>
    console.log(disabled);
    return (
        <div style={{opacity:`${disabled?"0.7":"1"}`,backgroundColor:"#cccc"}} className={classes.root} id="sideboard">            
            <span>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Subjects</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordion} >
                    {subjects.map(subject => <SubjectAccordion subject={subject}/>)}
                </AccordionDetails>
            </Accordion>
            </span>
            <button onClick={toggleDrawer} className={classes.sideBoardButton} type="button"> 
                <SideBoardButton id="sideboard-btn-svg"/>
            </button>
        </div> 
    )
}

export default SideBoard

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import {ReactComponent as Open} from "../../images/Navbar/open.svg"
import {ReactComponent as Close} from "../../images/Navbar/close.svg"

const useStyles = makeStyles({
    root:{
        position:"sticky",
        top:"55px",
        zIndex:"1",
        marginTop:"-5px"
    },
    accordion:{
        display:"flex",
        flexDirection:"column-reverse",
        padding:"0px"
    },
    expandIcon:{
        position:"absolute",
        top:"0",
        padding:"0"
    },
    summary:{
        padding:"0px",
        "& .MuiPaper-root":{
            margin:"auto"
        },
        "& .box":{
            width:"100%",
            "& .header":{
                borderRadius:"0px !important",
                "& .headerRight":{
                    alignItems:"center",
                    margin:"auto"
                }
            }
        }
    }
})

const SliderButton = ({open})=>(
    <Button style={{backgroundColor:open?"#edfcfa":"#5ce0d2"}}>
        {open?<Close style={{transform:"rotate(180deg)"}}></Close>:<Open ></Open>}
    </Button>
)

export default function Drawer(props) {

    const classes = useStyles()
    const [expanded,setExpanded] = React.useState(false)

    return (
        <div className={classes.root}>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<SliderButton open={expanded} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={{
                        expandIcon: classes.expandIcon
                    }}
                    onClick={()=>{setExpanded(!expanded)}}
                >
                </AccordionSummary>
                <AccordionDetails className={classes.summary} id="details">
                    {props.children}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { Button, Divider } from '@material-ui/core';
import { useAlert } from 'react-alert';
import { GlobalContext } from '../../context/GlobalState';
import axios from 'axios';

const useStyles = makeStyles({
  root: {
    minWidth: 'auto',
    marginBottom: '30px',
    border:"none !important",
    boxShadow:"0px 1px 5px #0000004d !important",
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDivider: {
    // boxShadow: "0px 4px 6px rgba(51, 122, 115, 0.16)",
    backgroundColor: '#9c9191',
  },
  paragraph_answer: {
    width: '100%',
  },
  submitBtn: {
    backgroundColor: '#5CE0D2',
    borderRadius: '7px',
    width: '200px',
    '&:hover': {
      backgroundColor: '#5CE0D2',
    },
  },
  radioInput: {
    '&:checked': {
      backgroundColor: '#5CE0D2',
      borderColor:"#5CE0D2"
    }
  }
});

const Question = ({ questions, taskId, isSubmitted }) => {
  const alert = useAlert();
  const classes = useStyles();
  const [sAnswers, setAnswers] = useState({});
  const { grade, section, authToken } = useContext(GlobalContext).userDetails;

  const handleSubmitAnswers = async () => {
    try {
      const data = { answers: Object.values(sAnswers) };
      if (Object.keys(questions).length !== Object.keys(sAnswers).length)
        return alert.error('All questions should be attempted!');
      let url = `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/tasks/${taskId}/submittests`;
      await axios.post(url, data, {
        headers: {
          token: authToken,
        },
        transformResponse: [
          (response) => {
            const data = JSON.parse(response);
            console.log(data, data?.message);
            if (data.status === 'success') return data;
            if (data?.status === 'fail') {
              alert.error(data?.message);
            }
          },
        ],
      });
      isSubmitted(true);
    } catch (e) {
      // alert.error(e.message);
    }
  };
  return (
    <>
      <div className='text-center mb-4'>
        <small className='text-muted '>
          Do not hit browser back button or refresh the page. Doing so will
          restart the test & you’ll lose your progress.
        </small>
      </div>
      {questions &&
        questions.map((que, idx) => (
          <Card className={classes.root} variant='elevation' key={idx}>
            <CardHeader title={`Question ${idx + 1}`} />
            <Divider className={classes.cardDivider} />
            <CardContent>
              <h3>{`${que.question}`}</h3>
              <div>
                {que.questionType === 'mcq' ? (
                  que.options.map((op, index) => (
                    <div className='form-check' key={index}>
                      <input
                        className={'form-check-input '+classes.radioInput}
                        type='radio'
                        name={`question${idx + 1}`}
                        onClick={() => {
                          setAnswers({
                            ...sAnswers,
                            [`question${idx + 1}`]: {
                              answer: op.id,
                              questionId: que.questionId,
                            },
                          });
                        }}
                      />
                      <label className='form-check-label' style={{fontWeight: 'bold'}}>{op.option}</label>
                    </div>
                  ))
                ) : (
                  <TextField
                    id='filled-basic'
                    className={classes.paragraph_answer}
                    label='Answer'
                    variant='filled'
                    multiline
                    /* styles the input component */
                    inputProps={{
                      style: {
                        height: '200px',
                      },
                    }}
                    onChange={(e) => {
                      setAnswers({
                        ...sAnswers,
                        [`question${idx + 1}`]: {
                          answer: e.target.value,
                          questionId: que.questionId,
                        },
                      });
                    }}
                  />
                )}
              </div>
            </CardContent>
          </Card>
        ))}
      <div className='text-center'>
        <Button
          variant='contained'
          className={classes.submitBtn}
          onClick={handleSubmitAnswers}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default Question;

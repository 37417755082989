import React, { useState, useEffect, useContext, useMemo } from "react";
import SingleChapter from "./SingleChapter";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalState";
import { useMediaQuery } from "react-responsive";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min.js";
import Module from "../Module.jsx";
import { groupModulesByCategory } from "../../../utils/tools.js";
const { isDisabled } = require("../util.js");

const ChapterPage = (props) => {
  const params = useParams();
  const { userDetails, setUserDetails } = useContext(GlobalContext);
  const history = useHistory();
  const location = useLocation();
  const isSmall = useMediaQuery({ query: "(max-width: 472px)" });

  const subjectId = props?.match?.params?.subjectId;
  const [pageData, setPageData] = useState({
    chapters: [],
    loading: true,
  });
  const [modules, setModules] = useState({
    modules: [],
    loading: true,
  });

  const { chapters, loading } = pageData;
  const selectedSubject = localStorage.getItem("selectedSubject") || "Chapters";

  useEffect(() => {
    if (!subjectId) return;
    fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/subjects/${subjectId}`, {
      headers: {
        token: `${window.localStorage.getItem("authToken")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        let chapters = response?.data?.subject?.totalChapters
          ? response?.data?.subject?.chapters.filter(
              (item) => item.lang === window.localStorage.getItem("language")
            )
          : [];
        setPageData((data) => ({
          ...data,
          loading: false,
          chapters: chapters || [],
        }));
      })
      .catch((e) =>
        setPageData((data) => ({
          ...data,
          loading: false,
        }))
      );
  }, []);

  useEffect(() => {
    const grade = window.localStorage.getItem("grade");
    const section = window.localStorage.getItem("section");
    const language = window.localStorage.getItem("language") || "english";
    const tempOrigin = "https://api.2xcell.in";
    fetch(
      `${tempOrigin}/api/v1/grades/${grade}/sections/${section}/topics/${subjectId}/modules?lang=${language}&folder=subject`,
      {
        headers: {
          token: `${window.localStorage.getItem("authToken")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        const modules = response?.data?.modules || [];
        setModules({
          loading: false,
          modules: modules,
        });
      })
      .catch((e) =>
        setModules((data) => ({
          ...data,
          loading: false,
        }))
      );
  }, []);

  const getCurrentDate = () => {
    const date = new Date();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return `${date.getDate()} ${months[date.getMonth()]},${date.getFullYear()}`;
  };

  const updateQuery = (key, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    history.push({ search: searchParams.toString() });
  };

  const getQuery = (key) => {
    const params = new URLSearchParams(location.search);
    return params.get(key);
  };

  const folder = useMemo(() => {
    return getQuery("folder") || "chapter";
  }, [location]);

  const groupedModules = useMemo(() => {
    return groupModulesByCategory(modules.modules);
  }, [modules]);

  useEffect(() => {
    setUserDetails({ ...userDetails, selectedTab: "2Xcell Resources" });
    setUserDetails({
      ...userDetails,
      selectedPath: {
        subject: selectedSubject,
        chapter: "",
        topic: "",
      },
    });
  }, []);

  return (
    <section className="learningResources my-md-5 my-sm-1 p-lg-0 p-2 p-sm-2 py-sm-4 col-md-9 col-sm-12  mx-auto">
      <div className="container">
        {!isSmall && (
          <div
            className="box mb-5 row"
            style={{ cursor: "pointer", alignItems: "center" }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
              }}
            >
              <h2
                className="fw-bold col-sm-12 white_color"
                style={{ fontWeight: "bolder", color: "white" }}
              >
                {userDetails.grade} - {userDetails.section}
              </h2>
            </span>
            <h5
              className="col-sm-3"
              style={{
                marginLeft: "auto",
                fontSize: "90%",
                fontWeight: "bolder",
                marginBottom: "auto",
                textAlign: "right",
              }}
            >
              <CalendarTodayIcon style={{ width: "17px" }} /> {getCurrentDate()}
            </h5>
            <p className=" white_color fw-bold" style={{ color: "white" }}>
              {selectedSubject + " > "}
            </p>
          </div>
        )}

        <div
          className="button_container"
          style={{ 
            justifyContent:"space-between",
            display: modules.modules.length ? "flex" : "none" 
          }}
        >
          <button
            type="button"
            className={folder === "chapter" ? "selected_language" : ""}
            onClick={() => {
              updateQuery("folder", "chapter");
            }}
          >
            CHAPTERS
          </button>
          <button
            type="button"
            className={folder === "module" ? "selected_language" : ""}
            onClick={() => {
              updateQuery("folder", "module");
            }}
          >
            TEACHER RESOURCES
          </button>
        </div>

        {folder === "chapter" ? (
          <div className="row my-3 gy-4">
            {loading
              ? "Loading ..."
              : chapters.length
              ? chapters.map((val, i) => (
                  <SingleChapter
                    key={i}
                    name={val.name}
                    chapterId={val._id}
                    selectedSubject={selectedSubject}
                    subjectId={subjectId}
                    num={val.totalTopics}
                    thumbnail={val.thumbnail}
                    disabled={val.disabled || isDisabled(val.topics)}
                  />
                ))
              : "0 chapters to display"}
          </div>
        ) : (
          groupedModules.map((mod) => {
            return (
              <Module
                heading={mod.categoryName}
                hide={mod.modules.length ? false : true}
                modules={mod.modules}
                fetched={[]}
              />
            );
          })
        )}
      </div>
    </section>
  );
};

export default ChapterPage;

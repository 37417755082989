import React from 'react';
import '../../../LearningResources/styles/LearningResources.css';
import { NavLink,useParams } from 'react-router-dom';
import { image } from '../../../helpers';

//convert html, used for url link of each card in different language
function decodeHTMLEntities(text) {
  var textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}

const fixName = (name) => {
  const charArrays = ["?", "+", "&", "/"];
  charArrays.forEach((c) => {
    name = name.replaceAll(c, "");
  });
  return name;
}

//function to fix the thumbnail url
const fixThumbnailUrl = (url) => {
  if (!url) {
    return '';
  }

  url = url.split(' ').join('%20');
  if (url.startsWith('https://xcell-cms-files.s3.amazonaws.com')) {
    return url;
  } else {
    return 'https://xcell-cms-files.s3.amazonaws.com' + url;
  }
};

const SingleChapter = (props) => {
  const params = useParams()
  return (
    <div className='col-sm-2 col-6' style={{display:props.disabled?'none':'block'}}>
      <NavLink
        style={{ textDecoration: 'none' }}
        to={`/myassessment/practice/subjects/${props.subjectId}/${fixName(params.subjectName)}/chapters/${props.chapterId}/${fixName(props.name)}/topics`}
      >
        <div className='card card-height card-radius'>
          <div className='card-img'>
            <img
              src={image(fixThumbnailUrl(props.thumbnail))}
              className='card-thumbnail'
              alt={props?.name || 'sub'}
            />
          </div>
          {/* <div className="text-center" style={LearningResourcesStyle}> */}
          <div className='text-center text-dark'>
            <p
              dangerouslySetInnerHTML={{ __html: props.name }}
              className='mar-p'
            ></p>
            {/* <p>Topics : {props.num}</p> */}
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default SingleChapter;

import React, { useState,  useContext } from 'react';
import axios from 'axios';
import { GlobalContext } from '../../context/GlobalState';
import { useAlert } from 'react-alert';
import DocumentLoading from '../Loading/ExportLoader';

const UploadResponse = (props) => {
  const alert = useAlert();
  const [file, getFile] = useState(undefined);
  const [uploading,setUploading]=useState(false);
  const { grade, section, authToken } = useContext(GlobalContext).userDetails;

  const handleFileChange = (e) => {
    return getFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    try {
      if (!file) return alert.error('No pdf attached!');
      setUploading(true);
      const taskId = props.match.params.assesmentId;
      const formData = new FormData();
      formData.append('pdf', file);
      let url = `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/tasks/${taskId}/submittasks`;
      const submit = await axios.post(url, formData, {
        headers: {
          'content-type': 'multipart/form-data',
          token: authToken,
        },
        transformResponse: [
          (response) => {
            const data = JSON.parse(response);
            if (data.status === 'success'){
              return data;
            }else{
              throw new Error(data.message);
            }
          },
        ],
      });
      if (submit.data.status === 'success') {
        alert.show('Task Submitted!');
      }else{
        alert.show(submit.data.message);
      }
      setUploading(false);
    } catch (e) {
      alert.error(e.message);
      setUploading(false);
    }
  };
  return (
    <>
      <section className='uploadResponse my-5'>
        <div className='container'>
          {/* <div>
            {
             (
                <img
                  src={UploadResponseImage}
                  alt="UploadResponseImage"
                  className="img-fluid"
                />
              )
            }
          </div> */}

          <div className='row'>
            <div className='col-sm-6'>
              <div className='my-4'>
                <label htmlFor='formFile' className='form-label'>
                  Upload Response
                </label>
                <input
                  className='form-control'
                  type='file'
                  id='formFile'
                  accept='.pdf,.png,.jpg,.doc,.docx,.ppt,.pptx,.txt'
                  onChange={handleFileChange}
                />
                <small>File Format: Size: 2-5 mb</small>
                <button
                  onClick={handleFileUpload}
                  className='btn'
                  style={{
                    display: 'block',
                    marginTop: '.5rem',
                    border: '1px solid #333',
                  }}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
        {uploading&&<DocumentLoading />}
      </section>
    </>
  );
};

export default UploadResponse;

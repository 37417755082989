import React, { useEffect } from "react";
import { head, input, remarkStyle, signature } from "../../helper";
import "./mcqCard.css";
import DefaultImage from "../../../../../images/Navbar/title.png";
import { htmlDecode } from "../../../../../utils/parse";

function McqCard({
  color = "",
  data,
  number,
  correctAnswer,
  setCorrectAnswer,
}) {
  const question = data;
  const handleClick = (e) => {
    setCorrectAnswer(e.target.value);
  };

  //Uncheck all the options when a new question is rendered
  useEffect(() => {
    const radios = document.querySelectorAll(".mcq_radio");
    radios.forEach((radio) => {
      radio.checked = false;
    });
  }, [number]);

  return (
    <>
      <div className="container question_card  my-4">
        <div className="superhead">
          <div className="remark" style={remarkStyle(color)}>
            Select Single Option{" "}
          </div>
          <div className="mcq__head" style={head(color)}>
            <div className="mcqCard">
              <div className="mcqCard__left">
                <h6
                  style={{ fontWeight: "bolder", marginTop: "5px" }}
                  dangerouslySetInnerHTML={{
                    __html: htmlDecode(`Q${number}. ${question?.question}`),
                  }}
                ></h6>
              </div>
            </div>
            <div className="main_div">
              {question?.images?.questionImg ? (
                <div className="question_image">
                  <img
                    className="q-img"
                    src={question.images?.questionImg}
                    alt="mcq-question"
                  />
                </div>
              ) : null}
              <div
                className="mcq"
                style={
                  question?.images?.op1 ? { width: "70%" } : { width: "80%" }
                }
              >
                <div className="up top-left">
                  <label className="opt1-container" style={input(color)}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: htmlDecode(question?.options[0]?.value || "-"),
                      }}
                    ></span>
                    <input
                      className="mcq_radio"
                      type="radio"
                      name="radio"
                      value={question?.options[0]?.optionId}
                      onClick={handleClick}
                    />
                    <span className="checkmark" style={input(color, 3)}></span>
                    <div
                      className="imgBlock"
                      style={
                        question?.images?.op2
                          ? {
                              background: `url(${question?.images?.op2}) no-repeat center center`,
                              backgroundSize: "cover",
                            }
                          : { display: "none" }
                      }
                    ></div>
                  </label>
                </div>
                <div className="up top-right">
                  <label className="opt1-container" style={input(color)}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: htmlDecode(question?.options[1]?.value || "-"),
                      }}
                    ></span>
                    <input
                      className="mcq_radio"
                      type="radio"
                      name="radio"
                      value={question?.options[1]?.optionId}
                      onClick={handleClick}
                    />
                    <span className="checkmark" style={input(color, 3)}></span>
                    <div
                      className="imgBlock"
                      style={
                        question?.images?.op3
                          ? {
                              background: `url(${question?.images?.op3}) no-repeat center center`,
                              backgroundSize: "cover",
                            }
                          : { display: "none" }
                      }
                    ></div>
                  </label>
                </div>
                <div className="up bottom-left">
                  <label className="opt1-container" style={input(color)}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: htmlDecode(question?.options[2]?.value || "-"),
                      }}
                    ></span>
                    <input
                      className="mcq_radio"
                      type="radio"
                      name="radio"
                      value={question?.options[2]?.optionId}
                      onClick={handleClick}
                    />
                    <span className="checkmark" style={input(color, 3)}></span>
                    <div
                      className="imgBlock"
                      style={
                        question?.images?.op3
                          ? {
                              background: `url(${question?.images?.op3}) no-repeat center center`,
                              backgroundSize: "cover",
                            }
                          : { display: "none" }
                      }
                    ></div>
                  </label>
                </div>
                <div className="up bottom-right">
                  <label className="opt1-container" style={input(color)}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: htmlDecode(question?.options[3]?.value || "-"),
                      }}
                    ></span>
                    <input
                      className="mcq_radio"
                      type="radio"
                      name="radio"
                      value={question?.options[3]?.optionId}
                      onClick={handleClick}
                    />
                    <span className="checkmark" style={input(color, 3)}></span>
                    <div
                      className="imgBlock"
                      style={
                        question?.images?.op4
                          ? {
                              background: `url(${question?.images?.op4}) no-repeat center center`,
                              backgroundSize: "cover",
                            }
                          : { display: "none" }
                      }
                    ></div>
                  </label>
                </div>
              </div>
            </div>
            <div className="last">
              {question.onModel === "admin" && question.creatorId ? (
                <img
                  src={DefaultImage}
                  className="signarture"
                  style={{ ...signature(color), width: "50px" }}
                />
              ) : (
                <div className="signarture" style={signature(color)}>
                  {question.creatorId ? question.creatorId.name : ""}
                  <br />
                  {question.schoolId.name}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default McqCard;

import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import "./styles/TaskTopic.css";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useAlert } from "react-alert";
import axios from "axios";
import { createFilteredTask } from "../functions";

import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalState";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    border: "2px solid black",
    borderRadius: "10px",
  },
  table: {
    minWidth: "auto",
  },
  uploadBtn: {
    backgroundColor: "#5CE0D2",
    borderRadius: "27px",
    "&:hover": {
      backgroundColor: "#5CE0D2",
    },
    textTransform: "none !important",
    fontWeight: "bold !important",
  },
}));

const TaskTopic = (props) => {
  const alert = useAlert();
  const history = useHistory();
  const { grade, section, authToken } = useContext(GlobalContext).userDetails;
  const [task, getTaskData] = useState(null);

  const getRedirectLink = (task) => {
    const path = task.url
      ? `resource?url=${task.url}`
      : `modules/${task.content?._id}`;
    return `/tasklist/task/${task._id}/${path}`;
  };

  useEffect(() => {
    const getTask = async () => {
      try {
        let { taskid } = props.match.params;
        const taskData = await axios(
          `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/tasks/${taskid}`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        console.log("Task response->", taskData);
        if (taskData.data.status === "success") {
          getTaskData(createFilteredTask(taskData.data.data.task));
        }
      } catch (e) {
        alert.error("Task Not Found");
        console.log(e);
      }
    };

    getTask();
  }, [authToken, grade, section, alert, props]);
  document.title = "2xcell.in";
  const classes = useStyles();

  return (
    <>
      <section className="taskTopic my-5">
        <div className="container">
          <div className="box mb-5">
            <h1 className="fw-bold">{task ? task.topic : "Topic"}</h1>
          </div>

          {task ? (
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Subject</TableCell>
                    <TableCell align="center">Chapter</TableCell>
                    <TableCell align="center">Topic</TableCell>
                    <TableCell align="center">Module</TableCell>
                    <TableCell align="center">Task Type</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row" align="center">
                      {task.subject}
                    </TableCell>
                    <TableCell align="center">{task.chapter}</TableCell>
                    <TableCell align="center">{task.topic}</TableCell>
                    <TableCell align="center">
                      {task.onModel === "test" ? (
                        task.content?.name //Display only name if task is a test, else give option to redirect to module
                      ) : (
                        <Link
                          to={getRedirectLink(task)}
                          style={{ color: "blue" }}
                          target="_blank"
                        >
                          {task.url || task.content?.name}
                        </Link>
                      )}
                    </TableCell>
                    <TableCell align="center">{task.taskType}</TableCell>
                    <TableCell align="center">{task.to}</TableCell>
                    <TableCell align="center">
                      <Button
                        size="small"
                        variant="contained"
                        className={classes.uploadBtn}
                        disabled={!task.submission}
                        onClick={() => {
                          if (task.onModel === "test") {
                            return history.push(
                              `/test/${task.content.topicId}/${task._id}/${task.content._id}`
                            );
                          } else {
                            return history.push(
                              `/learningresources/modules-assesment/uploadresponse/${task._id}`
                            );
                          }
                        }}
                      >
                        Upload
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <h4>Loading...</h4>
          )}
        </div>
      </section>
    </>
  );
};

export default TaskTopic;

import { useState } from 'react';
import DescCard from './Questions/descCard/DescCard';
import FillBlankCard from './Questions/FillBlankCard/FillBlankCard';
import McqCard from './Questions/McqCards/McqCard';
import MmcqCard from './Questions/MmcqCards/MmcqCard';
import OneWord from './Questions/OneWord/OneWord';
import TFCard from './Questions/TFCard/TFCard';
import {ReactComponent as Loader} from './Questions/Loader/Loader.svg'


const useSlider = (color) => {
  const [index, setIndex] = useState(0);
  const [data] = useState();

  const handleSlide = (e, prev = false) => {
    if (!prev) {
      if (data[index + 1]) {
        return setIndex((state) => state + 1);
      }
      return setIndex(0);
    }
    if (data[index - 1]) return setIndex((state) => state - 1);
    return setIndex(data.length - 1);
  };

  const showSlide = (question,questionCount,correctAnswer,setCorrectAnswer,onlineTestId,loading) => {
    const props = question;
    const type = question?.questionType;
    if(!question || !question?.question || loading) return <Loader/>
    if (type === 'mcq') return <McqCard data={props} correctAnswer={correctAnswer} setCorrectAnswer={setCorrectAnswer} number={questionCount} color={color} onlineTestId={onlineTestId} />;
    if (type === 'mmcq') return <MmcqCard data={props} correctAnswer={correctAnswer} setCorrectAnswer={setCorrectAnswer} number={questionCount} color={color} onlineTestId={onlineTestId} />;
    if (type === 'fillup') return <FillBlankCard data={props} correctAnswer={correctAnswer} setCorrectAnswer={setCorrectAnswer} number={questionCount} color={color} onlineTestId={onlineTestId} />;
    if (type === 'oneword') return <OneWord data={props} correctAnswer={correctAnswer} setCorrectAnswer={setCorrectAnswer} number={questionCount} color={color} onlineTestId={onlineTestId} />;
    if (type === 'description') return <DescCard data={props} correctAnswer={correctAnswer} setCorrectAnswer={setCorrectAnswer} number={questionCount} color={color} onlineTestId={onlineTestId} />;
    if (type === 'correctstatement') return <TFCard data={props} correctAnswer={correctAnswer} setCorrectAnswer={setCorrectAnswer} number={questionCount}  color={color} onlineTestId={onlineTestId} />;
  };

  return [handleSlide, showSlide];
};

export default useSlider;

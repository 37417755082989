import React from 'react'
import { Col, Row } from "react-bootstrap";
import Stat from '../common/Stat'
import StatBar from '../common/StatBar'
import "../QuestionReport.css"
import IsSmallScreen from '../common/IsSmallScreen';

function TrueFalse() {

    const isSmallScreen = IsSmallScreen

    return (
        <Row className="analytics_question_layer">
          <Col className="analytics_question_content">
          <Row className="analytics_question_heading">
              <p>Question 1</p>
              <p className="analytics_question_heading_card">2 Marks</p>
              <p className="analytics_question_heading_card">Easy</p>
            </Row>
            <Row className="analytics_question_statement">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque
              cupiditate doloribus nesciunt illum rem inventore quibusdam,
              adipisci doloremque autem consequatur similique laudantium odio
              commodi nulla architecto ipsa repudiandae, sint temporibus?
            </Row>
            <Row>
              <Row className="analytics_question_option">
                <Col className="option_tof">True</Col>
              </Row>
              <Row className="analytics_question_option">
                <Col className="option_tof" style={{ color: "#FA582E" }}>
                  False
                </Col>
              </Row>
            </Row>
            <Row
              className="correct_answer_fill_in_the_blank"
              style={{ marginBottom: isSmallScreen ? 5 : 0 }}
            >
              Correct Answer: True
            </Row>
          </Col>
          <Col className="analytics_question_stats">
            <Stat>
              <StatBar style={{ width: 200, backgroundColor: "#7EB6A2" }}>
                True
              </StatBar>
              40 Students
            </Stat>
            <Stat>
              <StatBar style={{ width: 100 }}>False</StatBar>20 Students
            </Stat>
          </Col>
        </Row>
    )
}

export default TrueFalse

import React, { useEffect } from 'react';
import './fillBlankCard.css';
import { useState } from 'react';
import { remarkStyle, head, signature, input } from '../../helper';
import DefaultImage from '../../../../../images/Navbar/title.png'
import { htmlDecode } from '../../../../../utils/parse';

export default function FillBlankCard({ img, color = '', data, number, correctAnswer, setCorrectAnswer, onlineTestId }) {
  const [edit, setEdit] = useState(false);
  const openEdit = () => setEdit(true);
  const closeEdit = () => {
    setEdit(false);
  };
  const question = data
  const id = onlineTestId + "," + question._id

  useEffect(() => {
    setCorrectAnswer([])
  }, [number])

  const handleFillUp = (optionId, value) => {
    /*for(let i in correctAnswer){
      if(correctAnswer[i].blankId===optionId){
        correctAnswer[i].answer = value
        setCorrectAnswer(correctAnswer)
      }
    }*/
    //setCorrectAnswer([...correctAnswer,{'blankId':optionId,'answer':value}])
    let parsedData = JSON.parse(localStorage.getItem(id));
    const answerIndex = parsedData.findIndex(answer => answer.blankId === optionId);
    if (answerIndex === -1) {
      parsedData.push({ 'blankId': optionId, 'answer': value });
    } else {
      parsedData[answerIndex].answer = value
    }
    localStorage.setItem(id, JSON.stringify(parsedData));
    setCorrectAnswer(parsedData);
  }

  //Empty the input when a new question is rendered
  useEffect(() => {
    const inputs = document.querySelectorAll(".fill_up_input_box")
    if (localStorage.getItem(id)) {
      let parsedData = JSON.parse(localStorage.getItem(id))
      inputs.forEach((input, index) => {
        input.value = parsedData[index] || ''
      })
    } else {
      let temp = []
      localStorage.setItem(id, JSON.stringify(temp))
      inputs.forEach((input) => {
        input.value = ''
      })
    }

  }, [number])

  const parseString = (str) => {
    return new DOMParser()
      .parseFromString(str, "text/html")
      .body.textContent.trimStart();
  }

  return (
    <>
      {/* {edit && <EditCard edit={edit} closeEdit={closeEdit} quesType='fib' />} */}
      <div
        className='container question_card  my-4'
        style={{ display: edit ? 'none' : 'block' }}
      >
        <div className='superhead'>
          <div className='remark' style={remarkStyle(color)}>Fill In The Blanks</div>
          <div className='fb__head' style={head(color)}>
            <div className='fbCard'>
              <div className='fbCard__left' dangerouslySetInnerHTML={{ __html: htmlDecode(`Q${number}. ${question?.question}`) }}></div>
              {/* <div className='fbCard__right'>
                <img src={editImg} onClick={openEdit} />
                <img src={deleteImg} />

                
              </div>  */}
            </div>
            <div className='fb'>
              {question?.images?.questionImg ? <img className='fb_image' src={question?.images?.questionImg} /> : null}
              {data.options.map((option, index) =>
                <input
                  placeholder='Write your answer here'
                  className="fill_up_input_box"
                  style={{ ...input(color), marginBottom: '5px' }}
                  onChange={(e) => { handleFillUp(option.optionId, e.target.value) }}
                  value={localStorage.getItem(id) ? ((JSON.parse(localStorage.getItem(id)))[index])?.answer || '' : ''}
                />)
              }
            </div>
            <div className='last'>
              {question.onModel === "admin" ? <img src={DefaultImage} className='signarture' style={{ ...signature(color), width: '50px' }} /> : <div className='signarture' style={signature(color)}>{question.creatorId ? question.creatorId.name : ""}<br />{question.schoolId.name}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

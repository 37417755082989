import React, { useEffect } from "react";
import { head, input, remarkStyle, signature } from "../../helper";
import "./mcqCard.css";
import DefaultImage from "../../../../../images/Navbar/title.png";
import { htmlDecode } from "../../../../../utils/parse";

function McqCard({
  color = "",
  data,
  number,
  correctAnswer,
  setCorrectAnswer,
  onlineTestId,
}) {
  const question = data;
  const id = onlineTestId + "," + question._id;
  console.log("Question inside mcq card ====> ", question);

  const handleClick = (e) => {
    console.log("Now clicked ===> ", e.target.value);

    localStorage.getItem(id) === e.target.value
      ? localStorage.removeItem(id)
      : localStorage.setItem(id, e.target.value);
    setCorrectAnswer(e.target.value);
  };

  //Uncheck all the options when a new question is rendered
  useEffect(() => {
    if (localStorage.getItem(id)) return;

    const radios = document.querySelectorAll(".mcq_radio");
    radios.forEach((radio) => {
      radio.checked = false;
    });
  }, [number]);

  const parseString = (str) => {
    return new DOMParser()
      .parseFromString(str, "text/html")
      .body.textContent.trimStart();
  };

  return (
    <>
      <div className="container question_card  my-4">
        <div className="superhead">
          <div className="remark" style={remarkStyle(color)}>
            Select Single Option{" "}
          </div>
          <div className="mcq__head" style={head(color)}>
            <div className="mcqCard">
              <div className="mcqCard__left">
                <h6
                  style={{ fontWeight: "bolder", marginTop: "5px" }}
                  dangerouslySetInnerHTML={{
                    __html: htmlDecode(`Q${number}. ${question?.question}`),
                  }}
                ></h6>
              </div>
            </div>
            <div className="main_div">
              {question?.images?.questionImg ? (
                <div className="question_image">
                <img
                  className="q-img"
                  src={question.images?.questionImg}
                  alt="mcq-question"
                />
                </div>
              ) : null}
              <div
                className="mcq"
                style={
                  question?.images?.op1 ? { width: "70%" } : { width: "80%" }
                }
              >
                <div className="up top-left">
                  <label className="opt1-container" style={input(color)}>
                    <div className="opt-card-top">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: htmlDecode(question?.options[0]?.value || "-"),
                        }}
                      ></span>
                      <input
                        className="mcq_radio"
                        type="radio"
                        name="radio"
                        checked={
                          localStorage.getItem(id) ===
                          question?.options[0]?.optionId
                        }
                        value={question?.options[0]?.optionId}
                        onClick={handleClick}
                      />
                      <span
                        className="checkmark"
                        style={input(color, 3)}
                      ></span>
                    </div>
                    {question?.images?.op1 && (
                      <img className="imgBlock" src={question?.images?.op1} />
                    )}
                  </label>
                </div>
                <div className="up top-right">
                  <label className="opt1-container" style={input(color)}>
                    <div className="opt-card-top">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: htmlDecode(question?.options[1]?.value || "-"),
                        }}
                      ></span>
                      <input
                        className="mcq_radio"
                        type="radio"
                        name="radio"
                        checked={
                          localStorage.getItem(id) ===
                          question?.options[1]?.optionId
                        }
                        value={question?.options[1]?.optionId}
                        onClick={handleClick}
                      />
                      <span
                        className="checkmark"
                        style={input(color, 3)}
                      ></span>
                    </div>
                    {question?.images?.op2 && (
                      <img className="imgBlock" src={question?.images?.op2} />
                    )}
                  </label>
                </div>
                <div className="up bottom-left">
                  <label className="opt1-container" style={input(color)}>
                    <div className="opt-card-top">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: htmlDecode(question?.options[2]?.value || "-"),
                        }}
                      ></span>
                      <input
                        className="mcq_radio"
                        type="radio"
                        name="radio"
                        checked={
                          localStorage.getItem(id) ===
                          question?.options[2]?.optionId
                        }
                        value={question?.options[2]?.optionId}
                        onClick={handleClick}
                      />
                      <span
                        className="checkmark"
                        style={input(color, 3)}
                      ></span>
                    </div>
                    {question?.images?.op3 && (
                      <img className="imgBlock" src={question?.images?.op3} />
                    )}
                  </label>
                </div>
                <div className="up bottom-right">
                  <label className="opt1-container" style={input(color)}>
                    <div className="opt-card-top">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: htmlDecode(question?.options[3]?.value || "-"),
                        }}
                      ></span>
                      <input
                        className="mcq_radio"
                        type="radio"
                        name="radio"
                        checked={
                          localStorage.getItem(id) ===
                          question?.options[3]?.optionId
                        }
                        value={question?.options[3]?.optionId}
                        onClick={handleClick}
                      />
                      <span
                        className="checkmark"
                        style={input(color, 3)}
                      ></span>
                    </div>
                    {question?.images?.op4 && (
                      <img className="imgBlock" src={question?.images?.op4} />
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="last">
              {question.onModel === "admin" && question.creatorId ? (
                <img
                  src={DefaultImage}
                  className="signarture"
                  style={{ ...signature(color), width: "50px" }}
                />
              ) : (
                <div className="signarture" style={signature(color)}>
                  {question.creatorId ? question.creatorId.name : ""}
                  <br />
                  {question.schoolId.name}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default McqCard;

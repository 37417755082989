import React, {useState, useEffect} from "react";
import { useAlert } from "react-alert";
import axios from "axios";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalState";
import "./styles/Updates.css";
import {ReactComponent as AccountIcon} from "../../images/dashboard/AnnouncementUserLogo.svg";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import {ReactComponent as ArrowRightOutlinedIcon} from "../../images/dashboard/AnnouncementCommentArrow.svg";
import Divider from "@material-ui/core/Divider";
import { CardHeader, IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {ReactComponent as CalendarNext} from '../../images/dashboard/calendar/Next.svg'
import {ReactComponent as CalendarPrev} from '../../images/dashboard/calendar/Prev.svg'
import {useMediaQuery} from 'react-responsive'

const useStyles = makeStyles({
  root: {
    minWidth: "auto",
    borderRadius:"15px !important",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  calendar:{
    marginTop: "1rem",
    borderRadius:"15px",
    padding:"10px"
  },
  selectedDate:{
    borderRadius:"50%"
  },
  "@media screen and (max-width: 472px)":{
    root:{
      width:"95%",
      margin:"auto"

    }
  }
});

const Updates = () => {
  const classes = useStyles();
  const alert = useAlert();
  const [announcement, getAnnouncementData] = useState([]);
  const { grade, section, authToken } = useContext(GlobalContext).userDetails;
  const isSmall = useMediaQuery({query:"(max-width: 472px)"})

  const [fetched,setFetched] = useState(false)
  
  useEffect(() => {
    try {
      const getUpdates = async () => {
        const announcementData = await axios.get(`${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/announcements?sort=createdAt`,{
          headers: {
            token: authToken,
          },
        });
        console.log(announcementData)
        if (announcementData.data.status === 'success') {
          let {announcements} = announcementData.data.data;
          getAnnouncementData(announcements.reverse())
          setFetched(true)
        }
      }
  
      getUpdates();
    } catch (e) {
      alert.error('Not able to fetch tasks, Please refresh');
      console.log(e)
    }
  }, [grade, section, authToken, alert])

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [value, onChange] = useState(new Date());

  //Function to convert strings into possible links
  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return '<a target="blank" href="  ' + url + '">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }
  
  return (
    <>
      <section className="postCard">
        <div className="row">
          <div className="col-sm-8">
            {announcement.length ? announcement.map((e, idx) => {
              const currentDate = new Date(e.createdAt);
              return (
                <Card className={`${classes.root} mb-5`} key={`${e.userId?._id}${idx}`} style={{background: "rgba(241, 241, 250, 0.3)"}}>
                  <CardHeader
                    className="px-4"
                    avatar={
                      (e?.userId?.profile && e?.userId?.profile?.fileUrl)?
                      <img src={e.userId?.profile?.fileUrl} alt={e?.userId?.profile?.fileUrl} style={{width:"30px", height:"30px",borderRadius:"50%",backgroundSize:"cover"}}/>:
                      <AccountIcon style={{ fontSize: "60px",color:'rgba(127, 61, 255, 0.3)' }} />
                    }
                    title={e.userId?.name || ""}
                    subheader={`${months[currentDate.getMonth()]} ${currentDate.getDate()}, ${currentDate.getFullYear()}`}

                  />
                  <CardContent>
                    <div className="message px-2" dangerouslySetInnerHTML={{__html:urlify(e.announcement)}} >
                    </div>
                  </CardContent>
                  {/* <Divider style={{ backgroundColor: "black" }} />
                  <CardActions>
                    <div className="input-group mb-3 px-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add Comment"
                      />
                      <button className="btn" type="button">
                        <ArrowRightOutlinedIcon />
                      </button>
                    </div>
                  </CardActions> */}
                </Card>
              )}) : <h5 className="text-center" styles={isSmall?{fontSize:"0.8em",width:"fit-content"}:{}} >{fetched?'No announcements here':'Loading...'}</h5>
            }
          </div>

          <div className="col-sm-4 d-none d-sm-block">
            {/* <Card className={classes.root}>
              <CardContent>
                <div>
                  <h3 style={{fontWeight: "bolder",color:"rgba(127, 61, 255, 1)"}}>Updates</h3>
                  <ul>
                    <li>Flipped Class : Mathematics Due Date: Today</li>
                  </ul>
                </div>
              </CardContent>
              <CardActions>
                <Button size="small" style={{fontWeight: "bolder",fontSize:"1.1em",marginLeft:"auto",color:"rgba(127, 61, 255, 1)",textTransform:"none"}}>See More</Button>
              </CardActions>
            </Card> */}
            <Calendar
              onChange={onChange}
              value={value}
              className={classes.calendar}
              prevLabel={<CalendarPrev/>}
              nextLabel={<CalendarNext/>}
              prev2Label=""
              next2Label=""
              tileClassName={classes.selectedDate}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Updates;

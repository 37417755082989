import React, {useState, useContext, useEffect} from "react";
import { useAlert } from "react-alert";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import {Divider } from "@material-ui/core";
import { GlobalContext } from "../../context/GlobalState";
import axios from "axios";
import "../Test/styles/Test.css";

const useStyles = makeStyles({
    root: {
      minWidth: "auto",
      marginBottom: "30px",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    cardDivider: {
      // boxShadow: "0px 4px 6px rgba(51, 122, 115, 0.16)",
      backgroundColor: "#9c9191",
    },
    paragraph_answer:{
        width:'100%',
        multiline:true,
        rows:5
    },
    submitBtn: {
      backgroundColor: "#5CE0D2",
      borderRadius: "7px",
      width: "200px",
      "&:hover": {
        backgroundColor: "#5CE0D2",
      },
    },
});

function TestPreview(props) {
    const [testName,setTestName] = useState(null)
    const [testDesc,setTestDesc] = useState(null)
    const [questions, setQuestions] = useState(undefined)
    const [fetched,setFetched] = useState(false)

    const classes = useStyles();
    const alert = useAlert();
    const { grade, section, authToken } = useContext(GlobalContext).userDetails;

    useEffect(() => {
        const getTask = async () => {
            try {
            let {topicId, id} = props.match.params
            const testData = await axios(`${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/topics/${topicId}/tests/${id}`, {
                headers: {
                token: authToken,
                },
            })
            if(testData.data.status === 'success') {
                console.log(testData);
                setFetched(true)
                setTestName(testData.data.data.test.name)
                setTestDesc(testData.data.data.test.desc)
                setQuestions(testData.data.data.test.questions);
            }
            } catch(e) {
            alert.error('Task Not Found')
            console.log(e)
            }
        }
        getTask();
    }, [grade, section, authToken])
    return (
        <section className="testQuestion my-5">
            {fetched?
            <div className="container">
                <div className="box mb-3">
                    <h1 className="fw-bold">{testName}</h1>
                    <p>{testDesc}</p>
                </div>
                {
                    questions && questions.map((que, idx) => (
                    <Card className={classes.root} variant="elevation" key={idx}>
                        <CardHeader title={`Question ${idx+1}`} />
                        <Divider className={classes.cardDivider} />
                        <CardContent>
                        <h3>{`${que.question}`}</h3>
                        <div>
                            {que.questionType==='mcq'?
                            que.options.map((op, index) => (
                                <div className="form-check" key={index}>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    checked={false}
                                    name={`question${idx+1}`}
                                />
                                <label className="form-check-label" style={{fontWeight: 'bold'}}>
                                    {op.option}
                                </label>
                                </div>
                            ))
                            : 
                            <TextField id="filled-basic" 
                                className={classes.paragraph_answer}  
                                label="Answer" 
                                variant="filled"
                                multiline
                                /* styles the input component */
                                inputProps={{
                                    style: {
                                        height:'200px',
                                    }}}    
                            />
                            }
                        </div>
                        </CardContent>
                    </Card>
                    ))
                }
            </div>
            : <h2 className="text-center">Loading...</h2> }
        </section>
    )
}

export default TestPreview

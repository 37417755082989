import React, { useState, useEffect } from "react";
import "date-fns";
import axios from "axios";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalState";
import { useAlert } from "react-alert";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { NavLink } from "react-router-dom";
import { createFilteredTask } from "../functions";
import "./styles/TaskTopic.css";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "auto",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 280,
  },
  tableRow: {
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#A6EDE5 !important",
    },
  },
}));

const TableTask = () => {
  const alert = useAlert();
  const { userDetails, setUserDetails } = useContext(GlobalContext);
  let [rows, setRows] = useState(undefined);
  const { grade, section, authToken, userId } = userDetails;
  const currentDate = new Date();
  useEffect(() => {
    const getTask = async () => {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();
      let date = new Date(`${year}-${month}-${day}`).getTime() - 8640000;
      date = new Date(date).toISOString();
      try {
        const taskData = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/tasks?from[gte]=${date}`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        console.log("Task data response->", taskData);
        if (taskData.data.status === "success") {
          let { tasks } = taskData.data.data;
          tasks = tasks.map((task) => createFilteredTask(task, userId));
          setRows(tasks);
        }
      } catch (e) {
        alert.error("Not able to fetch tasks, Please refresh");
        console.log(e);
      }
    };
    getTask();
  }, [grade, section, authToken, alert]);

  const classes = useStyles();
  const [filterData, setFilterData] = useState({
    fromDate: currentDate,
    toDate: null,
    task: "",
  });

  useEffect(() => {
    setUserDetails({ ...userDetails, selectedTab: "Task List" });
  }, []);

  const handleFromDateChange = async (fromDate) => {
    setFilterData({ ...filterData, fromDate });
    try {
      const selectedDate = new Date(fromDate);
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth() + 1;
      const day = selectedDate.getDate();
      const date = new Date(`${year}-${month}-${day}`).toISOString();

      let filterUrl = `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/tasks?from[gte]=${date}`;
      if (filterData.toDate) {
        filterUrl = `${filterUrl}&to[lte]=${filterData.toDate.toISOString()}`;
      }
      if (filterData.task) {
        filterUrl = `${filterUrl}&taskType=${filterData.task}`;
      }
      const filteredTaskData = await axios.get(filterUrl, {
        headers: {
          token: authToken,
        },
      });
      console.log(
        'Task data response after changing "from" date->',
        filteredTaskData
      );
      if (filteredTaskData.data.status === "success") {
        const filteredTask = filteredTaskData.data.data.tasks.map((task) =>
          createFilteredTask(task, userId)
        );
        setRows(filteredTask);
      }
    } catch (e) {
      alert.error("Not able to fetch tasks, Please refresh");
      console.log(e);
    }
  };

  const handleToDateChange = async (toDate) => {
    document.getElementById("toDate-label").style.marginTop = "5px";

    setFilterData({ ...filterData, toDate });
    try {
      const date = new Date(toDate).toISOString();
      let filterUrl = `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/tasks?to[lte]=${date}`;
      if (filterData.fromDate) {
        filterUrl = `${filterUrl}&from[gte]=${filterData.fromDate.toISOString()}`;
      }
      if (filterData.task) {
        filterUrl = `${filterUrl}&taskType=${filterData.task}`;
      }
      const filteredTaskData = await axios.get(filterUrl, {
        headers: {
          token: authToken,
        },
      });
      console.log(
        'Task data response after changing "to" date->',
        filteredTaskData
      );
      if (filteredTaskData.data.status === "success") {
        const filteredTask = filteredTaskData.data.data.tasks.map((task) =>
          createFilteredTask(task, userId)
        );
        setRows(filteredTask);
      }
    } catch (e) {
      alert.error("Not able to fetch tasks, Please refresh");
      console.log(e);
    }
  };

  const handleTaskChange = async (e) => {
    document.getElementById("taskSelector").style.marginTop = "5px";

    const { value } = e.target;
    try {
      let filterUrl = value
        ? `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/tasks?taskType=${value}`
        : `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/tasks`;
      if (filterData.toDate) {
        filterUrl = `${filterUrl}&to[lte]=${filterData.toDate.toISOString()}`;
      }
      if (filterData.fromDate) {
        filterUrl = `${filterUrl}&from[gte]=${filterData.fromDate.toISOString()}`;
      }
      setFilterData({ ...filterData, task: value });
      const filteredTaskData = await axios.get(filterUrl, {
        headers: {
          token: authToken,
        },
      });
      console.log(
        "Task data response after changing task type->",
        filteredTaskData
      );
      if (filteredTaskData.data.status === "success") {
        const filteredTask = filteredTaskData.data.data.tasks.map((task) =>
          createFilteredTask(task, userId)
        );
        setRows(filteredTask);
      }
    } catch (e) {
      alert.error("Not able to fetch tasks, Please refresh");
      console.log(e);
    }
  };

  return (
    <>
      <section className="taskFilter">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justifyContent="space-around" className="mb-4">
            <KeyboardDatePicker
              inputVariant="outlined"
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="fromDate"
              label="From Date"
              value={filterData.fromDate}
              onChange={handleFromDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              autoOk={true}
            />

            <KeyboardDatePicker
              inputVariant="outlined"
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="toDate"
              label="To Date"
              value={filterData.toDate}
              onChange={handleToDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              autoOk={true}
            />

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="taskSelector">Task</InputLabel>
              <Select
                labelId="taskLabel"
                id="task"
                value={filterData.task}
                onChange={handleTaskChange}
                name="task"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="homework">Home Work</MenuItem>
                <MenuItem value="flipped class">Flipped Class</MenuItem>
                <MenuItem value="revision task">Revision Work</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </MuiPickersUtilsProvider>
      </section>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Subject</TableCell>
              <TableCell align="center">Chapter</TableCell>
              <TableCell align="center">Topic</TableCell>
              <TableCell align="center">Module</TableCell>
              <TableCell align="center">Task</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">From</TableCell>
              <TableCell align="center">To</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row, index) => (
                <TableRow
                  key={`${row.subject}${index}`}
                  variant="button"
                  component={NavLink}
                  to={`/tasklist/topic/${row._id}`}
                  className={classes.tableRow}
                >
                  <TableCell scope="row" align="center">
                    {row.subject}
                  </TableCell>
                  <TableCell align="center">{row.chapter}</TableCell>
                  <TableCell align="center">{row.topic}</TableCell>
                  <TableCell align="center">{row.url || row.module}</TableCell>
                  <TableCell align="center">{row.tasktype}</TableCell>
                  <TableCell align="center">{row.status}</TableCell>
                  <TableCell align="center">{row.from}</TableCell>
                  <TableCell align="center">{row.to}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableTask;

import React, { useState, useEffect, useContext } from 'react';
import './styles/Modules.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Module from './Module';
import Assesment from './Assesment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {
  // useLocation,
  useParams,
} from 'react-router-dom';
import { ReactComponent as ModuleIcon } from '../../images/resources/module.svg';
import { ReactComponent as AssessmentIcon } from '../../images/resources/assessment.svg';
import { GlobalContext } from '../../context/GlobalState';
import ModuleIconPath from '../../images/modules/module.png';
import AssessmentIconPath from '../../images/modules/assessment.png';
import { useMediaQuery } from 'react-responsive';
import Drawer from '../Navbar/Drawer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const Modules = (props) => {
  // console.log(props.match.params.subjectId);
  console.log('modules props', props);

  const { userDetails, setUserDetails } = useContext(GlobalContext);
  const isSmall = useMediaQuery({ query: '(max-width: 472px)' });
  document.title = '2xcell.in';
  // const location = useLocation();

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [heading, setHeading] = useState('Modules');

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeHeading = (val) => {
    setHeading(val);
  };

  //State and function to get the modules of a specific topic
  const [modules, setModules] = useState([]);
  const [fetchedModules, setFetchedModules] = useState(false);
  const getModules = async () => {
    console.log(userDetails, 'these are the details');
    const grade = window.localStorage.getItem('grade');
    const section = window.localStorage.getItem('section');
    const topicId = props.location.state.topicId;
    const language = window.localStorage.getItem('language') || 'english';
    const tempOrigin = 'https://api.2xcell.in';
    await fetch(
      `${tempOrigin}/api/v1/grades/${grade}/sections/${section}/topics/${topicId}/modules?lang=${language}`,
      {
        headers: {
          token: `${window.localStorage.getItem('authToken')}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        const res = response.data.modules.filter((record) => {
          return record?.school
            ? record?.school === userDetails?.school?._id
            : true;
        });
        setModules(res);
        setFetchedModules(true);
      });
  };

  //State and function to get the worksheets of a specific topic
  // const [worksheets, setWorksheets] = useState([]);
  const [
    fetchedWorksheets,
    // setFetchedWorksheets
  ] = useState(false);
  // const getWorksheets = async()=>{
  //   const grade = window.localStorage.getItem('grade');
  //   const section = window.localStorage.getItem('section');
  //   const topicId = props.location.state.topicId

  //   try{
  //     await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/topics/${topicId}/worksheets`,{
  //       headers: {
  //         'token': `${window.localStorage.getItem('authToken')}`
  //       }
  //     }).then(response=>response.json())
  //         .then(response=>{
  //           setWorksheets(response.data.worksheets)
  //           setFetchedWorksheets(true)
  //         })
  //   }catch{}
  // }

  //State and function to get the tests of a specific topic
  const [tests, setTests] = useState([]);
  const [fetchedTests, setFetchedTests] = useState(false);
  const getTests = async () => {
    const grade = window.localStorage.getItem('grade');
    const section = window.localStorage.getItem('section');
    const topicId = props.location.state.topicId;

    try {
      await fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/topics/${topicId}/tests`,
        {
          headers: {
            token: `${window.localStorage.getItem('authToken')}`,
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log('tests', response);
          setTests(response.data.tests);
          setFetchedTests(true);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getModules();
    getTests();
    // getWorksheets()
    // eslint-disable-next-line
  }, []);

  //Extracting params and the topic name from url
  const params = useParams();
  const subjectName = params.subject;
  const chapterName = params.chapter;
  const topicName = params.topic || params.topicName;

  const getCurrentDate = () => {
    const date = new Date();
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return `${date.getDate()} ${months[date.getMonth()]},${date.getFullYear()}`;
  };

  useEffect(() => {
    setUserDetails({ ...userDetails, selectedTab: '2Xcell Resources' });
    setUserDetails({
      ...userDetails,
      selectedPath: {
        subject: subjectName,
        chapter: chapterName,
        topic: topicName,
      },
    });
  }, []);

  const ResourcesTabs = () => (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='rgba(127, 61, 255, 1)'
        variant='scrollable'
        scrollButtons={'on'}
      >
        <Tab
          label='Module'
          {...a11yProps(0)}
          onClick={() => changeHeading('Modules')}
          icon={<img src={ModuleIconPath} />}
          style={{ color: 'rgba(127, 61, 255, 1)' }}
        />
        <Tab
          label='Assessment'
          {...a11yProps(1)}
          onClick={() => changeHeading('Assessment')}
          icon={<img src={AssessmentIconPath} />}
          style={{ color: 'rgba(127, 61, 255, 1)' }}
        />
      </Tabs>
    </Paper>
  );

  return (
    <>
      {isSmall && (
        <Drawer style={{ paddingTop: '30px' }}>
          <ResourcesTabs></ResourcesTabs>
        </Drawer>
      )}
      <section className='modules my-4  col-md-9 col-sm-12  mx-auto'>
        <div className='container'>
          <div className='box'>
            {!isSmall && (
              <div className='upperBox'>
                <div
                  className='text-center text-sm-start row'
                  style={{ alignItems: 'center' }}
                >
                  <span className='col-sm-9'>
                    <h2 style={{ color: 'white', fontWeight: 'bolder' }}>
                      {userDetails.grade + '-' + userDetails.section}
                    </h2>
                    <p style={{ color: 'white', fontWeight: 'bolder' }}>
                      {subjectName} &gt; {chapterName} &gt; {topicName}
                    </p>
                  </span>
                  <h5
                    className='col-sm-3'
                    style={{
                      marginLeft: 'auto',
                      fontSize: '90%',
                      fontWeight: 'bolder',
                      marginBottom: 'auto',
                      textAlign: 'right',
                    }}
                  >
                    <CalendarTodayIcon style={{ width: '17px' }} />{' '}
                    {getCurrentDate()}
                  </h5>
                </div>
              </div>
            )}

            {!isSmall && <ResourcesTabs />}

            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Module modules={modules} fetched={fetchedModules} />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <Assesment
                  subject='EVS'
                  // worksheets={worksheets}
                  fetchedWorksheets={fetchedWorksheets}
                  tests={tests}
                  fetchedTests={fetchedTests}
                />
              </TabPanel>
            </SwipeableViews>
          </div>
        </div>
      </section>
    </>
  );
};

export default Modules;

import React from 'react';
import {LoadingContainer,DocumentLoader,Text} from './Loader.style';
import ReactLoading from 'react-loading';

function DocumentLoading({type,color}) {
    return (
        <LoadingContainer>
            <DocumentLoader>
            <ReactLoading type="cylon" color="#0dcaf0" height={100} width={100}/>
            <Text>Please wait your data is Uploading </Text>
            </DocumentLoader>
            
        </LoadingContainer>
    )
}

export default DocumentLoading;

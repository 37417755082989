export function groupModulesByCategory(modules) {
  // Create an object to hold the grouped modules
  const groupedModules = {
    Activities: [],
    Assignments: [],
    "E-Content": [],
    "Home work": [],
    "Questions Papers": [],
    "Revision Note": [],
    Worksheets: [],
    Module: [],
  };

  // Iterate over the modules array
  modules.forEach((module) => {
    const category = module.category;
    // Add the current module to the category array
    groupedModules[category].push(module);
  });

  // Convert the groupedModules object into an array of {categoryName, modules} objects
  return Object.keys(groupedModules).map((categoryName) => ({
    categoryName,
    modules: groupedModules[categoryName],
  }));
}

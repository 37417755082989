import React from 'react';

const ImageBox = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '2rem',
};

const image = {
  margin: 'auto',
};

function Image(props) {
  console.log(props);
  return (
    <div style={ImageBox}>
      {/* <h4>{props.location.useProps.moduleName}</h4> */}
      <img
        style={image}
        src={props.location.useProps.fileUrl}
        alt={props.location.useProps.fileUrl}
      />
    </div>
  );
}

export default Image;

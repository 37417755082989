const UPPER_LIMIT = 80

const StatBarWidth = (question, option) => {

    try {

        switch (question.questionId.questionType) {
            case "mcq":
                {
                    const answered = question.statistics.filter(user => user.answer.mcq == option.optionId)
                    const totalResponse = question.statistics.length

                    console.log(answered)
                    console.log(Math.round((answered.length / totalResponse) * UPPER_LIMIT))
                    return Math.round((answered.length / totalResponse) * UPPER_LIMIT)
                }
            case "mmcq":
                {
                    const answered = question.statistics.filter(user => user.answer.mmcq.includes(option.optionId))
                    const totalResponse = question.statistics.length

                    console.log(answered)
                    console.log(Math.round((answered.length / totalResponse) * UPPER_LIMIT))
                    return Math.round((answered.length / totalResponse) * UPPER_LIMIT)
                }
            case "oneword":
                {

                    const answered = question.statistics.map(user => user.answer.oneword === question.questionId.correctAnswers.oneword[0].blankId)
                    const totalResponse = question.statistics.length

                    return Math.round((answered.length / totalResponse) * UPPER_LIMIT)
                }
            case "fillup":
                {

                    const answered = question.statistics.map(user => user.answer.fillup.map(answer => answer.answer).join(",") === question.questionId.correctAnswers.fillup.map(answer => answer.answer).join(","))
                    const totalResponse = question.statistics.length

                    return Math.round((answered.length / totalResponse) * UPPER_LIMIT)
                }
            default:
                return 0
        }
    } catch (err) {
        console.log("Error in calculating bar width: ", err.message)
        return 0
    }
}

module.exports = StatBarWidth
import React from 'react';
import './styles/Dashboard.css';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Updates from './Updates';
import RevisionTask from './RevisionTask';
import FlippedClass from './FlippedClass';
import Homework from './Homework';
import { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#edfcfa",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const Dashboard = () => {
  const { grade, section, name } = useContext(GlobalContext).userDetails;
  document.title = '2xcell.in';
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const currentDate = new Date();

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='inherit'
          variant='scrollable'
          scrollButtons={'on'}
          // centered
        >
          <Tab
            label='Revision Task'
            {...a11yProps(0)}
            style={{ fontWeight: 'bolder' }}
          />
          <Tab
            label='Flipped Class'
            {...a11yProps(1)}
            style={{ fontWeight: 'bolder' }}
          />
          <Tab
            label='Home Work'
            {...a11yProps(2)}
            style={{ fontWeight: 'bolder' }}
          />
        </Tabs>
      </Paper>

      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <RevisionTask />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <FlippedClass />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Homework />
        </TabPanel>
      </SwipeableViews>
    </>
  );
};

export default Dashboard;

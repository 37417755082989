import React from 'react'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    card:{
        borderRadius: '10px',
        minWidth:"200px",
        textAlign:"center",
        color:"white",
        fontSize:"0.9em",
        padding:"5px 1em"
    },
    "@media screen and (max-width: 472px)":{
        card: {
            display: "flex",
            flexDirection:"column",
            justifyContent:"space-between",
            alignItems:"center",
            padding:"5px 0px",
            minWidth:"80px",
            maxWidth:"80px",
            minHeight:"80px",
            "& > *":{
                fontSize:"0.7em",
            }
        }
    }
})

function secondsToHms(d) {

    if(!d) return 0

    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    return h + ":" + m + ":" + s
}

/**
 * Component to render the minicards on top of the page
*/
function MiniCards({data}) {

    const styles = useStyles()
    let practiceSessionDetails = [
        {
            heading:"Time Spent",
            body: data && secondsToHms(data.timeSpend),
            style:{
                backgroundColor:"rgba(124, 180, 160, 1)",
            }
        },
        {
            heading:"Questions Practiced",
            body: data && ((data.attempted || 0) + "/" + (data.toAttempt || 0)),
            style:{
                backgroundColor:"rgba(180, 108, 189, 1)"
            }
        },
        {
            heading:"Concepts Practiced",
            body: data && data.topics || 0,
            style:{
                backgroundColor:"rgba(64, 143, 177, 1)"
            }
        },
        {
            heading:"Chapters Practiced",
            body: data && data.chapters || 0,
            style:{
                backgroundColor:"rgba(255, 129, 169, 1)"
            }
        },
    ]

    const Card = ({heading,body,style}) => 
        <div style={style} className={styles.card}>
            <h5>{heading}</h5>
            <p>{body}</p>
        </div>


    return (
        <div className={styles.container}>
            {practiceSessionDetails.map(item => <Card heading={item.heading} body={item.body} style={item.style}/>)}
        </div>
    )
}

export default MiniCards
